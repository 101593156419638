/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Box, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, DrawerOverlay, Flex, useColorModeValue, useDisclosure } from '@chakra-ui/react';
import Sidebar from './sidebar';
import Header from './header';
import { useAuth } from '../../context/auth';
import CreateHuntDrawer from '../tour-drawer';
import CreateChallengeDrawer from '../challenge-drawer';
interface DashboardLayoutProps {
  children: React.ReactNode;
  tourDrawerOpen: boolean;
  setTourDrawerOpen: (open: boolean) => void;
  locationDrawerOpen: boolean;
  setLocationDrawerOpen: (open: boolean) => void;
}

const DashboardLayout: React.FC<DashboardLayoutProps> = ({ children, tourDrawerOpen, setTourDrawerOpen, locationDrawerOpen, setLocationDrawerOpen }) => {
  const { user } = useAuth();
  const { onOpen, isOpen, onClose } = useDisclosure();
  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const [selectedPage, setSelectedPage] = useState('Dashboard');
  const borderColor = useColorModeValue("gray.400", "gray.700");

  useEffect(() => {
    const path = window.location.pathname;
    if (path.endsWith("/home")) {
      setSelectedPage("Dashboard");
    } else if (path.endsWith("/tours")) {
      setSelectedPage("Tours");
    } else if (path.endsWith("/participants")) {
      setSelectedPage("Participants");
    } else if (path.endsWith("/activity")) {
      setSelectedPage("Activity Log");
    } else if (path.endsWith("/settings")) {
      setSelectedPage("Settings");
    } else if (path.endsWith("/billing")) {
      setSelectedPage("Billing");
    } else if (path.includes("/locations/")) {
      setSelectedPage("Location");
    } else if (path.includes("/tours/")) {
      setSelectedPage("Tour");
    }
  }, [window.location.pathname]);

  return (
    <Flex flexDirection={{ base: "column", md: "row" }} height="100vh">
      {/* Sidebar for desktop */}
      <Box
        display={{ base: "none", md: "block" }}
        w="64"
        bg={bgColor}
        borderRight="1px"
        borderRightColor={borderColor}
      >
        <Sidebar user={user} appName="Culinary Castle" />
      </Box>
      {/* Drawer for mobile */}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay>
          <DrawerContent bgGradient="linear(to-b, purple.500, pink.500)" color="white">
            <DrawerCloseButton />
            <DrawerHeader>Menu</DrawerHeader>
            <DrawerBody>
              <Sidebar user={user} appName="Culinary Castle" />
            </DrawerBody>
          </DrawerContent>
        </DrawerOverlay>
      </Drawer>
      <Box flex={1} overflow="auto">
        <Header onOpen={onOpen} user={user} title={selectedPage} />
        {children}
      </Box>
      <CreateHuntDrawer
        isOpen={tourDrawerOpen || false}
        onClose={() => setTourDrawerOpen?.(false)}
      />
      <CreateChallengeDrawer
        isOpen={locationDrawerOpen || false}
        onClose={() => setLocationDrawerOpen?.(false)}
      />
    </Flex>
  );
};

export default DashboardLayout;
