import React from "react"
import {
  HStack,
  Avatar,
  VStack,
  Text,
  IconButton,
  useColorModeValue,
} from "@chakra-ui/react"
import { ArrowLeft } from "lucide-react"
import { useNavigate } from "react-router-dom"

type ChatHeaderProps = {
  creatorUsername?: string
  creatorEmail?: string
  huntTitle?: string
  isCreator: boolean
  participantUsername?: string
  participantEmail?: string
}

export const ChatHeader = ({
  creatorUsername,
  creatorEmail,
  huntTitle,
  isCreator,
  participantUsername,
  participantEmail,
}: ChatHeaderProps) => {
  const navigate = useNavigate()
  const headerBg = useColorModeValue("purple.50", "purple.900")
  const borderColor = useColorModeValue("gray.200", "gray.700")

  const displayName = isCreator
    ? participantUsername || participantEmail
    : creatorUsername || creatorEmail

  return (
    <HStack
      w="full"
      p={4}
      bg={headerBg}
      borderBottom="1px"
      borderColor={borderColor}
      spacing={4}
      position="sticky"
      top={0}
      zIndex={1}
      align="center"
    >
      <IconButton
        aria-label="Back"
        icon={<ArrowLeft />}
        variant="ghost"
        onClick={() => navigate(-1)}
        color="purple.500"
        size={{ base: "sm", md: "md" }}
      />
      <Avatar
        size={{ base: "sm", md: "md" }}
        name={displayName}
        bg="purple.500"
      />
      <VStack align="start" spacing={0} flex={1}>
        <Text fontWeight="medium" fontSize={{ base: "sm", md: "md" }}>
          {displayName}
        </Text>
        {huntTitle && (
          <Text fontSize={{ base: "xs", md: "sm" }} color="gray.500">
            {huntTitle}
          </Text>
        )}
      </VStack>
    </HStack>
  )
} 