import React from 'react';
import { Box, VStack, Heading, Text, Button, Flex, Icon } from '@chakra-ui/react';
import { ArrowRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { FaMapMarkedAlt, FaCamera, FaFlag, FaVoteYea, FaShare } from 'react-icons/fa';
import PoweredByFooter from "../../components/shared/powered-by-footer";

const HowToPlay: React.FC = () => {
  const navigate = useNavigate();

  const instructions = [
    { icon: FaMapMarkedAlt, text: "Choose a food tour and start your culinary adventure" },
    { icon: FaCamera, text: "Visit each location and take photos of the dishes" },
    { icon: FaFlag, text: "Complete the tour by visiting all locations" },
    { icon: FaVoteYea, text: "Vote for your favorite restaurant and view the leaderboard" },
    { icon: FaShare, text: "Share your experience and try other tours!" },
  ];
  const bgImage = "url('/images/pit.jpg')";

  return (
    <Box
      bgImage={bgImage}
      minHeight="100vh"
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Box
        bg="white"
        borderRadius="xl"
        maxWidth="xl"
        width="100%"
        overflow="hidden"
        boxShadow="xl"
        p={8}
      >
        <VStack spacing={8} align="stretch">
          <Heading size="xl" textAlign="center" color="orange.500">
            Dallas Food Tour: Your Path to Culinary Glory!
          </Heading>
          <Text fontSize="lg" textAlign="center" fontWeight="bold">
            Embark on your gastronomic journey with these simple steps:
          </Text>
          {instructions.map((instruction, index) => (
            <Flex key={index} align="center" bg="gray.50" p={4} borderRadius="md" boxShadow="md">
              <Flex
                bg="orange.500"
                color="white"
                w={10}
                h={10}
                borderRadius="full"
                justify="center"
                align="center"
                mr={4}
                flexShrink={0}
              >
                {index + 1}
              </Flex>
              <Icon as={instruction.icon} boxSize={6} color="orange.500" mr={4} />
              <Text fontSize="md">{instruction.text}</Text>
            </Flex>
          ))}
          <Button
            rightIcon={<ArrowRight />}
            colorScheme="orange"
            onClick={() => navigate('/participant/dallas-food-tour/play-food-tour-game')}
            alignSelf="center"
            size="lg"
            mt={4}
          >
            Join The Tour
          </Button>
        </VStack>
      </Box>
      <PoweredByFooter />
    </Box>
  );
};

export default HowToPlay;
