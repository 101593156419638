import { Box, Link } from "@chakra-ui/react";
import React from "react";

const PoweredByFooter = () => {
  return (
    <Box
      position="fixed"
      bottom={2}
      left="50%"
      transform="translateX(-50%)"
      zIndex={1000}
      fontSize="2xs"
      fontWeight="medium"
      bg="rgba(0, 0, 0, 0.6)"
      color="whiteAlpha.800"
      py={0.5}
      px={2}
      borderRadius="full"
      backdropFilter="blur(4px)"
      transition="all 0.2s"
      _hover={{
        bg: "rgba(0, 0, 0, 0.8)",
        color: "white",
      }}
      pointerEvents="auto"
    >
      <Link
        href="/"
        _hover={{ textDecoration: "none" }}
        display="block"
        position="relative"
        zIndex={1001}
      >
        Powered by Culinary Castle
      </Link>
    </Box>
  );
};

export default PoweredByFooter; 