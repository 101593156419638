import { SimpleGrid, Box, VStack, Skeleton, Flex } from "@chakra-ui/react";

const TourListSkeleton = () => {
  return (
    <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((index) => (
        <Box
          key={index}
          borderWidth="1px"
          borderRadius="md"
          p={4}
          borderColor="gray.200"
          bg="white"
          boxShadow="sm"
          _hover={{ boxShadow: "md" }}
        >
          <VStack align="stretch" spacing={4}>
            <Flex justify="space-between" align="center">
              <Skeleton height="24px" width="60%" />
              <Skeleton height="20px" width="20%" borderRadius="full" />
            </Flex>
            
            <Skeleton height="40px" />
            
            <Flex justify="space-between">
              <Skeleton height="16px" width="40%" />
              <Skeleton height="16px" width="30%" />
            </Flex>
            
            <Flex justify="flex-end" gap={2}>
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} height="32px" width="32px" borderRadius="md" />
              ))}
            </Flex>
          </VStack>
        </Box>
      ))}
    </SimpleGrid>
  );
};

export default TourListSkeleton; 