import { VStack, Icon, Text, Box, Spinner } from "@chakra-ui/react";
import { MapPin } from "lucide-react";
import { NavigationButtons } from "./navigation-buttons";
import type { Coordinates } from "../../../helpers/map-util";

type LocationCardProps = {
  closestChallenge: any;
  userLocation: Coordinates;
  isLoadingLocation: boolean;
  mapUrl: string;
  onFetchLocation: () => void;
};

export const LocationCard = ({
  closestChallenge,
  userLocation,
  isLoadingLocation,
  mapUrl,
  onFetchLocation
}: LocationCardProps) => (
  <Box 
    textAlign="center" 
    p={[3, 4, 5]} 
    bg="gray.50"
    borderRadius="xl"
    width="100%"
    maxWidth="600px"
    boxShadow="sm"
  >
    <Icon as={MapPin} w={6} h={6} color="red.500" mb={2} />
    <Text 
      color="gray.800" 
      fontSize={["md", "lg"]} 
      mt={2} 
      fontWeight="bold"
    >
      First Stop: {closestChallenge?.title || <Spinner size="sm" />}
    </Text>
    
    <NavigationButtons
      mapUrl={mapUrl}
      userLocation={userLocation}
      isLoadingLocation={isLoadingLocation}
      onFetchLocation={onFetchLocation}
    />

    <Text 
      color="gray.600" 
      fontSize="sm" 
      mt={3}
      px={2}
    >
      {closestChallenge?.address || ""}
    </Text>
  </Box>
); 