import React from "react"
import { Box, Text, Flex, useColorModeValue } from "@chakra-ui/react"
import { format } from "date-fns"
import { UserType } from "../../../graphql/generated"

type Message = {
  id: string
  content: string
  from: UserType
  createdAt: string
  participantName?: string
  creatorUsername: string
}

type MessageBubbleProps = {
  message: Message
  isUser: boolean
}

export const MessageBubble = ({ message, isUser }: MessageBubbleProps) => {
  const isCreator = message.from === UserType.Creator
  const shouldAlignRight = isUser ? isCreator : !isCreator

  const bubbleBg = useColorModeValue(
    shouldAlignRight ? "purple.500" : "gray.100",
    shouldAlignRight ? "purple.500" : "gray.700"
  )
  const textColor = shouldAlignRight ? "white" : undefined

  return (
    <Flex justify={shouldAlignRight ? "flex-end" : "flex-start"} w="100%">
      <Box
        maxW="70%"
        bg={bubbleBg}
        color={textColor}
        px={4}
        py={2}
        borderRadius="lg"
        position="relative"
      >
        <Text fontSize="sm">{message.content}</Text>
        <Text fontSize="xs" opacity={0.8} mt={1}>
          {format(new Date(message.createdAt), "MMM d, h:mm a")}
        </Text>
      </Box>
    </Flex>
  )
} 