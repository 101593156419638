import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from "react-router-dom";
import { Box, Button, Flex, Heading, Text, Stack, Icon, Container, Grid, VStack, useColorModeValue, Input, keyframes, HStack, useDisclosure, IconButton, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, ButtonProps, Image, Collapse, useToast } from "@chakra-ui/react";
import { Lock, Users, Share2, Briefcase, GraduationCap, Heart, CheckCircle, Menu as MenuIcon, Calendar, Utensils, X, MessageCircleCodeIcon } from "lucide-react";
import { motion } from "framer-motion";
import { useAuth } from "../../context/auth";
import { useLocation, useNavigate } from 'react-router-dom';
import StripePricingTable from '../../components/stripe-pricing-table';

export const useScrollToHash = () => {
    const location = useLocation();

    React.useEffect(() => {
        if (!location.hash) return;
        const id = location.hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location]);
};

const fadeIn = keyframes`
  from { opacity: 0; transform: translateY(20px); }
  to { opacity: 1; transform: translateY(0); }
`;

const gradientAnimation = keyframes`
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
`;

const spotlightAnimation = keyframes`
  0%, 100% { background-position: 0% 0%; }
  25% { background-position: 100% 100%; }
  50% { background-position: 100% 0%; }
  75% { background-position: 0% 100%; }
`;

type AudienceType = "Tour Guides" | "Foodies" | "Tour Operators" | "Restaurant Owners" | "Travel Agencies" | "Event Planners" | "Food Bloggers" | "Food Critics" | "Food Journalists" | "Food Influencers";

const audiences: AudienceType[] = ["Tour Guides", "Foodies", "Tour Operators", "Restaurant Owners", "Travel Agencies", "Event Planners", "Food Bloggers", "Food Critics", "Food Journalists", "Food Influencers"];

const useSlideshow = (items: string[], interval: number = 2000) => {
    const [current, setCurrent] = useState(0);

    useEffect(() => {
        const timer = setInterval(() => {
            setCurrent((prev) => (prev + 1) % items.length);
        }, interval);
        return () => clearInterval(timer);
    }, [items, interval]);

    return items[current];
};

// First, create a custom hook for testimonial colors
const useTestimonialColors = () => {
    const bgColor = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("purple.500", "gray.300");
    const borderColor = useColorModeValue("purple.200", "gray.700");
    const iconBgColor = useColorModeValue("purple.50", "purple.900");
    const iconColor = useColorModeValue("purple.500", "purple.200");
    const accentTextColor = useColorModeValue("purple.600", "purple.400");

    const getAccentColors = (accentColor: string) => ({
        borderColor,
        iconBgColor,
        iconColor,
        accentTextColor
    });

    return {
        bgColor,
        textColor,
        getAccentColors
    };
};

// Add this custom hook for FAQ colors at the top with other hooks
const useFAQColors = () => {
    const bgColor = useColorModeValue("white", "gray.800");
    const buttonBgColor = useColorModeValue("white", "gray.800");
    const buttonHoverBgColor = useColorModeValue("purple.50", "gray.700");
    const textColor = useColorModeValue("purple.600", "purple.300");
    const contentTextColor = useColorModeValue("purple.600", "purple.300");
    const borderColor = useColorModeValue("purple.200", "gray.700");

    return {
        bgColor,
        buttonBgColor,
        buttonHoverBgColor,
        textColor,
        contentTextColor,
        borderColor
    };
};

// Add this type definition near the top with other types
type FAQColors = {
    bgColor: string;
    buttonBgColor: string;
    buttonHoverBgColor: string;
    textColor: string;
    contentTextColor: string;
    borderColor: string;
};

// Define consistent spacing and font sizes
const SPACING = { base: 4, md: 6, lg: 8 };
const FONT_SIZE = { base: "lg", md: "xl", lg: "2xl" };

export default function ColorfulAnimatedLandingPage() {
    useScrollToHash();
    const { isOpen, onToggle } = useDisclosure();
    const bgColor = useColorModeValue("whiteAlpha.800", "gray.800Alpha.80");
    const { user } = useAuth();
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const toast = useToast();
    const currentAudience = useSlideshow(audiences);
    const { bgColor: testimonialBgColor, textColor: testimonialTextColor, getAccentColors } = useTestimonialColors();
    const faqColors = useFAQColors();

    const validateEmail = (email: string) => {
        const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        return re.test(email);
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newEmail = e.target.value;
        setEmail(newEmail);
        setIsEmailValid(validateEmail(newEmail));
    };

    const handleSignupSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (validateEmail(email)) {
            navigate(`/signup?email=${encodeURIComponent(email)}`);
        } else {
            toast({
                title: "Invalid email",
                description: "Please enter a valid email address.",
                status: "error",
                duration: 3000,
                isClosable: true,
            });
        }
    };

    return (
        <Flex
            direction="column"
            minH="100vh"
            bg={useColorModeValue("#fbf6ff", "gray.900")}
        >
            <Box
                as="header"
                w="full"
                backdropFilter="blur(10px)"
                position="fixed"
                zIndex="50"
                boxShadow="md"
                bgGradient={useColorModeValue(
                    "radial-gradient(circle, rgba(255,255,255,0.8) 0%, rgba(230,230,250,0.8) 25%, rgba(216,191,216,0.8) 50%, rgba(221,160,221,0.8) 75%, rgba(238,130,238,0.8) 100%)",
                    "radial-gradient(circle, rgba(44,44,44,0.8) 0%, rgba(55,55,55,0.8) 25%, rgba(66,66,66,0.8) 50%, rgba(77,77,77,0.8) 75%, rgba(88,88,88,0.8) 100%)"
                )}
                backgroundSize="400% 400%"
                animation={`${spotlightAnimation} 15s ease infinite`}
            >
                <Flex px={SPACING} py={3} h={{ base: 16, md: 20 }} align="center" justify="space-between">
                    <Flex align="center">
                        <Image src="/images/logo.png" alt="Culinary Castle Logo" boxSize={10} mr={2} />
                        <Text
                            fontSize={{ base: "lg", md: "xl" }}
                            fontWeight="extrabold"
                            color="purple.600"
                            ml={1}
                            _dark={{ color: "purple.400" }}
                            bgGradient="linear(to-r, purple.500, pink.500)"
                            bgClip="text"
                            letterSpacing="tight"
                        >
                            Culinary Castle
                        </Text>
                    </Flex>

                    <HStack spacing={6} display={{ base: "none", md: "flex" }}>
                        <NavLink to="#features">Features</NavLink>
                        <NavLink to="#use-cases">Use Cases</NavLink>
                        <NavLink to="#testimonials">Testimonials</NavLink>
                        <NavLink to="#how-it-works">How it works</NavLink>
                        <NavLink to="#faq">FAQ</NavLink>
                    </HStack>

                    <HStack spacing={4}>
                        {user.isAuth === false && (
                            <Button
                                as={RouterLink}
                                to="/login"
                                size="sm"
                                colorScheme="purple"
                                variant="outline"
                                _hover={{
                                    bg: "purple.50",
                                    transform: "translateY(-2px)",
                                    boxShadow: "sm",
                                }}
                                transition="all 0.2s"
                                display={{ base: "none", md: "inline-flex" }}
                            >
                                Log In
                            </Button>
                        )}
                        {user.isAuth && (
                            <Button
                                as={RouterLink}
                                to="/home"
                                size="sm"
                                colorScheme="purple"
                                _hover={{
                                bg: "purple.500",
                                transform: "translateY(-2px)",
                                boxShadow: "md",
                            }}
                            transition="all 0.2s"
                            display={{ base: "none", md: "inline-flex" }}
                        >
                                Dashboard
                            </Button>
                        )}
                        <IconButton
                            display={{ base: "flex", md: "none" }}
                            onClick={onToggle}
                            icon={isOpen ? <X /> : <MenuIcon />}
                            variant="ghost"
                            aria-label="Toggle Navigation"
                            color="purple.600"
                            _hover={{
                                bg: "purple.50",
                                color: "purple.600",
                            }}
                        />
                    </HStack>
                </Flex>

                {/* Mobile nav */}
                <Collapse in={isOpen} animateOpacity>
                    <Box
                        pb={4}
                        display={{ md: "none" }}
                        bg={bgColor}
                        backdropFilter="blur(10px)"
                        borderBottom="1px"
                        borderColor="purple.600"
                    >
                        <Stack as="nav" spacing={SPACING}>
                            <NavLink to="#features" mobile>Features</NavLink>
                            <NavLink to="#use-cases" mobile>Use Cases</NavLink>
                            <NavLink to="#how-it-works" mobile>How it works</NavLink>
                            <NavLink to="#testimonials" mobile>Testimonials</NavLink>
                            <NavLink to="#faq" mobile>FAQ</NavLink>
                            {user.isAuth === false && (
                                <Button
                                    as={RouterLink}
                                    to="/login"
                                    w="75%"
                                    mx="auto"
                                    colorScheme="purple"
                                    variant="outline"
                                >
                                    Log In
                                </Button>
                            )}
                            <Button
                                as={RouterLink}
                                to={user.isAuth ? "/home" : "#signup"}
                                w="75%"
                                mx="auto"
                                colorScheme="purple"
                            >
                                {user.isAuth ? "Dashboard" : "Sign Up"}
                            </Button>
                        </Stack>
                    </Box>
                </Collapse>
            </Box>

            <Box as="main" flex="1">
                <Box
                    as="section"
                    w="full"
                    pt={{ base: 24, md: 32, lg: 40 }}
                    pb={{ base: 16, md: 24, lg: 32 }}
                    bgGradient="linear(to-r, purple.700, purple.600, purple.500, pink.500, pink.400, pink.300)"
                    backgroundSize="400% 400%"
                    animation={`${gradientAnimation} 20s ease infinite`}
                    _dark={{
                        bgGradient: "linear(to-r, gray.900, purple.900, blue.900, teal.900)",
                        backgroundSize: "400% 400%",
                        animation: `${gradientAnimation} 15s ease infinite`
                    }} color="white">
                    <Container maxW="container.xl" textAlign="center">
                        <Stack
                            spacing={{ base: 4, md: 6 }}
                            alignItems="center"
                        >
                            <Heading
                                as="h1"
                                size={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }}
                                fontWeight="extrabold"
                                lineHeight="1.1"
                                letterSpacing="tight"
                                animation={`${fadeIn} 1s ease-out`}
                            >
                                <Text
                                    as="span"
                                    display="block"
                                    fontSize={{ base: "3xl", sm: "4xl", md: "5xl", lg: "6xl", xl: "7xl" }} bgColor="white"
                                    bgClip="text"
                                    animation={`${fadeIn} 1s ease-out 0.5s both, shimmer 3s ease-in-out infinite`}
                                    position="relative"
                                    _after={{
                                        content: '""',
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "100%",
                                        background: "linear-gradient(90deg, transparent, rgba(255,255,255,0.2), transparent)",
                                        transform: "translateX(-100%)",
                                        animation: "shine 2s infinite"
                                    }}
                                >
                                    Real-Time Food Tours made by{" "}
                                    <Text
                                        as="span"
                                        display="inline-block"
                                        minW="200px"
                                        textAlign="center"
                                        transition="all 0.5s ease-in-out"
                                    >
                                        {currentAudience}
                                    </Text>
                                </Text>
                            </Heading>
                            <Text
                                fontSize={FONT_SIZE}
                                maxW="3xl"
                                mb={{ base: 2, md: 4 }}
                                opacity="0.8"
                                animation={`${fadeIn} 1s ease-out 1s both`}
                            >
                                Transform your culinary passion into unforgettable adventures. Create, track, and monetize interactive food tours with real-time progress tracking and seamless participant engagement.
                            </Text>
                            <Stack
                                direction={{ base: "column", md: "row" }}
                                spacing={SPACING}
                                width={{ base: "full", md: "auto" }}
                                align="center"
                            >
                                <Button
                                    onClick={() => {
                                        window.open("https://calendly.com/culinary-castle/demo", "_blank");
                                    }}
                                    size="lg"
                                    width={{ base: "full", md: "auto" }}
                                    px={8}
                                    variant={"ghost"}
                                    color="white"
                                    borderWidth={2}
                                    borderColor="white"
                                    _hover={{
                                        color: "purple.600",
                                        bg: "purple.50",
                                        transform: "translateY(-2px)",
                                        boxShadow: "lg",
                                    }}
                                    _active={{
                                        bg: "purple.100",
                                        transform: "translateY(0)",
                                        boxShadow: "md",
                                    }}
                                    leftIcon={<Icon as={Calendar} boxSize={5} />}
                                    fontWeight="bold"
                                    transition="all 0.2s"
                                >
                                    Book a Demo
                                </Button>
                                <Button
                                    as={RouterLink}
                                    to="/signup"
                                    size="lg"
                                    width={{ base: "full", md: "auto" }}
                                    px={8}
                                    colorScheme="white"
                                    variant={"ghost"}
                                    borderWidth={2}
                                    borderColor="whiteAlpha.800"
                                    _hover={{
                                        bg: "purple.700",
                                        transform: "translateY(-2px)",
                                        boxShadow: "lg",
                                    }}
                                    _active={{
                                        bg: "purple.800",
                                        transform: "translateY(0)",
                                        boxShadow: "md",
                                    }}
                                    leftIcon={<Icon as={Utensils} boxSize={5} />}
                                    fontWeight="bold"
                                    transition="all 0.2s"
                                >
                                    Craft Food Tours
                                </Button>
                            </Stack>
                        </Stack>
                    </Container>
                </Box>

                <Box
                    as="section"
                    id="features"
                    w="full"
                    py={{ base: 16, md: 24, lg: 32 }}
                    position="relative"
                    bg={useColorModeValue("white", "gray.800")}
                    overflow="hidden"
                >
                    {/* Add decorative background pattern */}
                    <Box
                        position="absolute"
                        top={0}
                        left={0}
                        right={0}
                        bottom={0}
                        bg="purple.50"
                        opacity={0.1}
                        style={{
                            backgroundImage: "radial-gradient(circle at 1px 1px, purple.100 1px, transparent 0)",
                            backgroundSize: "40px 40px"
                        }}
                        _dark={{ bg: "purple.900", opacity: 0.05 }}
                    />

                    <Container maxW="container.xl" position="relative">
                        <VStack spacing={8} mb={16}>
                            <Heading
                                size={{ base: "xl", md: "2xl", lg: "3xl" }}
                                bgGradient="linear(to-r, purple.600, pink.500)"
                                bgClip="text"
                                textAlign="center"
                                fontWeight="extrabold"
                            >
                                Powerful Features for Food Enthusiasts
                            </Heading>
                            <Text
                                fontSize={FONT_SIZE}
                                color={useColorModeValue("purple.600", "purple.300")}
                                _dark={{ color: "gray.300" }}
                                textAlign="center"
                                maxW="3xl"
                                mx="auto"
                            >
                                Create unforgettable culinary experiences with our comprehensive suite of tools designed for food tour creators and enthusiasts.
                            </Text>
                        </VStack>

                        <Grid
                            templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }}
                            gap={{ base: 8, md: 10 }}
                        >
                            {[
                                {
                                    icon: Utensils,
                                    title: "Curated Food Stops",
                                    description: "Handpick the finest local eateries and hidden gems for your food adventure.",
                                    gradient: "linear(to-r, orange.400, red.400)",
                                    accentColor: "orange"
                                },
                                {
                                    icon: Lock,
                                    title: "Private Tours",
                                    description: "Create exclusive, intimate food experiences for select groups and VIP clients.",
                                    gradient: "linear(to-r, yellow.400, orange.400)",
                                    accentColor: "yellow"
                                },
                                {
                                    icon: Users,
                                    title: "Participant Management",
                                    description: "Seamlessly manage multiple participants and coordinate large group food tours.",
                                    gradient: "linear(to-r, pink.400, red.400)",
                                    accentColor: "pink"
                                },
                                {
                                    icon: Share2,
                                    title: "Social Sharing",
                                    description: "Share and promote your culinary adventures across social platforms.",
                                    gradient: "linear(to-r, teal.400, green.400)",
                                    accentColor: "teal"
                                },
                                {
                                    icon: MessageCircleCodeIcon,
                                    title: "Message Blasting",
                                    description: "Send messages to participants to answer questions and provide updates.",
                                    gradient: "linear(to-r, blue.400, cyan.400)",
                                    accentColor: "blue"
                                },
                                {
                                    icon: CheckCircle,
                                    title: "Real-Time Tracking",
                                    description: "Monitor your food tour's progress and participant engagement in real-time.",
                                    gradient: "linear(to-r, purple.400, pink.400)",
                                    accentColor: "purple"
                                },
                            ].map((feature, index) => (
                                <FeatureCard key={index} feature={feature} index={index} />
                            ))}
                        </Grid>
                    </Container>
                </Box>

                <Box as="section" id="use-cases" w="full" py={{ base: 16, md: 24, lg: 32 }} bgGradient="linear(to-r, purple.600, pink.600)" color="white">
                    <Container maxW="container.lg" textAlign="center">
                        <Heading size={{ base: "xl", md: "2xl", lg: "3xl" }} mb={4}>Culinary Experiences for Everyone</Heading>
                        <Text fontSize={FONT_SIZE} color="gray.200" mb={{ base: 8, md: 12 }}>
                            From professional tour operators to passionate foodies, Culinary Castle caters to all your gastronomic adventure needs.
                        </Text>
                        <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={{ base: 6, md: 10 }}>
                            {[
                                { icon: Briefcase, title: "Food Tour Operators", description: "Create engaging self-guided food tours for your clients." },
                                { icon: GraduationCap, title: "Culinary Education", description: "Design interactive food-based learning experiences." },
                                { icon: Heart, title: "Foodie Date Nights", description: "Craft romantic culinary adventures for couples." },
                                { icon: Users, title: "Group Food Crawls", description: "Organize exciting food crawls for friends and family." },
                            ].map((item, index) => (
                                <VStack key={index} bg="whiteAlpha.100" p={6} rounded="lg" backdropFilter="blur(8px)" transition="transform 0.2s" _hover={{ transform: "scale(1.05)" }}>
                                    <Icon as={item.icon} h={12} w={12} color={`white`} />
                                    <Heading size="md">{item.title}</Heading>
                                    <Text color="gray.200">{item.description}</Text>
                                </VStack>
                            ))}
                        </Grid>
                    </Container>
                </Box>

                <Box as="section" id="testimonials" w="full" py={{ base: 12, md: 24, lg: 32 }} bg={useColorModeValue("#fbf6ff", "gray.800")}>
                    <Container maxW="container.lg" textAlign="center">
                        <VStack spacing={8} mb={16}>
                            <Heading
                                size={{ base: "xl", md: "2xl", lg: "3xl" }}
                                bgGradient="linear(to-r, purple.600, pink.500)"
                                bgClip="text"
                                textAlign="center"
                                fontWeight="extrabold"
                            >
                                What Our Food Adventurers Say
                            </Heading>
                            <Text
                                fontSize={FONT_SIZE}
                                color={useColorModeValue("purple.600", "purple.300")}
                                textAlign="center"
                                maxW="3xl"
                                mx="auto"
                            >
                                Don't just take our word for it - hear from the food lovers and businesses who've tasted success with Culinary Castle.
                            </Text>
                        </VStack>
                        <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={{ base: 6, md: 8 }}>
                            {[
                                {
                                    icon: Utensils,
                                    text: "Culinary Castle transformed our food tour business. Our customers love the interactive experience!",
                                    author: "Sarah J., Food Tour Operator",
                                    gradient: "linear(to-r, orange.400, red.400)",
                                    accentColor: "orange"
                                },
                                {
                                    icon: GraduationCap,
                                    text: "As a culinary instructor, Culinary Castle has made my classes so much more engaging for students!",
                                    author: "Mark T., Culinary Educator",
                                    gradient: "linear(to-r, yellow.400, orange.400)",
                                    accentColor: "yellow"
                                },
                                {
                                    icon: Heart,
                                    text: "I used Culinary Castle for a food date, and it was a huge hit!",
                                    author: "Alex R., Food Enthusiast",
                                    gradient: "linear(to-r, pink.400, red.400)",
                                    accentColor: "pink"
                                },
                            ].map((testimonial, index) => {
                                const colors = getAccentColors(testimonial.accentColor);
                                return (
                                    <TestimonialCard
                                        key={index}
                                        testimonial={testimonial}
                                        index={index}
                                        bgColor={testimonialBgColor}
                                        borderColor={colors.borderColor}
                                        iconBgColor={colors.iconBgColor}
                                        iconColor={colors.iconColor}
                                        textColor={testimonialTextColor}
                                        accentTextColor={colors.accentTextColor}
                                    />
                                );
                            })}
                        </Grid>
                    </Container>
                </Box>

                <Box as="section" id="how-it-works" w="full" py={{ base: 16, md: 24, lg: 32 }} bgGradient="linear(to-r, purple.600, pink.600)" color="white">
                    <Container maxW="container.lg" textAlign="center">
                        <Heading size={{ base: "xl", md: "2xl", lg: "3xl" }} mb={4}>How Culinary Castle Works</Heading>
                        <Text fontSize={FONT_SIZE} color="gray.200" mb={{ base: 8, md: 12 }}>
                            Creating mouthwatering food tours has never been easier. Follow these simple steps to get started on your culinary journey.
                        </Text>
                        <Grid templateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(4, 1fr)" }} gap={{ base: 6, md: 8 }}>
                            {[
                                { number: 1, title: "Sign Up", description: "Create your free account in seconds." },
                                { number: 2, title: "Design Your Tour", description: "Use our tools to create engaging food challenges and routes." },
                                { number: 3, title: "Invite Foodies", description: "Share your food tour with food enthusiasts." },
                                { number: 4, title: "Savor the Experience", description: "Watch as participants enjoy your culinary adventure!" },
                            ].map((step, index) => (
                                <VStack key={index}>
                                    <Box bg="white" color={`${["yellow", "orange", "purple", "pink"][index]}.500`} rounded="full" w={16} h={16} display="flex" alignItems="center" justifyContent="center" fontSize="2xl" fontWeight="bold" mb={4} className="animate-bounce" sx={{ animationDelay: `${index * 0.1}s` }}>{step.number}</Box>
                                    <Heading size="lg">{step.title}</Heading>
                                    <Text color="gray.200">{step.description}</Text>
                                </VStack>
                            ))}
                        </Grid>
                        <Box mt={12}>
                            <ScrollButton
                                to="#signup"
                                size="lg"
                                bg="white"
                                color="yellow.500"
                                _hover={{ bg: "gray.100", color: "yellow.600" }}
                                className="animate-pulse"
                            >
                                Get Started
                            </ScrollButton>
                        </Box>
                    </Container>
                </Box>

                <Box as="section" id="pricing" w="full" hidden={true} mt={24} bg={useColorModeValue("#fbf6ff", "gray.900")}>
                    <Container maxW="container.xl" textAlign="center">
                        <Heading
                            size={{ base: "2xl", md: "3xl", lg: "4xl" }}
                            bgGradient="linear(to-r, purple.600, pink.500)"
                            bgClip="text"
                            textAlign="center"
                            fontWeight="extrabold"
                        >
                            Flexible Pricing Options
                        </Heading>
                        <Text fontSize={FONT_SIZE} color="purple.600" _dark={{ color: "gray.300" }} mb={{ base: 10, md: 16 }}>
                            Flexible pricing options to suit every appetite, from solo food enthusiasts to large-scale tour operators.
                        </Text>
                        <Box
                            maxW="6xl"
                            mx="auto"
                            bg="#fbf6ff"
                            _dark={{ bg: "gray.800" }}
                            rounded="md"
                        >
                            <StripePricingTable
                                pricingTableId="prctbl_1Q9ysyHYWgR58j8dXmj9tdQ4"
                                publishableKey="pk_test_51NzWOmHYWgR58j8dqlpXjajAcBsDr3OOGRxLmstqhxT5KzVLlXW5Fx456UyU3ruXIC2oeMyKjuSnorDLJGa96eK9003b3hgKyG"
                            />
                        </Box>
                    </Container>
                </Box>

                {/* Replace the existing divider Box with this new one */}


                <Box as="section" id="faq" w="full" py={{ base: 12, md: 16, lg: 24 }} bg={useColorModeValue("#fbf6ff", "gray.900")}>
                    <Container maxW="container.lg">
                        <VStack spacing={8} align="stretch" mb={16}>

                            <Heading
                                size={{ base: "xl", md: "2xl", lg: "3xl" }}
                                bgGradient="linear(to-r, purple.600, pink.500)"
                                bgClip="text"
                                textAlign="center"
                                fontWeight="extrabold"
                            >
                                Frequently Asked Questions
                            </Heading>
                            <Text
                                fontSize={FONT_SIZE}
                                color={useColorModeValue("purple.600", "purple.300")}
                                textAlign="center"
                                maxW="3xl"
                                mx="auto"
                            >
                                Got questions? We've got answers. If you don't see your question here, reach out to our friendly support team.
                            </Text>
                        </VStack>
                        <Accordion allowMultiple>
                            {[
                                {
                                    icon: Lock,
                                    question: "Is Culinary Castle free to use?",
                                    answer: "Culinary Castle offers a 7-day free tour for all new users. After the tour, we have affordable plans starting from $29.99/month for individuals, food tour operators, and businesses."
                                },
                                {
                                    icon: Users,
                                    question: "Can I create food tours for large groups?",
                                    answer: "Absolutely! Culinary Castle supports tours for groups of all sizes, from small teams to large food festivals. Our business plan is perfect for larger groups, offering advanced features to manage and coordinate big events."
                                },
                                {
                                    icon: Share2,
                                    question: "Is there a mobile app available?",
                                    answer: "While we don't have a dedicated mobile app, our web application is fully responsive and works great on all devices, including smartphones and tablets. You can access all features through your mobile browser, ensuring a seamless experience on the go."
                                },
                                {
                                    icon: Briefcase,
                                    question: "Can I customize the look of my food tours?",
                                    answer: "Yes! Culinary Castle offers various customization options to brand your food tours and make them unique to your culinary event or organization. Advanced customization is available on our business plan, allowing you to tailor the experience to your specific needs."
                                },
                                {
                                    icon: Lock,
                                    question: "How secure is my data with Culinary Castle?",
                                    answer: "We take data security very seriously. All data is encrypted both in transit and at rest, and we use industry-standard security measures to protect your information. We regularly update our security protocols and comply with relevant data protection regulations to ensure your data remains safe."
                                },
                                {
                                    icon: Share2,
                                    question: "Can I integrate Culinary Castle with other tools?",
                                    answer: "Yes, Culinary Castle offers integrations with popular restaurant booking and review platforms. For integrations, our Business plan provides API access and advanced support, allowing you to connect Culinary Castle with your existing tools and workflows."
                                }
                            ].map((faq, index) => (
                                <FAQAccordionItem
                                    key={index}
                                    faq={faq}
                                    index={index}
                                    colors={faqColors}
                                />
                            ))}
                        </Accordion>
                    </Container>
                </Box>

                <Box as="section" id="signup" w="full" py={{ base: 16, md: 24, lg: 32 }} bgGradient="linear(to-r, purple.600, pink.600)" color="white">
                    <Container maxW="container.md" textAlign="center">
                        <VStack spacing={8}>
                            <Heading size={{ base: "xl", md: "2xl", lg: "3xl" }} fontWeight="bold">
                                Start Crafting Your Culinary Experiences
                            </Heading>
                            <Text maxW="600px" color="gray.200" fontSize={FONT_SIZE}>
                                Join thousands of food enthusiasts and tour creators who are already crafting unforgettable gastronomic experiences. Sign up now and taste the difference!
                            </Text>
                            <Box w="full" maxW="md">
                                <form onSubmit={handleSignupSubmit}>
                                    <VStack spacing={4}>
                                        <Input
                                            size="lg"
                                            bg="white"
                                            color="purple.600"
                                            placeholder="Enter your email"
                                            type="email"
                                            value={email}
                                            onChange={handleEmailChange}
                                            _placeholder={{ color: "gray.400" }}
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{
                                                borderColor: "purple.400",
                                                boxShadow: "0 0 0 1px purple.400"
                                            }}
                                            isInvalid={!isEmailValid}
                                            errorBorderColor="red.300"
                                            rounded="xl"
                                            borderWidth="2px"
                                        />
                                        {!isEmailValid && (
                                            <Text color="red.300" fontSize="sm" alignSelf="start">
                                                Please enter a valid email address.
                                            </Text>
                                        )}
                                        <motion.div
                                            whileHover={{ scale: 1.05 }}
                                            whileTap={{ scale: 0.95 }}
                                            style={{ width: "100%" }}
                                        >
                                            <Button
                                                type="submit"
                                                size="lg"
                                                w="full"
                                                bg="white"
                                                color="purple.600"
                                                fontWeight="bold"
                                                _hover={{
                                                    bg: "yellow.400",
                                                    color: "purple.700",
                                                    transform: "translateY(-2px)",
                                                    boxShadow: "lg",
                                                }}
                                                _active={{
                                                    bg: "yellow.500",
                                                    transform: "translateY(0)",
                                                    boxShadow: "md",
                                                }}
                                                transition="all 0.2s"
                                                isDisabled={!isEmailValid}
                                            >
                                                Sign Up
                                            </Button>
                                        </motion.div>
                                    </VStack>
                                </form>
                                <Text fontSize="sm" color="gray.300" mt={4}>
                                    By signing up, you agree to our{" "}
                                    <Text as={RouterLink} to="#" color="yellow.300" _hover={{ textDecoration: "underline" }}>
                                        Terms & Conditions
                                    </Text>
                                </Text>
                            </Box>
                        </VStack>
                    </Container>
                </Box>
            </Box>

            <Box as="footer" py={6} px={SPACING} borderTop="1px" borderColor="gray.200" bg="white" _dark={{ bg: "gray.800", borderColor: "gray.700" }}>
                <Flex direction={{ base: "column", sm: "row" }} gap={2} alignItems="center">
                    <Text fontSize="xs" color="purple.600" _dark={{ color: "purple.400" }}>© 2024 Culinary Castle. All rights reserved.</Text>
                    <Flex gap={4} ml={{ sm: "auto" }} mt={{ base: 2, sm: 0 }}>
                        {["Terms of Service", "Privacy", "Contact Us"].map((item) => (
                            <RouterLink key={item} to="#">
                                <Text fontSize="xs" color="purple.600" _dark={{ color: "purple.400" }} _hover={{ color: "purple.600", _dark: { color: "purple.400" }, textDecoration: "underline", textUnderlineOffset: "4px" }}>{item}</Text>
                            </RouterLink>
                        ))}
                    </Flex>
                </Flex>
            </Box>
        </Flex>
    );
}

const NavLink = ({ children, to, mobile = false }: { children: React.ReactNode, to: string, mobile?: boolean }) => {
    const handleClick = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!to.startsWith('#')) return;
        event.preventDefault();
        const id = to.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <RouterLink to={to} onClick={handleClick}>
            <Text
                px={3}
                py={2}
                rounded="md"
                fontWeight="700"
                color={useColorModeValue("purple.600", "purple.400")}
                position="relative"
                transition="all 0.3s"
                _hover={{
                    textDecoration: "none",
                    color: useColorModeValue("purple.600", "purple.300"),
                    bg: useColorModeValue("purple.50", "gray.700"),
                }}
                _after={{
                    content: '""',
                    position: "absolute",
                    bottom: 0,
                    left: "50%",
                    transform: "translateX(-50%)",
                    width: "0%",
                    height: "2px",
                    bg: "purple.500",
                    transition: "all 0.3s",
                }}
                w={mobile ? "full" : "auto"}
                textAlign={mobile ? "center" : "left"}
            >
                {children}
            </Text>
        </RouterLink>
    );
};

const ScrollButton = ({ to, children, ...props }: { to: string; children: React.ReactNode } & ButtonProps) => {
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        const id = to.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };

    return (
        <Button onClick={handleClick} {...props}>
            {children}
        </Button>
    );
};

const FeatureCard = ({ feature, index }: { feature: any; index: number }) => {
    const bgColor = useColorModeValue("white", "gray.800");
    const borderColor = useColorModeValue(`${feature.accentColor}.200`, "gray.700");
    const headingColor = useColorModeValue(`${feature.accentColor}.700`, "white");
    const textColor = useColorModeValue("gray.600", "gray.300");

    return (
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
            style={{ width: "100%" }}
        >
            <VStack
                bg={bgColor}
                p={8}
                rounded="xl"
                spacing={6}
                height="full"
                borderWidth="1px"
                borderColor={borderColor}
                shadow="lg"
                transition="all 0.3s"
                _hover={{
                    transform: "translateY(-8px)",
                    shadow: "2xl",
                }}
                position="relative"
                overflow="hidden"
            >
                <Box
                    bg={useColorModeValue(`${feature.accentColor}.50`, `${feature.accentColor}.900`)}
                    rounded="full"
                    p={4}
                    position="relative"
                    zIndex={1}
                    shadow="md"
                >
                    <Icon
                        as={feature.icon}
                        h={6}
                        w={6}
                        color={useColorModeValue(`${feature.accentColor}.500`, `${feature.accentColor}.200`)}
                    />
                </Box>

                <VStack spacing={4} align="center" zIndex={1}>
                    <Heading
                        size="md"
                        color={headingColor}
                        fontWeight="bold"
                        letterSpacing="tight"
                        textAlign="center"
                    >
                        {feature.title}
                    </Heading>
                    <Text
                        color={textColor}
                        textAlign="center"
                        fontSize="md"
                        lineHeight="tall"
                    >
                        {feature.description}
                    </Text>
                </VStack>
            </VStack>
        </motion.div>
    );
};

const FAQAccordionItem = ({
    faq,
    index,
    colors
}: {
    faq: { icon: any; question: string; answer: string; };
    index: number;
    colors: FAQColors;
}) => (
    <AccordionItem
        border="none"
        mb={4}
    >
        <motion.div
            initial={{ opacity: 0, y: 20 }}
            whileInView={{ opacity: 1, y: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.5, delay: index * 0.1 }}
        >
            <AccordionButton
                bg={colors.buttonBgColor}
                color={colors.textColor}
                px={5}
                py={4}
                _hover={{ bg: colors.buttonHoverBgColor }}
                rounded="xl"
                shadow="sm"
                transition="all 0.3s"
                borderWidth="1px"
                borderColor={colors.borderColor}
            >
                <HStack flex="1" spacing={4} align="center">
                    <Icon as={faq.icon} h={5} w={5} />
                    <Text textAlign="left" fontWeight="semibold">
                        {faq.question}
                    </Text>
                </HStack>
                <AccordionIcon />
            </AccordionButton>
            <AccordionPanel
                pb={4}
                px={5}
                pt={2}
                bg={colors.bgColor}
                rounded="xl"
                mt={2}
                shadow="sm"
                borderWidth="1px"
                borderColor={colors.borderColor}
            >
                <Text color={colors.contentTextColor}>
                    {faq.answer}
                </Text>
            </AccordionPanel>
        </motion.div>
    </AccordionItem>
);

const TestimonialCard = ({
    testimonial,
    index,
    bgColor,
    borderColor,
    iconBgColor,
    iconColor,
    textColor,
    accentTextColor
}: {
    testimonial: any;
    index: number;
    bgColor: string;
    borderColor: string;
    iconBgColor: string;
    iconColor: string;
    textColor: string;
    accentTextColor: string;
}) => (
    <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.5, delay: index * 0.1 }}
        style={{ width: "100%" }}
    >
        <VStack
            bg={bgColor}
            p={8}
            rounded="xl"
            spacing={6}
            height="full"
            borderWidth="1px"
            borderColor={borderColor}
            shadow="lg"
            transition="all 0.3s"
            _hover={{
                transform: "translateY(-8px)",
                shadow: "2xl",
            }}
            position="relative"
            overflow="hidden"
        >
            <Box
                bg={iconBgColor}
                rounded="full"
                p={4}
                position="relative"
                zIndex={1}
                shadow="md"
            >
                <Icon
                    as={testimonial.icon}
                    h={6}
                    w={6}
                    color={iconColor}
                />
            </Box>

            <VStack spacing={4} align="center" zIndex={1}>
                <Text
                    color={textColor}
                    textAlign="center"
                    fontSize="md"
                    lineHeight="tall"
                    fontStyle="italic"
                >
                    "{testimonial.text}"
                </Text>
                <Text
                    fontWeight="bold"
                    color={accentTextColor}
                >
                    - {testimonial.author}
                </Text>
            </VStack>
        </VStack>
    </motion.div>
);


