import React from "react";
import {
    Box,
    Heading,
    Text,
    VStack,
    HStack,
    Button,
    useToast,
    Icon,
    Badge,
    SimpleGrid,
    Card,
    CardHeader,
    CardBody,
    useColorModeValue,
} from "@chakra-ui/react";
import { useAuth } from "../../context/auth";
import { useCreateBillingPortalSessionMutation } from "../../graphql/generated";
import {
    CreditCardIcon,
    Building2Icon,
    CheckCircleIcon,
    AlertCircleIcon,
    ExternalLinkIcon,
} from "lucide-react";
import { useGetConnectAccountLinkMutation } from "../../graphql/generated";

type SubscriptionInfo = {
    status: "active" | "inactive" | "past_due";
    plan: "FREE" | "BASIC" | "PLUS";
    periodEnd: string;
    amount: number;
    hasConnectedAccount: boolean;
};

const useConnectAccount = () => {
    const [getConnectAccountLink] = useGetConnectAccountLinkMutation();
    const toast = useToast();

    return async () => {
        try {
            const { data } = await getConnectAccountLink();
            if (data?.getConnectAccountLink) {
                window.open(data.getConnectAccountLink, "_blank");
            } else {
                throw new Error("Failed to get connect account URL");
            }
        } catch (error) {
            toast({
                title: "Error connecting account",
                description: "Please try again later",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
};

const useBillingPortal = () => {
    const [createBillingPortalSession] = useCreateBillingPortalSessionMutation();
    const toast = useToast();

    return async () => {
        try {
            const { data } = await createBillingPortalSession();
            if (data?.createBillingPortalSession) {
                window.open(data.createBillingPortalSession, "_blank");
            } else {
                throw new Error("Failed to get billing portal URL");
            }
        } catch (error) {
            toast({
                title: "Error accessing billing portal",
                description: "Please try again later",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };
};

const SubscriptionCard = ({ subscriptionInfo, onManageClick }: { subscriptionInfo: SubscriptionInfo, onManageClick: () => void }) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('purple.200', 'purple.600');
    const textColor = useColorModeValue('purple.800', 'white');
    const secondaryTextColor = useColorModeValue('gray.600', 'gray.300');

    const statusColors = {
        active: "green",
        inactive: "gray",
        past_due: "red",
    };

    const planColors = {
        FREE: "gray",
        BASIC: "purple",
        PLUS: "yellow",
    };
    if (subscriptionInfo.amount === 0) {
        // return a smaller card with only the manage subscription button
        return (
            <Card
                bg={cardBg}
                borderWidth="1px"
                borderColor={borderColor}
                borderRadius="xl"
                boxShadow="lg"
                transition="all 0.3s"
                height={"15vh"}
            >
                <CardBody p={4}>
                    <VStack spacing={3} align="stretch">
                        <HStack justify="space-between">
                            <Text fontSize="md" fontWeight="medium" color={textColor}>
                                Free Plan
                            </Text>
                            <Badge colorScheme="gray" px={2} py={0.5} borderRadius="full" fontSize="xs">
                                FREE
                            </Badge>
                        </HStack>

                        <Button
                            onClick={onManageClick}
                            colorScheme="purple"
                            size="md"
                            w="full"
                            rightIcon={<Icon as={ExternalLinkIcon} />}
                            bgGradient="linear(to-r, purple.400, purple.600)"
                            _hover={{
                                bgGradient: "linear(to-r, purple.500, purple.700)",
                                transform: "translateY(-1px)",
                                shadow: "md"
                            }}
                        >
                            Upgrade Subscription
                        </Button>
                    </VStack>
                </CardBody>
            </Card>
        );
    }

    return (
        <Card
            bg={cardBg}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="xl"
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-4px)', shadow: 'xl' }}
            height="full"
        >
            <CardHeader borderBottomWidth="1px" borderColor={borderColor}>
                <HStack justify="space-between" align="center">
                    <HStack spacing={3}>
                        <Icon as={CreditCardIcon} boxSize={6} color="purple.500" />
                        <Heading size="md" color={textColor}>Current Subscription</Heading>
                    </HStack>
                    <Badge
                        colorScheme={statusColors[subscriptionInfo.status]}
                        variant="subtle"
                        px={3}
                        py={1}
                        borderRadius="full"
                        textTransform="uppercase"
                        letterSpacing="wider"
                    >
                        {subscriptionInfo.status.replace("_", " ")}
                    </Badge>
                </HStack>
            </CardHeader>
            <CardBody>
                <VStack align="stretch" spacing={6}>
                    <SimpleGrid columns={2} spacing={4}>
                        <VStack align="start" spacing={2}>
                            <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium">Current Plan</Text>
                            <Badge
                                colorScheme={planColors[subscriptionInfo.plan]}
                                px={3}
                                py={1}
                                borderRadius="full"
                                fontSize="md"
                            >
                                {subscriptionInfo.plan}
                            </Badge>
                        </VStack>
                        {subscriptionInfo.amount > 0 && (
                            <VStack align="start" spacing={2}>
                                <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium">Period End</Text>
                                <Text fontWeight="semibold" color={textColor}>{subscriptionInfo.periodEnd}</Text>
                            </VStack>
                        )}
                    </SimpleGrid>

                    {subscriptionInfo.amount > 0 && (<Box>
                        <Text color={secondaryTextColor} fontSize="sm" fontWeight="medium" mb={2}>Monthly Amount</Text>

                        <Text fontSize="3xl" fontWeight="bold" color={textColor}>
                            ${subscriptionInfo.amount.toFixed(2)}
                            <Text as="span" fontSize="sm" color={secondaryTextColor} ml={1}>/month</Text>
                        </Text>

                    </Box>
                    )}

                    <Button
                        onClick={onManageClick}
                        rightIcon={<ExternalLinkIcon />}
                        colorScheme="purple"
                        size="lg"
                        w="full"
                        bgGradient="linear(to-r, purple.400, purple.600)"
                        _hover={{
                            bgGradient: "linear(to-r, purple.500, purple.700)",
                            transform: "translateY(-1px)",
                            shadow: "md"
                        }}
                    >
                        Manage Subscription
                    </Button>
                </VStack>
            </CardBody>
        </Card>
    );
};

const ConnectAccountCard = ({ isConnected, onConnect, isFreeUser }: {
    isConnected: boolean,
    onConnect: () => void,
    isFreeUser?: boolean
}) => {
    const cardBg = useColorModeValue('white', 'gray.700');
    const borderColor = useColorModeValue('purple.200', 'purple.600');
    const textColor = useColorModeValue('purple.800', 'white');
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');

    return (
        <Card
            bg={cardBg}
            borderWidth="1px"
            borderColor={borderColor}
            borderRadius="xl"
            boxShadow="lg"
            transition="all 0.3s"
            _hover={{ transform: 'translateY(-4px)', shadow: 'xl' }}
            height={isFreeUser ? "15vh" : "full"}
        >
            {!isFreeUser ? (
                <><CardHeader borderBottomWidth="1px" borderColor={borderColor}>
                    <HStack spacing={3}>
                        <Icon as={Building2Icon} boxSize={6} color="purple.500" />
                        <Heading size="md" color={textColor}>Payment Account</Heading>
                    </HStack>
                </CardHeader>
                    <CardBody>
                        <VStack align="stretch" spacing={6}>
                            <VStack align="start" spacing={4}>
                                <HStack spacing={3}>
                                    <Icon
                                        as={isConnected ? CheckCircleIcon : AlertCircleIcon}
                                        color={isConnected ? "green.500" : "orange.500"}
                                        boxSize={5} />
                                    <Text color={textColor} fontSize="md" fontWeight="medium">
                                        {isConnected
                                            ? "Your account is connected"
                                            : "Connect your account to receive payments"}
                                    </Text>
                                </HStack>
                                <Text color={secondaryTextColor} fontSize="sm" pl={8}>
                                    {isConnected
                                        ? "You can now receive payments from participants joining your food tours. Your earnings will be automatically deposited into your connected bank account."
                                        : "Set up your payment account to start accepting payments from tour participants. This allows you to receive earnings directly to your bank account and manage your payouts."}
                                </Text>
                                <Text color={secondaryTextColor} fontSize="sm" pl={8}>
                                    {isConnected
                                        ? "Payments are processed securely and typically deposited within 2-3 business days of the tour completion."
                                        : "Our payment system is secure and compliant with industry standards. You'll need to provide basic business information and banking details to get started."}
                                </Text>
                            </VStack>

                            <Box pt={4}>
                                <Button
                                    onClick={onConnect}
                                    rightIcon={<ExternalLinkIcon />}
                                    colorScheme={isConnected ? "green" : "purple"}
                                    variant={isConnected ? "outline" : "solid"}
                                    size="lg"
                                    w="full"
                                    bgGradient={isConnected ? "none" : "linear(to-r, purple.400, purple.600)"}
                                    _hover={{
                                        bgGradient: isConnected ? "none" : "linear(to-r, purple.500, purple.700)",
                                        transform: "translateY(-1px)",
                                        shadow: "md"
                                    }}
                                >
                                    {isConnected ? "Manage Payment Account" : "Connect Payment Account"}
                                </Button>
                            </Box>
                        </VStack>
                    </CardBody></>
            ) : (
                <CardBody p={4}>
                    <VStack spacing={3} align="stretch">
                        <HStack justify="space-between">
                            <Text fontSize="md" fontWeight="medium" color={textColor}>
                                Payment Account
                            </Text>
                            <Badge colorScheme={isConnected ? "green" : "orange"} px={2} py={0.5} borderRadius="full" fontSize="xs">
                                {isConnected ? "Connected" : "Not Connected"}
                            </Badge>
                        </HStack>

                        <Button
                            onClick={onConnect}
                            colorScheme={isConnected ? "green" : "purple"}
                            size="md"
                            w="full"
                            rightIcon={<Icon as={ExternalLinkIcon} />}
                            variant={isConnected ? "outline" : "solid"}
                            bgGradient={isConnected ? "none" : "linear(to-r, purple.400, purple.600)"}
                            _hover={{
                                bgGradient: isConnected ? "none" : "linear(to-r, purple.500, purple.700)",
                                transform: "translateY(-1px)",
                                shadow: "md"
                            }}
                        >
                            {isConnected ? "Manage Account" : "Connect Account"}
                        </Button>
                    </VStack>
                </CardBody>
            )}
        </Card>
    );
};

const BillingPage = () => {
    const { user: tempUser } = useAuth();
    const handleBillingPortal = useBillingPortal();
    const handleConnectAccount = useConnectAccount();
    const bgColor = useColorModeValue('gray.50', 'gray.800');
    type User = typeof tempUser & {
        externalAccountId: string | null;
    };
    const user: User = {
        ...tempUser,
        externalAccountId: null,
    };
    const subscriptionInfo: SubscriptionInfo = {
        status: user?.userType === "FREE" ? "inactive" : "active",
        plan: user?.userType as "FREE" | "BASIC" | "PLUS",
        periodEnd: "December 31, 2024",
        amount: user?.userType === "FREE" ? 0 : user?.userType === "BASIC" ? 29.99 : 49.99,
        hasConnectedAccount: !!user?.externalAccountId,
    };

    const isFreeUser = user?.userType === "FREE";

    return (
        <Box p={4} bg={bgColor} minH="100vh">
            <VStack spacing={8} align="stretch">

                <SimpleGrid columns={{ base: 1, md: 2 }} spacing={8} mt={4}>
                    <SubscriptionCard
                        subscriptionInfo={subscriptionInfo}
                        onManageClick={handleBillingPortal}
                    />
                    <ConnectAccountCard
                        isConnected={subscriptionInfo.hasConnectedAccount}
                        onConnect={handleConnectAccount}
                        isFreeUser={isFreeUser}
                    />
                </SimpleGrid>
                <Card
                    bg={useColorModeValue('purple.50', 'purple.900')}
                    borderRadius="xl"
                    boxShadow="md"
                >
                    <CardBody p={6}>
                        <VStack align="start" spacing={4}>
                            <Heading size="md" color="purple.700" _dark={{ color: 'purple.200' }}>
                                Need Help?
                            </Heading>
                            <Text color="purple.600" _dark={{ color: 'purple.300' }}>
                                If you have any questions about billing or your subscription, our support team is here to help.
                            </Text>
                            <Button
                                leftIcon={<Icon as={ExternalLinkIcon} />}
                                variant="link"
                                color="purple.600"
                                _dark={{ color: 'purple.200' }}
                                onClick={() => {
                                    window.location.href = "mailto:support@culinarycastle.com";
                                }}
                            >
                                Contact Support
                            </Button>
                        </VStack>
                    </CardBody>
                </Card>
            </VStack>
        </Box>
    );
};

export default BillingPage;
