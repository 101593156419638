import React from "react";
import { 
  VStack, 
  Heading, 
  Text, 
  Button, 
  Icon, 
  Stack,
  Box,
  HStack,
  Textarea,
  useToast,
  Divider,
  Image
} from "@chakra-ui/react";
import { FaStar } from "react-icons/fa";
import { Play, Share2, Mail, ThumbsUp } from "lucide-react";
import { motion } from "framer-motion";
import { isDarkColor } from "../../helpers/color-util";
import { useState } from "react";

const MotionBox = motion(Box as any);
const MotionVStack = motion(VStack as any);

type FinishedStateProps = {
  hunt: {
    color?: string;
    logo?: string;
    allowRetries?: boolean;
    allowInvites?: boolean;
    allowReviews?: boolean;
  };
  votedRestaurant: string;
  votedRestaurantName: string;
  totalPhotos: number;
  onStartNewTour: () => void;
  onShare: () => void;
};

export const FinishedState: React.FC<FinishedStateProps> = ({
  hunt,
  votedRestaurant,
  votedRestaurantName,
  totalPhotos,
  onStartNewTour,
  onShare,
}) => {
  const [rating, setRating] = useState(0);
  const [review, setReview] = useState("");
  const toast = useToast();

  const handleSubmitReview = async () => {
    // Implement review submission logic here
    toast({
      title: "Thank you for your feedback!",
      description: "Your review helps us improve the experience for everyone.",
      status: "success",
      duration: 3000,
    });
    setRating(0);
    setReview("");
  };

  return (
    <VStack 
      spacing={{ base: 6, md: 8 }} 
      align="center" 
      justify="center" 
      p={{ base: 4, md: 6 }} 
      width="100%" 
      maxWidth="500px" 
      mx="auto"
    >
      <MotionBox
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ type: "spring", stiffness: 260, damping: 20 }}
      >
        {hunt?.logo ? (
          <Image 
            src={`data:image/jpeg;base64,${hunt.logo}`}
            alt="Hunt Logo"
            w={{ base: 24, md: 32 }}
            h={{ base: 24, md: 32 }}
            objectFit="contain"
          />
        ) : (
          <Box 
            w={{ base: 24, md: 32 }}
            h={{ base: 24, md: 32 }}
            bg={`${hunt?.color || "orange.500"}20`}
            borderRadius="full"
          />
        )}
      </MotionBox>

      <MotionVStack
        spacing={{ base: 2, md: 3 }}
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.2 }}
        width="100%"
      >
        <Heading 
          size={{ base: "lg", md: "xl" }}
          bgGradient={`linear(to-r, ${hunt?.color || "orange.500"}, purple.500)`}
          bgClip="text"
          textAlign="center"
          px={2}
        >
          Congratulations, Food Explorer! 🎉
        </Heading>
        
        <Text 
          fontSize={{ base: "md", md: "lg" }} 
          color="gray.600" 
          textAlign="center"
          px={2}
        >
          Thanks for capturing {totalPhotos} delicious moments! <br />
        </Text>

        <Box 
          p={{ base: 3, md: 4 }}
          bg={`${hunt?.color}15` || "orange.50"} 
          borderRadius="xl"
          borderWidth="2px"
          borderColor={hunt?.color || "orange.500"}
          width="100%"
        >
          <Text 
            fontSize={{ base: "sm", md: "md" }} 
            textAlign="center" 
            fontWeight="medium"
          >
            Your favorite spot: <br />
            <Text 
              as="span" 
              color={hunt?.color || "orange.500"} 
              fontSize={{ base: "md", md: "lg" }} 
              fontWeight="bold"
            >
              {votedRestaurantName || "Not selected"}
            </Text>
          </Text>
        </Box>
        <i style={{ fontSize: "0.8em" }}>We'll email you the results and highlights after the tour ends.</i>
      </MotionVStack>

      {hunt?.allowReviews && <Divider />}

      {hunt?.allowReviews && (
        <MotionVStack
          spacing={{ base: 3, md: 4 }}
          width="100%"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4 }}
        >
          <Heading 
            size={{ base: "sm", md: "md" }} 
            color="gray.700"
          >
            Share Your Experience
          </Heading>
          
          <HStack spacing={{ base: 1, md: 2 }}>
            {[1, 2, 3, 4, 5].map((value) => (
              <Icon
                key={value}
                as={FaStar}
                w={{ base: 6, md: 8 }}
                h={{ base: 6, md: 8 }}
                color={value <= rating ? "yellow.400" : "gray.200"}
                cursor="pointer"
                onClick={() => setRating(value)}
              />
            ))}
          </HStack>

          <Textarea
            value={review}
            onChange={(e) => setReview(e.target.value)}
            placeholder="Tell us about your food tour experience..."
            resize="none"
            borderColor="gray.200"
            _focus={{ borderColor: hunt?.color || "orange.500" }}
            height={{ base: "80px", md: "100px" }}
            fontSize={{ base: "sm", md: "md" }}
            p={3}
          />

          <Button
            leftIcon={<ThumbsUp size={18} />}
            onClick={handleSubmitReview}
            width="100%"
            size={{ base: "sm", md: "md" }}
            style={{ 
              backgroundColor: hunt?.color || "orange.500",
              color: hunt?.color && isDarkColor(hunt.color) ? "white" : "black"
            }}
            isDisabled={!rating || !review}
          >
            Submit Feedback
          </Button>
        </MotionVStack>
      )}

      <Stack
        direction={{ base: "column", md: "row" }}
        spacing={{ base: 2, md: 3 }}
        width="100%"
        maxWidth="400px"
      >
        {hunt?.allowRetries && (
          <Button
            leftIcon={<Play size={18} />}
            style={{ 
              backgroundColor: hunt?.color || "orange.500", 
              color: hunt?.color && isDarkColor(hunt.color) ? "white" : "black" 
            }}
            onClick={onStartNewTour}
            width="100%"
            size={{ base: "sm", md: "md" }}
          >
            Start New Tour
          </Button>
        )}
        {hunt?.allowInvites && (
          <Button
            leftIcon={<Share2 size={18} />}
            onClick={onShare}
            variant="outline"
            width="100%"
            size={{ base: "sm", md: "md" }}
            style={{ borderColor: hunt?.color || "orange.500" }}
          >
            Share Results
          </Button>
        )}
      </Stack>
    </VStack>
  );
}; 