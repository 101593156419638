import React from "react";
import { Box, BoxProps, useColorModeValue } from "@chakra-ui/react";

type CardWrapperProps = BoxProps & {
  children: React.ReactNode;
};

const CardWrapper: React.FC<CardWrapperProps> = ({ children, ...props }) => {
  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue('purple.200', 'purple.700');

  return (
    <Box
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      boxShadow="lg"
      overflow="hidden"
      bg={bgColor}
      w="100%"
      mb={4}
      {...props}
    >
      {children}
    </Box>
  );
};

export default CardWrapper; 