import React from 'react';
import { VStack, Box, Text, Flex, useColorModeValue, Link, Skeleton, Alert, AlertIcon } from '@chakra-ui/react';
import QuickStats from '../../components/quick-stats-cards';
import ActiveTours from '../../components/tours-card';
import RecentActivity from '../../components/recent-activity-card';
import ParticipantsChat from '../../components/participants-chat';
import ShowChallenges from '../../components/challenges-card';
import TransactionCard from '../../components/transactions-card';
import { useAuth } from '../../context/auth';
import { capitalize } from 'lodash';
import { LoadingProvider } from "../../context/loading";
import { useGetHuntsQuery, useGetChallengesQuery } from '../../graphql/generated';

const BlurredCard = ({ children, title, types }: { children: React.ReactNode; title: string; types: string[] }) => {
  const blurBg = useColorModeValue('rgba(255, 255, 255, 0.8)', 'rgba(26, 32, 44, 0.8)');

  return (
    <Box position="relative" padding={4}>
      {children}
      <Box
        position="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        backdropFilter="blur(4px)"
        bg={blurBg}
        borderRadius="lg"
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
      >
        <Text fontSize="xl" fontWeight="bold" mb={2}>Upgrade to access {title}</Text>
        <Text fontSize="sm">This feature is available for <Link href="/#pricing" fontStyle={"italic"} >{types.map(type => capitalize(type)).join(" and ")}</Link> plans.</Text>
      </Box>
    </Box>
  );
};

const DashboardContent = () => {
  const { user } = useAuth();
  const userType = user?.userType || "FREE";

  const {
    loading: toursLoading,
    error: toursError
  } = useGetHuntsQuery({
    pollInterval: 5000,
    fetchPolicy: 'network-only'
  });

  const {
    loading: challengesLoading,
    error: challengesError
  } = useGetChallengesQuery({
    pollInterval: 5000,
    fetchPolicy: 'network-only'
  });

  if (toursLoading || challengesLoading) {
    return <Box p={6}><Skeleton height="400px" /></Box>;
  }

  if (toursError || challengesError) {
    return (
      <Box p={6}>
        <Skeleton height="400px" />
      </Box>
    );
  }

  return (
    <Box p={6}>
      <VStack spacing={6} align="stretch">
        <QuickStats userType={userType} />
        {/* Temporarily hidden:
        <TransactionCard />
        {userType === 'FREE' ? (
          <Flex direction={{ base: 'column', md: 'row' }} gap={6}>
            <Box flex="1">
              <BlurredCard title="Participants Chat" types={["PLUS"]}>
                <ParticipantsChat />
              </BlurredCard>
            </Box>
          </Flex>
        ) : (
          <>
            {userType === 'PLUS' && <ParticipantsChat />}
          </>
        )}
        */}
        <ActiveTours userType={userType} />
        <ShowChallenges userType={userType} />
        <RecentActivity userType={userType} />
      </VStack>
    </Box>
  );
};

const DashboardHome = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  return (
    <LoadingProvider>
      <Box bg={bgColor} minH="100vh">
        <DashboardContent />
      </Box>
    </LoadingProvider>
  );
};

export default DashboardHome;
