import React, { useState, useCallback } from 'react';
import { Box, VStack, Heading, Text, Button, Icon, Progress } from '@chakra-ui/react';
import { useSwipeable } from 'react-swipeable';
import { Play, Check, ArrowLeft, ArrowRight } from 'lucide-react';
import PoweredByFooter from "../../components/shared/powered-by-footer";

type Question = {
    id: number;
    text: string;
    options: string[];
    correctAnswer: string;
  };

const questions: Question[] = [
  {
    id: 1,
    text: "What is the capital of France?",
    options: ["London", "Berlin", "Paris", "Madrid"],
    correctAnswer: "Paris"
  },
  {
    id: 2,
    text: "Which planet is known as the Red Planet?",
    options: ["Venus", "Mars", "Jupiter", "Saturn"],
    correctAnswer: "Mars"
  },
  {
    id: 3,
    text: "Who painted the Mona Lisa?",
    options: ["Vincent van Gogh", "Pablo Picasso", "Leonardo da Vinci", "Michelangelo"],
    correctAnswer: "Leonardo da Vinci"
  },
  {
    id: 4,
    text: "What is the largest mammal in the world?",
    options: ["African Elephant", "Blue Whale", "Giraffe", "Hippopotamus"],
    correctAnswer: "Blue Whale"
  },
  {
    id: 5,
    text: "Which element has the chemical symbol 'O'?",
    options: ["Gold", "Silver", "Oxygen", "Iron"],
    correctAnswer: "Oxygen"
  }
];

const PlayGame: React.FC = () => {
  const [gameState, setGameState] = useState<'intro' | 'playing' | 'finished'>('intro');
  const [currentQuestion, setCurrentQuestion] = useState(0);
  const [score, setScore] = useState(0);

  const startGame = useCallback(() => setGameState('playing'), []);

  const handleAnswer = useCallback((answer: string) => {
    if (questions[currentQuestion].correctAnswer === answer) {
      setScore((prev) => prev + 1);
    }
    setCurrentQuestion((prev) => prev + 1);
    if (currentQuestion === questions.length - 1) {
      setGameState('finished');
    }
  }, [currentQuestion]);

  const renderIntro = useCallback(() => (
    <VStack spacing={6} align="center" justify="center" p={8}>
      <Icon as={Play} w={12} h={12} color="purple.500" />
      <Heading size="lg" color="black" textAlign="center">
        Welcome to the Quiz Game!
      </Heading>
      <Text color="gray.600" textAlign="center">
        Swipe through questions and tap to answer. Test your knowledge!
      </Text>
      <Button
        leftIcon={<Play />}
        colorScheme="purple"
        onClick={startGame}
        width="100%"
        maxWidth="300px"
      >
        Start Game
      </Button>
    </VStack>
  ), [startGame]);

  const renderQuestion = useCallback(() => {
    const question = questions[currentQuestion];
    return (
      <VStack spacing={6} align="stretch" justify="center" p={8} width="100%">
        <Progress value={(currentQuestion / questions.length) * 100} colorScheme="purple" />
        <Heading size="md" color="black" textAlign="center">
          {question.text}
        </Heading>
        {question.options.map((option, index) => (
          <Button
            key={index}
            onClick={() => handleAnswer(option)}
            colorScheme="blue"
            variant="outline"
            size="lg"
          >
            {option}
          </Button>
        ))}
        <Text color="gray.600" textAlign="center">
          Question {currentQuestion + 1} of {questions.length}
        </Text>
      </VStack>
    );
  }, [currentQuestion, handleAnswer]);

  const renderFinished = useCallback(() => (
    <VStack spacing={6} align="center" justify="center" p={8}>
      <Icon as={Check} w={12} h={12} color="green.500" />
      <Heading size="lg" color="black" textAlign="center">
        Congratulations!
      </Heading>
      <Text color="gray.600" textAlign="center">
        Your score: {score}/{questions.length}
      </Text>
      <Button
        leftIcon={<Play />}
        colorScheme="purple"
        onClick={() => {
          setGameState('intro');
          setCurrentQuestion(0);
          setScore(0);
        }}
        width="100%"
        maxWidth="300px"
      >
        Play Again
      </Button>
    </VStack>
  ), [score]);

  const handlers = useSwipeable({
    onSwipedLeft: () => currentQuestion < questions.length - 1 && setCurrentQuestion(prev => prev + 1),
    onSwipedRight: () => currentQuestion > 0 && setCurrentQuestion(prev => prev - 1),
  });

  const renderContent = useCallback(() => {
    switch (gameState) {
      case 'intro': return renderIntro();
      case 'playing': return renderQuestion();
      case 'finished': return renderFinished();
    }
  }, [gameState, renderIntro, renderQuestion, renderFinished]);
  const bgImage = "url('/images/treasure.jpg')";

  return (
    <Box
      {...handlers}
      bgGradient="linear-gradient(to bottom right, purple.500, pink.300)"
      minHeight="100vh"
      display="flex"
      flexDirection="column"
      bgImage={bgImage}
      bgSize="cover"
      bgPosition="center"
      bgRepeat="no-repeat"
      alignItems="center"
      justifyContent="center"
      p={4}
    >
      <Box
        bg="white"
        borderRadius="xl"
        maxWidth="md"
        width="100%"
        overflow="hidden"
        boxShadow="xl"
      >
        <Box bg="purple.500" color="white" p={4}>
          <Heading size="lg" textAlign="center">Quiz Game</Heading>
        </Box>
        {renderContent()}
      </Box>
      {gameState === 'playing' && (
        <Box mt={4} display="flex" justifyContent="space-between" width="100%" maxWidth="md">
          <Button
            leftIcon={<ArrowLeft />}
            onClick={() => currentQuestion > 0 && setCurrentQuestion(prev => prev - 1)}
            isDisabled={currentQuestion === 0}
            colorScheme="whiteAlpha"
          >
            Previous
          </Button>
          <Button
            rightIcon={<ArrowRight />}
            onClick={() => currentQuestion < questions.length - 1 && setCurrentQuestion(prev => prev + 1)}
            isDisabled={currentQuestion === questions.length - 1}
            colorScheme="whiteAlpha"
          >
            Next
          </Button>
        </Box>
      )}
      <PoweredByFooter />
    </Box>
  );
};

export default PlayGame;
