import React, { useState } from 'react';
import {
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  Heading,
  Flex,
  Tooltip,
  Box,
} from '@chakra-ui/react';
import { useCreateHuntMutation, useGetChallengesQuery, useEditHuntMutation } from '../graphql/generated';
import { z } from 'zod';
import HuntForm from './tour-form';
import HuntPreview from './tour-preview';
import Banner from "./shared/banner";
import { useAuth } from '../context/auth';
import { format, addDays } from 'date-fns';
import { useToast } from '@chakra-ui/react';
import { CheckIcon } from '@chakra-ui/icons';

const createTourSchema = z.object({
  id: z.string().optional(),
  title: z.string().min(1, "Title is required"),
  description: z.string().optional(),
  backgroundMediaId: z.string().optional(),
  logoMediaId: z.string().optional(),
  paymentFee: z.number().min(0, "Payment fee must be non-negative").optional().default(0),
  slug: z.string().optional(),
  image: z.string().optional(),
  color: z.string().optional(),
  startDate: z.string().min(1, "Start date is required"),
  endDate: z.string().min(1, "End date is required"),
  address: z.string().optional().default('Virtual'),
  radius: z.number().min(0, "Radius must be non-negative").optional().default(0),
  isPublic: z.boolean(),
  allowReviews: z.boolean(),
  allowRetries: z.boolean(),
  allowInvites: z.boolean(),
  challenges: z.array(z.string()).min(1, "At least one challenge is required"),
});

export type CreateHuntFormData = z.infer<typeof createTourSchema>;

type TourDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  initialData?: CreateHuntFormData;
  isEditing?: boolean;
  onSuccess?: () => void;
};

const TourDrawer = ({ isOpen, onClose, initialData, isEditing = false, onSuccess }: TourDrawerProps) => {
  const [createHunt] = useCreateHuntMutation();
  const [editHunt] = useEditHuntMutation();
  const [isFormValid, setIsFormValid] = useState(false);
  const [errors, setErrors] = useState<Partial<CreateHuntFormData>>({});
  const [activeTab, setActiveTab] = useState<'edit' | 'preview'>('edit');
  const { data: challengesData, loading: challengesLoading } = useGetChallengesQuery();
  const [showBanner, setShowBanner] = useState(false);
  const [bannerProps, setBannerProps] = useState<{
    type: "success" | "error" | "info";
    title: string;
    message: string;
  }>({
    type: "success",
    title: "",
    message: ""
  });
  const {user} = useAuth();

  const isPremiumUser = (user?.userType === "PLUS") || (user?.userType === "BASIC");

  const initialFormData: CreateHuntFormData = {
    id: undefined,
    title: "",
    description: "",
    slug: "",
    color: "#6B46C1", // Default purple color
    startDate: format(new Date(), "yyyy-MM-dd'T'HH:mm"),
    endDate: format(addDays(new Date(), 1), "yyyy-MM-dd'T'HH:mm"),
    address: "",
    radius: 100,
    paymentFee: 0,
    isPublic: false,
    allowReviews: true,
    allowRetries: true,
    allowInvites: true,
    challenges: [],
    ...initialData,
  };

  const [formData, setFormData] = useState<CreateHuntFormData>(initialFormData);

  const getChangedFields = (
    current: CreateHuntFormData,
    initial: CreateHuntFormData
  ): Partial<CreateHuntFormData> => {
    const changes: any = {};
    const fieldsToCompare: (keyof CreateHuntFormData)[] = [
      'title',
      'description',
      'backgroundMediaId',
      'logoMediaId',
      'paymentFee',
      'slug',
      'image',
      'color',
      'startDate',
      'endDate',
      'address',
      'radius',
      'isPublic',
      'allowReviews',
      'allowRetries',
      'allowInvites',
      'challenges'
    ];
    
    fieldsToCompare.forEach((key) => {
      if (JSON.stringify(current[key]) !== JSON.stringify(initial[key])) {
        changes[key] = current[key];
      }
    });
    
    return changes;
  };

  const handleSubmit = async () => {
    try {
      const inputData = {
        id: formData.id,
        title: formData.title,
        description: formData.description,
        backgroundMediaId: formData.backgroundMediaId,
        logoMediaId: formData.logoMediaId,
        slug: formData.slug,
        color: formData.color,
        startDate: new Date(formData.startDate).toISOString(),
        endDate: new Date(formData.endDate).toISOString(),
        address: formData.address || "Virtual",
        radius: formData.radius || 0,
        paymentFee: formData.paymentFee || 0,
        isPublic: formData.isPublic,
        allowReviews: formData.allowReviews,
        allowRetries: formData.allowRetries,
        allowInvites: formData.allowInvites,
        challenges: formData.challenges
      };

      if (isEditing) {
        const changedFields = getChangedFields(inputData, initialFormData);
        if (Object.keys(changedFields).length === 0) {
          setBannerProps({
            type: "info",
            title: "No Changes",
            message: "No changes were made to the tour"
          });
          setShowBanner(true);
          return;
        }
        const { data } = await editHunt({
          variables: { 
            input: {
              id: formData.id,
              ...changedFields,
            }
          },
        });
        if (data?.registerHunt) {
          setBannerProps({
            type: "success",
            title: "Success",
            message: "Tour has been updated successfully"
          });
          setShowBanner(true);
        }
      } else {
        const { data } = await createHunt({
          variables: { input: inputData },
        });
        if (data?.registerHunt) {
          setBannerProps({
            type: "success",
            title: "Success",
            message: "New tour has been created successfully"
          });
          setShowBanner(true);
        }
      }
      
      onSuccess?.();
      onClose();
    } catch (error) {
      console.error('Error submitting form:', error);
      setBannerProps({
        type: "error",
        title: "Error",
        message: error instanceof Error ? error.message : "An error occurred while saving the tour"
      });
      setShowBanner(true);
    }
  };

  const isPreviewDisabled = !formData.title || !formData.startDate || !formData.endDate || formData.challenges.length === 0;
  const getMissingFieldsMessage = () => {
    const missingFields = [];
    if (!formData.title) missingFields.push("Title");
    if (!formData.startDate) missingFields.push("Start Date");
    if (!formData.endDate) missingFields.push("End Date");
    if (formData.challenges.length === 0) missingFields.push("at least one location");
    if (missingFields.length > 1) {
      return `${missingFields.slice(0, -1).join(", ")} and ${missingFields.slice(-1)}`;
    }
    return missingFields.join(", ");
  };

  return (
    <>
      <Banner
        isVisible={showBanner}
        onClose={() => setShowBanner(false)}
        title={bannerProps.title}
        message={bannerProps.message}
        type={bannerProps.type}
      />
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="0.5px" bg="purple.50" p={3}>
            <Heading size="md" color="purple.600">
              {isEditing ? "Edit Tour" : "Create Tour"}
            </Heading>
            <Text fontSize="xs" color="gray.600" mt={1}>
              {isEditing ? "Update your tour details" : "Design your next exciting tour"}
            </Text>
          </DrawerHeader>

          <DrawerBody p={0}>
            <Box pb={{ base: "inherit", md: "inherit" }}>
              <Tabs
                index={activeTab === 'edit' ? 0 : 1}
                onChange={(index) => setActiveTab(index === 0 ? 'edit' : 'preview')}
                height="100%"
              >
                <TabList>
                  <Tab>Edit</Tab>
                  <Tooltip label={isPreviewDisabled ? `${getMissingFieldsMessage()} is required` : ""} isDisabled={!isPreviewDisabled}>
                    <Tab isDisabled={isPreviewDisabled}>Preview</Tab>
                  </Tooltip>
                </TabList>
                <TabPanels>
                  <TabPanel bg="purple.50" p={4}>
                    <HuntForm
                      formData={formData}
                      setFormData={setFormData}
                      isPremiumUser={isPremiumUser}
                      errors={errors}
                      setErrors={setErrors}
                      challengesData={challengesData}
                      challengesLoading={challengesLoading}
                      setIsFormValid={setIsFormValid}
                      isEditing={isEditing}
                    />
                  </TabPanel>
                  <TabPanel p={4}>
                    <HuntPreview formData={formData} />
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </Box>
          </DrawerBody>

          <DrawerFooter
            borderTopWidth="1px"
            bg="white"
            p={4}
            position="sticky"
            bottom={0}
            width="100%"
            backgroundColor="white"
            zIndex={1000}
            boxShadow="0 -2px 6px rgba(0,0,0,0.1)"
          >
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button 
              colorScheme="purple" 
              onClick={handleSubmit}
              isDisabled={!isFormValid}
            >
              {isEditing ? "Update Tour" : "Create Tour"}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default TourDrawer;
