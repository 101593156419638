import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  Spinner,
  Alert,
  AlertIcon,
  useColorModeValue,
  Badge,
  Flex,
  Icon,
  Button,
  Container,
  useBreakpointValue,
  Card,
  CardBody,
  Divider,
  AspectRatio,
  HStack,
  Link,
  Grid,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetChallengeQuery } from '../../graphql/generated';
import { MapPin, Trophy, Clock, HelpCircle, ArrowLeft } from 'lucide-react';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import env from '../../env';

const MAPBOX_ACCESS_TOKEN = env.REACT_APP_MAPBOX_TOKEN;

const ChallengeDetailPage = () => {
  const { locationId } = useParams<{ locationId: string }>();
  const navigate = useNavigate();
  const mapContainer = React.useRef<HTMLDivElement>(null);
  const map = React.useRef<mapboxgl.Map | null>(null);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);

  const { data, loading, error } = useGetChallengeQuery({
    variables: { challengeId: locationId || '' },
    skip: !locationId,
  });

  const bgColor = useColorModeValue('gray.50', 'gray.900');
  const cardBg = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('purple.200', 'purple.700');
  const textColor = useColorModeValue('purple.900', 'white');
  const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    if (!data?.challenge?.address) return;

    const geocodeAddress = async (address: string) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
            address
          )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
        );
        const data = await response.json();
        if (data.features && data.features.length > 0) {
          setCoordinates(data.features[0].center);
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    };

    geocodeAddress(data?.challenge?.address || '');
  }, [data?.challenge?.address]);

  useEffect(() => {
    if (!coordinates || !mapContainer.current) return;

    mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN || '';

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v12',
      center: coordinates,
      zoom: 12,
      interactive: true,
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const marker = new mapboxgl.Marker({ color: '#805AD5' })
      .setLngLat(coordinates)
      .addTo(map.current);

    const popup = new mapboxgl.Popup({ offset: 25 })
      .setHTML(`
        <div style="padding: 8px;">
          <h3 style="font-weight: bold; margin-bottom: 4px; color: #805AD5;">${data?.challenge?.title}</h3>
          <p style="color: #666;">${data?.challenge?.address}</p>
        </div>
      `);

    marker.setPopup(popup);

    return () => {
      map.current?.remove();
    };
  }, [coordinates, data?.challenge]);

  if (loading) return (
    <Flex justify="center" align="center" minHeight="100vh" bg={bgColor}>
      <VStack spacing={4}>
        <Spinner size="xl" color="purple.500" thickness="4px" speed="0.65s" />
        <Text color={secondaryTextColor} fontSize="lg">Loading challenge details...</Text>
      </VStack>
    </Flex>
  );

  if (error) return (
    <Container maxW="4xl" p={8}>
      <Alert status="error" variant="left-accent" borderRadius="lg">
        <AlertIcon />
        <VStack align="start" spacing={2}>
          <Text fontWeight="medium">Error loading challenge</Text>
          <Text fontSize="sm">{error.message}</Text>
        </VStack>
      </Alert>
    </Container>
  );

  const challenge = data?.challenge;
  if (!challenge) return null;

  return (
    <Box bg={bgColor} minH="100vh" py={8}>
      <Container maxW="4xl">
        <VStack spacing={8} align="stretch">
          <Flex
            direction={isMobile ? "column" : "row"}
            justify="space-between"
            align={isMobile ? "flex-start" : "center"}
            gap={4}
          >
            <VStack align="start" spacing={2}>
              <Button
                leftIcon={<Icon as={ArrowLeft} />}
                variant="ghost"
                color="purple.500"
                onClick={() => navigate(-1)}
                size="sm"
                _hover={{ bg: 'purple.50' }}
              >
                Back
              </Button>
              <Heading 
                as="h1" 
                size={{ base: "xl", md: "2xl" }} 
                bgGradient="linear(to-r, purple.600, pink.500)"
                bgClip="text"
              >
                {challenge.title}
              </Heading>
            </VStack>
            <Badge
              colorScheme={getDifficultyColor(challenge.gameDifficulty)}
              px={3}
              py={1}
              borderRadius="full"
              textTransform="capitalize"
              fontSize="md"
            >
              Restaurant
            </Badge>
          </Flex>

          <Card 
            bg={cardBg} 
            borderWidth="1px" 
            borderColor={borderColor} 
            borderRadius="xl"
            overflow="hidden"
            boxShadow="lg"
            transition="all 0.3s"
          >
            <CardBody>
              <VStack spacing={6} align="stretch">
                <Box>
                  <Text color={secondaryTextColor} fontSize="lg" fontWeight="medium" mb={3}>
                    About this Location
                  </Text>
                  <Text color={textColor} fontSize="md" lineHeight="tall">
                    {challenge.description}
                  </Text>
                </Box>

                <Divider />

                <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={6}>
                  <VStack align="start" spacing={4}>
                    <Flex align="center" width="100%">
                      <Icon as={MapPin} color="purple.500" boxSize={5} mr={3} />
                      <Link 
                        href={`https://maps.google.com/?q=${encodeURIComponent(challenge.address)}`}
                        isExternal
                        color={textColor}
                        fontSize="md"
                        _hover={{ color: "purple.500", textDecoration: "none" }}
                      >
                        {challenge.address}
                      </Link>
                    </Flex>
                  </VStack>

                  <VStack align="start" spacing={4}>
                  <Flex align="center" width="100%">
                      <Icon as={Trophy} color="yellow.500" boxSize={5} mr={3} />
                      <Text color={textColor} fontSize="md">
                        {challenge.points} points
                      </Text>
                    </Flex>
                  </VStack>
                </Grid>

                <Divider />

                <Box>
                  <Text color={secondaryTextColor} fontSize="lg" fontWeight="medium" mb={3}>
                    Location
                  </Text>
                  <AspectRatio ratio={16/16} width="100%" borderRadius="xl" overflow="hidden">
                    <Box ref={mapContainer} />
                  </AspectRatio>
                </Box>
              </VStack>
            </CardBody>
          </Card>
        </VStack>
      </Container>
    </Box>
  );
};

const getDifficultyColor = (difficulty: string) => {
  switch (difficulty.toLowerCase()) {
    case 'easy': return 'green';
    case 'medium': return 'yellow';
    case 'hard': return 'orange';
    case 'expert': return 'red';
    default: return 'gray';
  }
};

export default ChallengeDetailPage;
