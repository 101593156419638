import React from 'react';
import { Box, VStack, Heading, Text, Button, Icon, Spinner } from '@chakra-ui/react';
import { Clock, Home, Share } from 'lucide-react';
import { useParams } from 'react-router-dom';
import { useGetPublicHuntDetailsQuery } from '../../graphql/generated';
import { useNavigate } from 'react-router-dom';
import { useDisclosure } from '@chakra-ui/react';
import ShareModal from '../../components/share-tour-modal';
import PoweredByFooter from "../../components/shared/powered-by-footer";

const CampusExplorerPage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { huntId } = useParams<{ huntId: string }>();
  const { data, loading, error } = useGetPublicHuntDetailsQuery({
    variables: { huntId: huntId || '' },
    skip: !huntId,
  });

  if (loading) return <Spinner />;
  if (error) return <Text>Error loading hunt details</Text>;

  const hunt = data?.publicHunt;

  const shareLink = `${window.location.origin}/participant/${huntId}/join`;
  const bgImage = "url('/images/pit.jpg')";

  return (
    <Box 
    bgImage={bgImage}
    bgSize="cover"
    bgPosition="center"
    bgRepeat="no-repeat"
    minHeight="100vh" 
    display="flex" 
    alignItems="center" 
    justifyContent="center" 
    p={4}>
      <Box 
        bg="white" 
        borderRadius="xl" 
        maxWidth="md" 
        width="100%" 
        overflow="hidden"
        border="2px solid #a65800"
      >
        <VStack spacing={6} align="center" justify="center" p={8}>
          <Icon as={Clock} w={12} h={12} color="orange.500" />
          <Heading size="lg" color="black" textAlign="center">
            Whoops! {hunt?.title} hasn't started yet!
          </Heading>
          <Text color="black" textAlign="center">
            {hunt?.description || 'The challenge is coming soon. Be ready to embark on an exciting journey!'}
          </Text>
          {hunt?.allowInvites && (
            <Text color="black" textAlign="center">
              We'll let you know when it starts! While you wait, why not invite your friends to join the adventure?
            </Text>
          )}
          <Box display="flex" justifyContent="center" width="100%">
            {hunt?.allowInvites && (
              <Button
                leftIcon={<Share />}
                colorScheme="orange"
                variant="outline"
                mr={4}
                flex={1}
                maxWidth="200px"
                onClick={onOpen}
              >
                Share
              </Button>
            )}
            <Button
              leftIcon={<Home />}
              colorScheme="orange"
              variant="solid"
              onClick={() => navigate('/')}
              flex={1}
              maxWidth="200px"
            >
              Home
            </Button>
          </Box>
        </VStack>
      </Box>
      <ShareModal isOpen={isOpen} onClose={onClose} shareLink={shareLink} />
      <PoweredByFooter />
    </Box>
  );
};

export default CampusExplorerPage;
