import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { ApolloProvider } from '@apollo/client';
import { appTheme } from './theme';
import { client as apolloClient } from './apollo-client';
import { AuthProvider } from './context/auth';
import App from "./app";

const root = ReactDOM.createRoot(document.getElementById('root')!);
root.render(
    <React.StrictMode>
        <ApolloProvider client={apolloClient}>
            <ChakraProvider theme={appTheme}>
                <BrowserRouter>
                    <AuthProvider>
                        <App />
                    </AuthProvider>
                </BrowserRouter>
            </ChakraProvider>
        </ApolloProvider>
    </React.StrictMode>
);
