import { VStack, Link, HStack, Icon, Text, Button } from "@chakra-ui/react";
import { Navigation, Compass } from "lucide-react";
import type { Coordinates } from "../../../helpers/map-util";

type NavigationButtonsProps = {
  mapUrl: string;
  userLocation: Coordinates;
  isLoadingLocation: boolean;
  onFetchLocation: () => void;
  isDisabled?: boolean;
};

export const NavigationButtons = ({
  mapUrl,
  userLocation,
  isLoadingLocation,
  onFetchLocation,
  isDisabled
}: NavigationButtonsProps) => (
  <VStack spacing={3} mt={4}>
    <Link
      href={mapUrl}
      isExternal
      _hover={{ textDecoration: "none" }}
      width="100%"
      {...(isDisabled && {
        opacity: 0.5,
        cursor: "not-allowed"
      })}
    >
      <HStack 
        spacing={3} 
        justify="center" 
        bg="blue.500"
        color="white"
        p={3}
        borderRadius="lg"
        boxShadow="md"
        transition="all 0.2s"
        _hover={{ 
          bg: "blue.600",
          transform: "translateY(-2px)",
          boxShadow: "lg"
        }}
      >
        <Icon as={Navigation} w={5} h={5} />
        <Text fontSize={["sm", "md"]} fontWeight="medium">
          {userLocation ? "Navigate from Current Location" : "View Full Tour Route"}
        </Text>
      </HStack>
    </Link>
    
    {!userLocation && !isLoadingLocation && (
      <Button
        leftIcon={<Icon as={Compass} w={5} h={5} />}
        variant="outline"
        colorScheme="blue"
        size={["sm", "md"]}
        onClick={onFetchLocation}
        width="100%"
        p={3}
        borderRadius="lg"
        {...(isDisabled && {
          opacity: 0.5,
          cursor: "not-allowed"
        })}
        transition="all 0.2s"
        _hover={{
          bg: "blue.50",
          transform: "translateY(-2px)",
          boxShadow: "md"
        }}
      >
        Use My Location
      </Button>
    )}
  </VStack>
); 