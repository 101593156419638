import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Text,
  VStack,
  useToast,
  FormErrorMessage,
  Spinner,
  Alert,
  AlertIcon,
  ChakraProvider,
  extendTheme,
  Divider,
  Image,
  Icon,
} from '@chakra-ui/react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import { useJoinHuntMutation, useGetPublicHuntDetailsQuery } from '../../graphql/generated';
import { motion } from 'framer-motion';
import { isDarkColor } from '../../helpers/color-util';
import { useLoading } from '../../context/loading';
import { LoadingProvider } from '../../context/loading';
import PoweredByFooter from "../../components/shared/powered-by-footer";
import { isString } from 'lodash';
import { Home, CheckCircleIcon as CheckCircle } from "lucide-react";
import { useAuth } from '../../context/auth';


const theme = extendTheme({
  fonts: {
    heading: "'Bebas Neue', sans-serif",
    body: "'Montserrat', sans-serif",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
        lineHeight: 'tall',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 700,
      },
    },
  },
});

const MotionBox = motion.create(Box as any);

const JoinHunt = () => {
  const { huntId } = useParams<{ huntId: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [fullName, setFullName] = useState('');
  const [huntName, setHuntName] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);
  const toast = useToast();
  const [touched, setTouched] = useState({
    email: false,
    fullName: false,
  });
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [inviteId, setInviteId] = useState<string | null>(null);
  const [isSwitching, setIsSwitching] = useState(false);
  const [hasExistingParticipation, setHasExistingParticipation] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [joinHunt, { loading: isSubmitting }] = useJoinHuntMutation();

  const { data: huntData, loading: isLoading, error: huntError } = useGetPublicHuntDetailsQuery({
    variables: { huntId: huntId || '' },
    skip: !huntId,
  });

  const { isLoading: contextLoading, setIsLoading } = useLoading();

  const { user } = useAuth();

  useEffect(() => {
    if (huntData?.publicHunt) {
      setHuntName(huntData.publicHunt.title);
    }
  }, [huntData]);

  useEffect(() => {
    if (huntError) {
      console.error('Error fetching hunt details:', huntError);
      setError('Whoops! Looks like this tour is unavailable at this time. Please contact the organizer for assistance.');
    }
  }, [huntError]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    const emailParam = searchParams.get('email');
    if (emailParam) {
      setEmail(emailParam);
      setIsValidEmail(validateEmail(emailParam));
      setTouched(prev => ({ ...prev, email: true }));
    }

    const inviteParam = searchParams.get('invite');
    if (inviteParam) {
      setInviteId(inviteParam);
    }

    const nameParam = searchParams.get('name');
    if (nameParam) {
      setFullName(decodeURIComponent(nameParam));
    }

    const switchParam = searchParams.get('switch');
    if (switchParam === 'true') {
      setIsSwitching(true);
    }

    if (emailParam || inviteParam || nameParam) {
      const newUrl = window.location.pathname;
      window.history.replaceState({}, '', newUrl);
    }
  }, [location.search]);

  useEffect(() => {
    setIsLoading(isLoading);
  }, [isLoading, setIsLoading]);

  useEffect(() => {
    const participant = localStorage.getItem("participantId");
    const participantId = participant ? localStorage.getItem(`foodTourGameState_${huntId}_${participant}`) : null;
    setHasExistingParticipation(!!participantId);
    setIsLoggedIn(!!user?.id);
  }, [huntId, user?.id]);

  const handleBlur = (field: string) => {
    setTouched(prev => ({ ...prev, [field]: true }));
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setTouched({ email: true, fullName: true });

    if (!email) {
      setError('Please enter your email');
      return;
    }

    setError('');
    setIsLoading(true);

    try {
      const response = await joinHunt({
        variables: {
          huntId: huntId || '',
          input: {
            email,
            fullName: fullName || 'Anonymous User',
          },
        },
      });

      if (response.data?.joinHunt) {
        setSuccess(true);
        localStorage.setItem('participantId', response.data.joinHunt.id);

        toast({
          render: () => (
            <Box
              color="white"
              p={3}
              bg="rgba(0, 0, 0, 0.8)"
              borderRadius="lg"
              display="flex"
              alignItems="center"
              gap={3}
            >
              <Icon as={CheckCircle} color="green.300" />
              <Text fontWeight="medium">Successfully joined: {huntName}!</Text>
            </Box>
          ),
          duration: 3000,
          position: "top",
        });

        if (huntData?.publicHunt?.startDate && new Date(huntData.publicHunt.startDate) <= new Date()) {
          navigate(`/participant/${huntId}/play-food-tour-game`);
        } else {
          navigate(`/participant/${huntId}/not-started`);
        }
      }
    } catch (error) {
      const errorMessages: Record<string, string> = {
        "hunt is not active": "This tour has expired. Please contact the organizer for assistance.",
        "participant already exists": "You've already joined this tour. Please proceed to the game.",
        "you are not invited to this hunt": "This is a private tour. You haven't been invited to join. Please contact the organizer for assistance.",
        "participant is blocked": "You've been blocked from participating in this tour. Please contact the organizer for further information or assistance.",
        "invalid invite": "The invitation link is invalid or has expired. Please contact the organizer for a new invitation.",
      }
      console.error('Error joining tour:', error);
      if (error instanceof Error) {
        const errorMessage = errorMessages[error.message.toLowerCase()] || `Unable to join the tour. Please try again. ${error.message}`;
        setError(errorMessage);
      } else {
        setError(`Unable to join the tour. Please try again.`);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const validateEmail = (email: string): boolean => {
    if (!isString(email)) return false;

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    setIsValidEmail(validateEmail(newEmail));
  };

  const bgImage = huntData?.publicHunt?.image ? `data:image/jpeg;base64,${huntData.publicHunt.image}` : huntData?.publicHunt?.color ? huntData.publicHunt.color : "linear-gradient(to bottom right, orange.500, pink.300)";

  const WelcomeMessage = () => {
    if (!inviteId || !email) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Alert
          status="info"
          borderRadius="xl"
          bg="blue.50"
          color="blue.700"
          mb={4}
        >
          <AlertIcon />
          <Text fontSize="sm">
            Welcome! You've been invited to join this food tour.
          </Text>
        </Alert>
      </motion.div>
    );
  };

  const SwitchingMessage = () => {
    if (!isSwitching) return null;

    return (
      <motion.div
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        <Alert
          status="info"
          borderRadius="xl"
          bg="blue.50"
          color="blue.700"
          mb={4}
        >
          <AlertIcon />
          <Text fontSize="sm">
            Switching to a different player. Enter your email to continue.
          </Text>
        </Alert>
      </motion.div>
    );
  };

  const renderContent = () => {
    if (huntError) {
      return (
        <Alert
          status="error"
          borderRadius="xl"
          fontSize={{ base: "sm", md: "md" }}
          bg="red.50"
          color="red.600"
        >
          <AlertIcon />
          Whoops! Looks like this tour is unavailable at this time. Please contact the organizer for assistance.
        </Alert>
      );
    }

    return (
      <>
        <Text
          fontSize={{ base: "sm", md: "md" }}
          color="gray.600"
          fontWeight="medium"
          maxWidth="80%"
        >
          Complete all locations to finish and vote! Tour details will be emailed after joining.
        </Text>
        {error && (
          <Alert
            status="error"
            borderRadius="xl"
            fontSize={{ base: "sm", md: "md" }}
            bg="red.50"
            color="red.600"
          >
            <AlertIcon />
            {error}
          </Alert>
        )}
        {!success && (
          <form onSubmit={handleSubmit} style={{ width: '100%' }}>
            <VStack spacing={6}>
              <FormControl isRequired isInvalid={touched.email && !email}>
                <FormLabel
                  fontSize={{ base: "sm", md: "md" }}
                  color="gray.700"
                  fontWeight="medium"
                >
                  Email
                </FormLabel>
                <Input
                  type="email"
                  value={email}
                  onChange={handleEmailChange}
                  onBlur={() => handleBlur('email')}
                  placeholder="your@email.com"
                  size="lg"
                  borderRadius="lg"
                  borderWidth="2px"
                  _focus={{
                    borderColor: huntData?.publicHunt?.color || "orange.500",
                    boxShadow: "none"
                  }}
                />
                {touched.email && !email && (
                  <FormErrorMessage fontSize="sm">
                    Email is required
                  </FormErrorMessage>
                )}
              </FormControl>
              {isValidEmail && (
                <motion.div
                  initial={{ opacity: 0, y: -10 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3 }}
                  style={{ width: "100%" }}
                >
                  <FormControl>
                    <FormLabel
                      fontSize={{ base: "sm", md: "md" }}
                      color="gray.700"
                      fontWeight="medium"
                    >
                      Your Name (Optional)
                    </FormLabel>
                    <Input
                      type="text"
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      placeholder="John Doe"
                      size="lg"
                      borderRadius="lg"
                      borderWidth="2px"
                      _focus={{
                        borderColor: huntData?.publicHunt?.color || "orange.500",
                        boxShadow: "none"
                      }}
                    />
                  </FormControl>
                </motion.div>
              )}
              <WelcomeMessage />
              <SwitchingMessage />
              <VStack width="full" spacing={3} mt={2}>
                {isValidEmail ? (
                  <>
                    <Button
                      type="submit"
                      style={{
                        backgroundColor: huntData?.publicHunt?.color || "orange.500",
                        color: huntData?.publicHunt?.color && isDarkColor(huntData.publicHunt.color) ? "white" : "black"
                      }}
                      size="lg"
                      width="full"
                      height="56px"
                      fontSize="md"
                      fontWeight="600"
                      borderRadius="lg"
                      _hover={{
                        opacity: 0.9,
                        transform: "translateY(-1px)",
                        transition: "all 0.2s"
                      }}
                      isLoading={isSubmitting}
                      loadingText="Joining..."
                    >
                      Join Tour
                    </Button>

                    <Button
                      type="submit"
                      variant="ghost"
                      size="md"
                      width="full"
                      color="gray.500"
                      fontWeight="medium"
                      _hover={{ bg: "gray.50" }}
                      onClick={(e) => {
                        e.preventDefault();
                        handleSubmit(e);
                        setFullName("");
                      }}
                    >
                      Continue without name
                    </Button>
                  </>
                ) : (
                  <Button
                    type="button"
                    bg="gray.100"
                    color="gray.400"
                    size="lg"
                    width="full"
                    height="56px"
                    fontSize="md"
                    fontWeight="600"
                    borderRadius="lg"
                    cursor="not-allowed"
                    _hover={{ bg: "gray.100" }}
                  >
                    Enter email to continue
                  </Button>
                )}
              </VStack>
            </VStack>
          </form>
        )}
      </>
    );
  };

  if (contextLoading) {
    return (
      <Flex minHeight="100vh" align="center" justify="center" bgGradient={bgImage}>
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bg="rgba(0, 0, 0, 0.4)"
        />
        <Spinner size="xl" color="white" zIndex={1} />
      </Flex>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <Box
        minHeight="100vh"
        width="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        bgGradient={bgImage}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        position="relative"
        p={{ base: 4, md: 8 }}
        style={{ backgroundColor: huntData?.publicHunt?.color || "white" }}
      >
        <Box
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          bg="linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))"
        />

        <Flex

          position="absolute"
          top={4}
          right={4}
          zIndex={2}
          gap={2}
        >
          {isLoggedIn && (
            <Icon
              as={Home}
              size={24}
              color="white"
              cursor="pointer"
              onClick={() => navigate(`/home/tours/${huntId}`)}
              _hover={{ color: "gray.300" }}
            />
          )}

          {hasExistingParticipation && (
            <Button
              leftIcon={<Icon as={Home} />}
              bg="whiteAlpha.900"
              color="gray.700"
              size="md"
              _hover={{ bg: "whiteAlpha.800" }}
              onClick={() => navigate(`/participant/${huntId}/play-food-tour-game`)}
            >
              Return to Game
            </Button>
          )}
        </Flex>

        <MotionBox
          bg="white"
          borderRadius="2xl"
          maxWidth={{ base: "95%", sm: "90%", md: "450px" }}
          width="100%"
          mx="auto"
          my={{ base: 4, md: 0 }}
          overflow="hidden"
          boxShadow="2xl"
          position="relative"
          zIndex={1}
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          border="3px solid"
          style={{ borderColor: huntData?.publicHunt?.color || "orange.500" }}
        >
          <VStack spacing={8} p={{ base: 6, md: 10 }}>
            {huntData?.publicHunt?.logo && (
              <Box width="100%" textAlign="center">
                <Image
                  src={`data:image/jpeg;base64,${huntData.publicHunt.logo}`}
                  alt="Tour Logo"
                  maxWidth={{ base: "250px", md: "300px" }}
                  maxHeight={{ base: "125px", md: "150px" }}
                  objectFit="contain"
                  mx="auto"
                />
                <Divider
                  borderColor={huntData?.publicHunt?.color || "orange.500"}
                  borderWidth="1.5px"
                  mt={2}
                  width="75%"
                  mx="auto"
                />
              </Box>
            )}
            <VStack spacing={3} textAlign="center">
              <Heading
                fontSize={{ base: "2xl", md: "3xl" }}
                color="gray.800"
                letterSpacing="tight"
                lineHeight="shorter"
              >
                {huntName}
              </Heading>
              {renderContent()}
            </VStack>
          </VStack>
        </MotionBox>
        <PoweredByFooter />
      </Box>
    </ChakraProvider>
  );
};

const JoinHuntWithProvider = () => (
  <LoadingProvider>
    <JoinHunt />
  </LoadingProvider>
);

export default JoinHuntWithProvider;
