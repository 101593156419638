import React, { useEffect, useRef, useState } from 'react';
import { VStack, FormControl, FormLabel, Input, NumberInput, NumberInputField, NumberInputStepper, NumberIncrementStepper, NumberDecrementStepper, Button, FormErrorMessage, Heading, Textarea, HStack, Box, Text, useColorModeValue } from '@chakra-ui/react';
import ReactSelect from 'react-select';
import { CreateChallengeInput} from '../graphql/generated';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import '@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css';

const useCurrentLocation = () => {
  const [location, setLocation] = useState<[number, number] | null>(null);

  useEffect(() => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation([position.coords.longitude, position.coords.latitude]);
        },
        (error) => {
          console.error("Error getting location:", error);
          // Default to US center if location access denied
          setLocation([-98.5795, 39.8283]);
        }
      );
    }
  }, []);

  return location;
};

const AddressSearchBox = ({ value, onChange, accessToken }: any) => {
  const geocoderContainerRef = useRef<HTMLDivElement>(null);
  const [geocoder, setGeocoder] = useState<any>(null);
  const currentLocation = useCurrentLocation();

  useEffect(() => {
    if (!geocoderContainerRef.current || !currentLocation) return;

    const geocoderInstance = new MapboxGeocoder({
      accessToken,
      types: 'address,poi',
      placeholder: 'Search for an address',
      countries: 'us',
      proximity: {
        longitude: currentLocation[0],
        latitude: currentLocation[1]
      },
      bbox: [-125.0, 24.396308, -66.93457, 49.384358], // Continental US bounding box
      language: 'en',
    });

    geocoderInstance.addTo(geocoderContainerRef.current);

    geocoderInstance.on('result', (e: any) => {
      onChange(e.result.place_name);
    });

    geocoderInstance.on('clear', () => {
      onChange('');
    });

    setGeocoder(geocoderInstance);

    // Updated CSS for better mobile responsiveness
    const style = document.createElement('style');
    style.textContent = `
      .mapboxgl-ctrl-geocoder {
        box-shadow: none !important;
        max-width: 100% !important;
        width: 100% !important;
        min-width: unset !important;
      }
      .mapboxgl-ctrl-geocoder--input {
        box-shadow: none !important;
        border: 1px solid var(--chakra-colors-gray-300);
        border-radius: var(--chakra-radii-md);
        width: 100% !important;
        height: 40px !important;
        padding: 6px 45px !important;
      }
      .mapboxgl-ctrl-geocoder .suggestions {
        position: absolute;
        width: 100% !important;
        top: 100% !important;
        left: 0;
        right: 0;
        margin-top: 5px;
        max-height: 200px;
        overflow-y: auto;
        border-radius: var(--chakra-radii-md);
        border: 1px solid var(--chakra-colors-gray-300);
        box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
        z-index: 1000;
      }
      .mapboxgl-ctrl-geocoder .suggestions > li {
        padding: 8px 12px;
        cursor: pointer;
      }
      .mapboxgl-ctrl-geocoder .suggestions > li:hover {
        background-color: var(--chakra-colors-gray-100);
      }
      .mapboxgl-ctrl-geocoder--icon {
        top: 55% !important;
        transform: translateY(-50%) !important;
      }
      .mapboxgl-ctrl-geocoder--icon-search {
        left: 10px !important;
      }
      .mapboxgl-ctrl-geocoder--button {
        position: absolute;
        top: 75% !important;
        transform: translateY(-50%) !important;
        right: 10px !important;
        background: none;
        border: none;
        cursor: pointer;
      }
      .mapboxgl-ctrl-geocoder--icon-close {
        margin: 0 !important;
      }
      @media (max-width: 640px) {
        .mapboxgl-ctrl-geocoder {
          font-size: 15px;
        }
        .mapboxgl-ctrl-geocoder--input {
          height: 36px !important;
          padding: 5px 35px !important;
        }
        .mapboxgl-ctrl-geocoder .suggestions {
          max-height: 150px;
        }
        .mapboxgl-ctrl-geocoder .suggestions > li {
          padding: 10px 12px;
          font-size: 14px;
        }
      }
    `;
    document.head.appendChild(style);

    return () => {
      geocoderInstance.onRemove();
      document.head.removeChild(style);
    };
  }, [accessToken, onChange, currentLocation]);

  useEffect(() => {
    if (geocoder && value) {
      geocoder.setInput(value);
    }
  }, [geocoder, value]);

  return (
    <Box width="100%">
      <div ref={geocoderContainerRef} style={{ width: "100%" }} />
    </Box>
  );
};

const ChallengeForm = ({ 
  challenge, 
  handleInputChange, 
  errors, 
  handleQuizChange, 
  addQuizQuestion, 
  removeQuizQuestion, 
  addBasicGame, 
  removeBasicGame, 
  handleBasicGameChange,
  setIsFormValid // New prop
}: any) => {
  const validateForm = () => {
    const isValid = 
      !!challenge.title &&
      !!challenge.description &&
      challenge.points > 0 &&
      !!challenge.address &&
      (challenge.gameType === 'QUIZ' 
        ? challenge.quiz && challenge.quiz.length > 0 && challenge.quiz.every((q: any) => 
            q.question && q.answers.length > 0 && q.correctAnswers.length > 0
          )
        : challenge.gameType === 'BASIC'
          ? challenge.basic && challenge.basic.length > 0 && challenge.basic.every((b: any) =>
              b.text && b.content && b.expectedContent
            )
          : true
      );
    
    setIsFormValid(isValid);
  };

  useEffect(() => {
    validateForm();
  }, [challenge]);

  return (
    <Box
      borderWidth={1}
      borderRadius="lg"
      p={6}
      bg={useColorModeValue('white', 'gray.800')}
      shadow="md"
      maxHeight="calc(100vh - 200px)"
      overflowY="auto"
    >
      <VStack spacing={6} align="stretch">
        <FormControl isInvalid={!!errors.title} isRequired>
          <FormLabel fontWeight="bold">Name</FormLabel>
          <Input
            value={challenge.title}
            onChange={(e) => handleInputChange('title', e.target.value)}
            placeholder="Enter location name"
            bg="white"
            borderColor="gray.300"
          />
          {errors.title && <FormErrorMessage>{errors.title}</FormErrorMessage>}
        </FormControl>

        <FormControl isInvalid={!!errors.description} isRequired>
          <FormLabel fontWeight="bold" >Description</FormLabel>
          <Textarea
            value={challenge.description}
            onChange={(e) => handleInputChange('description', e.target.value)}
            placeholder="Enter location description"
            bg="white"
            borderColor="gray.300"
            minHeight="100px"
          />
          {errors.description && <FormErrorMessage>{errors.description}</FormErrorMessage>}
        </FormControl>

        <HStack spacing={6}>
          <FormControl isInvalid={!!errors.points} isRequired flex={1}>
            <FormLabel fontWeight="bold">Points</FormLabel>
            <NumberInput
              value={challenge.points}
              min={1}
              onChange={(valueString) => handleInputChange('points', parseInt(valueString))}
              bg="white"
              borderColor="gray.300"
            >
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
            {errors.points && <FormErrorMessage>{errors.points}</FormErrorMessage>}
          </FormControl>

          <FormControl isInvalid={!!errors.address} isRequired flex={1}>
            <FormLabel fontWeight="bold">Address</FormLabel>
            <AddressSearchBox
              accessToken={process.env.REACT_APP_MAPBOX_TOKEN}
              value={challenge.address}
              onChange={(result: string) => handleInputChange('address', result)}
            />
            {errors.address && <FormErrorMessage>{errors.address}</FormErrorMessage>}
          </FormControl>
        </HStack>

        {challenge.gameType === 'QUIZ' && challenge.quiz && (
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Quiz Questions</Heading>
            {challenge.quiz.map((q: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Question {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeQuizQuestion(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>

                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`quiz${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Question</FormLabel>
                    <Input
                      value={q.question}
                      onChange={(e) => handleQuizChange(index, 'question', e.target.value)}
                      placeholder="Enter question"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`quiz${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`quiz${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <Text fontWeight="bold">Answers</Text>
                  {q.answers.map((answer: any, answerIndex: number) => (
                    <FormControl key={answerIndex} isRequired>
                      <Input
                        value={answer}
                        onChange={(e) =>
                          handleQuizChange(
                            index,
                            'answers',
                            q.answers.map((a: any, i: number) => (i === answerIndex ? e.target.value : a))
                          )
                        }
                        placeholder={`Answer ${answerIndex + 1}`}
                        bg="white"
                        borderColor="gray.300"
                      />
                    </FormControl>
                  ))}

                  <FormControl isRequired>
                    <FormLabel fontWeight="bold">Correct Answers</FormLabel>
                    <ReactSelect
                      isMulti
                      options={q.answers.map((answer: any, i: number) => ({ value: answer, label: answer }))}
                      value={q.correctAnswers.map((answer: any) => ({ value: answer, label: answer }))}
                      onChange={(selectedOptions) => {
                        const selectedAnswers = selectedOptions.map(option => option.value);
                        handleQuizChange(index, 'correctAnswers', selectedAnswers);
                      }}
                      placeholder="Select correct answers"
                      styles={{
                        control: (base) => ({
                          ...base,
                          backgroundColor: 'white',
                          borderColor: 'var(--chakra-colors-gray-300)',
                        }),
                      }}
                    />
                  </FormControl>

                  <FormControl>
                    <FormLabel fontWeight="bold">Hint (optional)</FormLabel>
                    <Input
                      value={q.hint || ''}
                      onChange={(e) => handleQuizChange(index, 'hint', e.target.value)}
                      placeholder="Enter hint"
                      bg="white"
                      borderColor="gray.300"
                    />
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addQuizQuestion}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Question
            </Button>
          </VStack>
        )}
        {challenge.gameType === 'BASIC' && challenge.basic && (
          // text, content, expectedContent
          <VStack spacing={6} align="stretch">
            <Heading size="md" fontWeight="bold">Basic Game</Heading>
            {challenge.basic.map((b: any, index: number) => (
              <Box key={index} borderWidth="1px" borderRadius="md" p={4} bg="white">
                <HStack justifyContent="space-between" mb={4}>
                  <Heading size="sm" fontWeight="bold">Basic Game {index + 1}</Heading>
                  <Button size="sm" colorScheme="red" onClick={() => removeBasicGame(index)}>
                    <DeleteIcon />
                  </Button>
                </HStack>
                <VStack spacing={4} align="stretch">
                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Text</FormLabel>
                    <Input
                      value={b.text}
                      onChange={(e) => handleBasicGameChange(index, 'text', e.target.value)}
                      placeholder="Enter text"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Content</FormLabel>
                    <Input
                      value={b.content}
                      onChange={(e) => handleBasicGameChange(index, 'content', e.target.value)}
                      placeholder="Enter content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>

                  <FormControl isInvalid={!!errors[`basic${index}` as keyof CreateChallengeInput]} isRequired>
                    <FormLabel fontWeight="bold">Expected Content</FormLabel>
                    <Input
                      value={b.expectedContent}
                      onChange={(e) => handleBasicGameChange(index, 'expectedContent', e.target.value)}
                      placeholder="Enter expected content"
                      bg="white"
                      borderColor="gray.300"
                    />
                    {errors[`basic${index}` as keyof CreateChallengeInput] && (
                      <FormErrorMessage>{errors[`basic${index}` as keyof CreateChallengeInput]}</FormErrorMessage>
                    )}
                  </FormControl>
                </VStack>
              </Box>
            ))}
            <Button
              onClick={addBasicGame}
              leftIcon={<AddIcon />}
              variant="outline"
              colorScheme="blue"
            >
              Add Basic Game
            </Button>
          </VStack>
        )}
      </VStack>
    </Box>
  );
};

export default ChallengeForm;
