import { Box, VStack, Skeleton, Flex, SimpleGrid } from "@chakra-ui/react";

const TourDetailSkeleton = () => {
  return (
    <Box p={{ base: 2, sm: 4, md: 8 }}>
      <VStack spacing={4} align="stretch">
        {/* Header Section */}
        <Flex 
          direction={{ base: "column", md: "row" }}
          justifyContent="space-between" 
          alignItems={{ base: "start", md: "center" }}
          gap={4}
        >
          <VStack align="start" spacing={1}>
            <Skeleton height="36px" width="300px" />
            <Skeleton height="20px" width="200px" />
          </VStack>

          <VStack spacing={2} w={{ base: "full", md: "auto" }}>
            <Skeleton height="40px" width={{ base: "full", md: "120px" }} />
            <SimpleGrid columns={{ base: 2, sm: 3 }} spacing={2} w="full">
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} height="40px" />
              ))}
            </SimpleGrid>
          </VStack>
        </Flex>

        {/* Stats Card */}
        <Box 
          borderWidth="1px" 
          borderRadius="md" 
          p={4} 
          borderColor="gray.200" 
          bg="white"
          boxShadow="sm"
          _hover={{ boxShadow: "md" }}
        >
          <SimpleGrid columns={{ base: 2, md: 3 }} spacing={4}>
            {[1, 2, 3].map((i) => (
              <VStack key={i} align="start">
                <Skeleton height="16px" width="80px" />
                <Skeleton height="24px" width="60px" />
              </VStack>
            ))}
          </SimpleGrid>
        </Box>

        {/* Tabs Section */}
        <Box>
          <Skeleton height="48px" mb={4} />
          <Box 
            borderWidth="1px" 
            borderRadius="md" 
            p={6} 
            borderColor="gray.200" 
            bg="white"
            boxShadow="sm"
            _hover={{ boxShadow: "md" }}
          >
            <VStack spacing={4} align="stretch">
              <Skeleton height="32px" width="200px" />
              <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                {[1, 2, 3, 4].map((i) => (
                  <Box 
                    key={i} 
                    p={4} 
                    borderWidth="1px" 
                    borderRadius="md" 
                    borderColor="gray.200"
                    bg="white"
                    boxShadow="sm"
                    _hover={{ boxShadow: "md" }}
                  >
                    <VStack spacing={3}>
                      <Skeleton height="24px" width="80%" />
                      <Skeleton height="60px" />
                      <Flex gap={2}>
                        <Skeleton height="20px" width="60px" borderRadius="full" />
                        <Skeleton height="20px" width="60px" borderRadius="full" />
                      </Flex>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            </VStack>
          </Box>
        </Box>
      </VStack>
    </Box>
  );
};

export default TourDetailSkeleton; 