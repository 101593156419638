import React from "react";
import {
  Flex,
  Heading,
  Stack,
  useBreakpointValue,
  BoxProps,
  useColorModeValue,
} from "@chakra-ui/react";

type CardHeaderProps = BoxProps & {
  title: string;
  children?: React.ReactNode;
};

const CardHeader: React.FC<CardHeaderProps> = ({ title, children, ...props }) => {
  const isMobile = useBreakpointValue({ base: true, md: false });
  const borderColor = useColorModeValue('purple.200', 'purple.700');
  const headerColor = useColorModeValue('purple.500', 'purple.100');

  return (
    <Flex
      direction={isMobile ? "column" : "row"}
      justifyContent="space-between"
      alignItems={isMobile ? "stretch" : "center"}
      p={4}
      borderBottomWidth="1px"
      borderColor={borderColor}
      {...props}
    >
      <Heading
        size="md"
        color={headerColor}
        mb={isMobile ? 4 : 0}
        textAlign={isMobile ? "center" : "left"}
      >
        {title}
      </Heading>
      {children && (
        <Stack
          direction={isMobile ? "column" : "row"}
          spacing={2}
          width={isMobile ? "100%" : "auto"}
        >
          {children}
        </Stack>
      )}
    </Flex>
  );
};

export default CardHeader; 