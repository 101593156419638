import { useState } from 'react';
import {
    Box, Button, FormControl, Input, useToast, Heading, Text,
    Flex, Stack, InputGroup, InputLeftElement, Link,
    useColorModeValue, VStack, Icon, Image, Divider,
    IconButton
} from '@chakra-ui/react';
import { FaEnvelope, FaLock, FaArrowLeft } from 'react-icons/fa';
import { useAuth } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../graphql/generated';
import { z } from 'zod';
import { CloseIcon } from '@chakra-ui/icons';
import Banner from '../../components/shared/banner';

const LoginSchema = z.object({
    emailOrUsername: z.string().min(1, "Email or username is required"),
});

const VerificationBanner = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex="banner"
            transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
            transition="transform 0.3s ease-in-out"
        >
            <Flex
                bg="purple.500"
                color="white"
                p={4}
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                borderBottomRadius="md"
                position="relative"
            >
                <Box textAlign="center">
                    <Text fontWeight="bold">Verification Email Sent</Text>
                    <Text fontSize="sm">Please check your email to complete the login process.</Text>
                </Box>
                <IconButton
                    icon={<CloseIcon />}
                    aria-label="Close notification"
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={onClose}
                    _hover={{ bg: "purple.600" }}
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>
        </Box>
    );
};

const ErrorBanner = ({ isVisible, onClose, message }: { isVisible: boolean; onClose: () => void; message: string }) => {
    return (
        <Box
            position="fixed"
            top="0"
            left="0"
            right="0"
            zIndex="banner"
            transform={isVisible ? "translateY(0)" : "translateY(-100%)"}
            transition="transform 0.3s ease-in-out"
        >
            <Flex
                bg="red.500"
                color="white"
                p={4}
                alignItems="center"
                justifyContent="center"
                boxShadow="md"
                borderBottomRadius="md"
                position="relative"
            >
                <Box textAlign="center">
                    <Text fontWeight="bold">Error</Text>
                    <Text fontSize="sm">{message}</Text>
                </Box>
                <IconButton
                    icon={<CloseIcon />}
                    aria-label="Close notification"
                    size="sm"
                    variant="ghost"
                    color="white"
                    onClick={onClose}
                    _hover={{ bg: "red.600" }}
                    position="absolute"
                    right={2}
                    top="50%"
                    transform="translateY(-50%)"
                />
            </Flex>
        </Box>
    );
};

const LoginPage = () => {
    const navigate = useNavigate()
    const { setUser } = useAuth();
    const [loginFormData, setLoginFormData] = useState({
        emailOrUsername: '',
    });
    const [error, setError] = useState<string>('');
    const [showVerificationBanner, setShowVerificationBanner] = useState(false);
    const [showErrorBanner, setShowErrorBanner] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [showSuccessBanner, setShowSuccessBanner] = useState(false);

    const handleShowBanner = (type: 'verification' | 'error') => {
        if (type === 'verification') {
            setShowVerificationBanner(true);
            setTimeout(() => setShowVerificationBanner(false), 3000);
        } else {
            setShowErrorBanner(true);
            setTimeout(() => setShowErrorBanner(false), 3000);
        }
    };

    const [loginMutation] = useLoginMutation({
        onCompleted: (data) => {
            console.log('Login mutation completed:', data);
            const userInfo = {
                email: loginFormData.emailOrUsername.includes('@') ? loginFormData.emailOrUsername : undefined,
                username: loginFormData.emailOrUsername.includes('@') ? undefined : loginFormData.emailOrUsername,
                isAuth: false,
            };
            console.log('Setting user after login:', userInfo);
            setUser(userInfo);
            handleShowBanner('verification');
            setShowSuccessBanner(true);
        },
        onError: (error) => {
            console.error('Login mutation error:', error);
            setErrorMessage(error.message);
            handleShowBanner('error');
        },
    });

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLoginFormData({ ...loginFormData, [e.target.name]: e.target.value });
        setError('');
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        try {
            LoginSchema.parse(loginFormData);
            setError('');
            console.log('Submitting login mutation with:', loginFormData);
            loginMutation({
                variables: {
                    input: {
                        email: loginFormData.emailOrUsername.includes('@') ? loginFormData.emailOrUsername : undefined,
                        username: loginFormData.emailOrUsername.includes('@') ? undefined : loginFormData.emailOrUsername,
                    }
                }
            });
        } catch (error) {
            if (error instanceof z.ZodError) {
                setError(error.errors[0].message);
            }
        }
    };

    const bgColor = useColorModeValue("white", "gray.900");
    const cardBg = useColorModeValue("white", "gray.800");
    const textColor = useColorModeValue("purple.800", "purple.200");
    const secondaryTextColor = useColorModeValue("purple.600", "purple.300");

    return (
        <>
            <VerificationBanner isVisible={showVerificationBanner} onClose={() => {}} />
            <ErrorBanner isVisible={showErrorBanner} onClose={() => {}} message={errorMessage} />
            <Flex height="100vh" bg={bgColor} overflow="hidden">
                {/* Left side - Image */}
                <Box width={{ base: "0%", md: "60%" }} position="relative" overflow="hidden">
                    <Image
                        src="/images/group-fun.jpg"
                        alt="Login background"
                        objectFit="cover"
                        w="100%"
                        mr={2}
                        filter="blur(2px)"
                        h="100%"
                    />
                    <Box
                        position="absolute"
                        top="0"
                        left="0"
                        right="0"
                        bottom="0"
                        bg="blackAlpha.500"
                        zIndex="1"
                    />
                    <VStack
                        position="absolute"
                        bottom="8"
                        left="8"
                        zIndex="2"
                        align="flex-start"
                        spacing="3"
                    >
                        <Heading
                            color="white"
                            fontSize={{ base: "3xl", md: "4xl" }}
                            textShadow="0 2px 4px rgba(0,0,0,0.4)"
                        >
                            Culinary Castle
                        </Heading>
                        <Text
                            color="white"
                            fontSize={{ base: "lg", md: "xl" }}
                            textShadow="0 1px 2px rgba(0,0,0,0.4)"
                        >
                            Culinary adventures made by you
                        </Text>
                    </VStack>
                </Box>

                {/* Right side - Login Form */}
                <Flex
                    width={{ base: "100%", md: "40%" }}
                    direction="column"
                    p={{ base: 4, md: 8 }}
                    overflowY="auto"
                    bg={cardBg}
                    borderRadius="lg"
                    boxShadow="md"
                >
                    <Button
                        leftIcon={<FaArrowLeft />}
                        variant="ghost"
                        alignSelf="flex-start"
                        onClick={() => navigate("/")}
                        mb={4}
                        color={textColor}
                        _hover={{ bg: "purple.50", color: "purple.600" }}
                    >
                        Back to Home
                    </Button>

                    <VStack spacing={6} align="stretch" width="100%" maxW="400px" mx="auto" mt={14}>
                        <VStack spacing={2} align="center">
                            <Image
                                src="/images/logo.png"
                                alt="Culinary Castle Logo"
                                w="80px"
                                h="auto"
                                mb={4}
                            />
                            <Heading color={textColor} fontSize={{ base: "2xl", md: "3xl" }} fontWeight="bold">
                                Welcome Back
                            </Heading>
                            <Text color={secondaryTextColor} fontSize="sm" textAlign="center">
                                Enter your email or username to continue
                            </Text>
                        </VStack>

                        <form onSubmit={handleSubmit}>
                            <VStack spacing={4}>
                                <FormControl isInvalid={!!error}>
                                    <InputGroup size="md">
                                        <InputLeftElement pointerEvents="none">
                                            <Icon as={FaEnvelope} color="purple.400" />
                                        </InputLeftElement>
                                        <Input
                                            type="text"
                                            name="emailOrUsername"
                                            value={loginFormData.emailOrUsername}
                                            onChange={handleChange}
                                            placeholder="Email or Username"
                                            bg={cardBg}
                                            borderColor="purple.200"
                                            _hover={{ borderColor: "purple.300" }}
                                            _focus={{ borderColor: "purple.500", boxShadow: "0 0 0 1px purple.500" }}
                                        />
                                    </InputGroup>
                                    {error && <Text color="red.500" fontSize="xs" mt={1}>{error}</Text>}
                                </FormControl>
                                <Button
                                    type="submit"
                                    width="full"
                                    colorScheme="purple"
                                    size="md"
                                    fontSize="sm"
                                    fontWeight="bold"
                                    bg="purple.500"
                                    _hover={{ bg: "purple.600", transform: "translateY(-2px)", boxShadow: "md" }}
                                    transition="all 0.2s"
                                >
                                    Continue
                                </Button>
                            </VStack>
                        </form>

                        <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                            By continuing, you agree to our Terms of Service and Privacy Policy.
                        </Text>

                        <Flex align="center" my={2}>
                            <Divider flex={1} borderColor="purple.200" />
                            <Text px={3} color={secondaryTextColor} fontSize="xs">OR</Text>
                            <Divider flex={1} borderColor="purple.200" />
                        </Flex>

                        <Text fontSize="sm" color={secondaryTextColor} textAlign="center">
                            Don't have an account?{" "}
                            <Link
                                color="purple.500"
                                fontWeight="bold"
                                onClick={() => navigate("/signup")}
                                _hover={{ textDecoration: "underline" }}
                            >
                                Sign Up
                            </Link>
                        </Text>

                        <VStack spacing={2} mt={2}>
                            <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                                🔒 Your data is protected with industry-standard encryption. 🔒
                            </Text>
                            <Text fontSize="xs" color={secondaryTextColor} textAlign="center">
                                We never share your information without your permission.
                            </Text>
                        </VStack>
                    </VStack>
                </Flex>
            </Flex>
        </>
    );
};

export default LoginPage;
