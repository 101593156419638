import React, { useEffect, useRef, useState } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Box,
  Text,
  Flex,
  Icon,
  useColorModeValue,
  VStack,
  Spinner,
} from "@chakra-ui/react";
import { MapPin } from "lucide-react";
import env from "../env";

const MAPBOX_ACCESS_TOKEN = env.REACT_APP_MAPBOX_TOKEN;

type Challenge = {
  id: string;
  title: string;
  address: string;
}

type ChallengeWithCoordinates = Challenge & {
  longitude: number;
  latitude: number;
}

type MapModalProps = {
  isOpen: boolean;
  onClose: () => void;
  challenges: Challenge[];
}

const MapModal: React.FC<MapModalProps> = ({ isOpen, onClose, challenges }) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const markersRef = useRef<mapboxgl.Marker[]>([]);
  const [challengesWithCoordinates, setChallengesWithCoordinates] = useState<ChallengeWithCoordinates[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const mapBgColor = useColorModeValue('gray.50', 'gray.700');

  useEffect(() => {
    if (!mapboxgl.accessToken) {
      mapboxgl.accessToken = MAPBOX_ACCESS_TOKEN;
    }
  }, []);

  useEffect(() => {
    const fetchCoordinates = async () => {
      if (!isOpen || challenges.length === 0) return;
      
      setIsLoading(true);
      setError(null);
      
      try {
        const challengesWithCoords = await Promise.all(
          challenges.map(async (challenge) => {
            const response = await fetch(
              `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
                challenge.address
              )}.json?access_token=${MAPBOX_ACCESS_TOKEN}`
            );
            const data = await response.json();
            if (data.features && data.features.length > 0) {
              const [feature] = data.features;
              const [longitude, latitude] = feature.center;
              return { ...challenge, longitude, latitude };
            }
            throw new Error(`Could not find coordinates for address: ${challenge.address}`);
          })
        );
        setChallengesWithCoordinates(challengesWithCoords);
      } catch (err) {
        setError("Failed to load challenge locations. Please try again.");
        console.error("Error fetching coordinates:", err);
      } finally {
        setIsLoading(false);
      }
    };

    fetchCoordinates();
  }, [isOpen, challenges]);

  useEffect(() => {
    if (!isOpen || !mapContainer.current || !challengesWithCoordinates.length) return;

    if (!map.current) {
      const [firstLocation] = challengesWithCoordinates;
      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: "mapbox://styles/mapbox/streets-v11",
        center: [firstLocation.longitude, firstLocation.latitude],
        zoom: 10,
      });
    }

    markersRef.current.forEach(marker => marker.remove());
    markersRef.current = [];

    const bounds = new mapboxgl.LngLatBounds();
    
    challengesWithCoordinates.forEach((challenge) => {
      const popup = new mapboxgl.Popup({ offset: 25 }).setHTML(`
        <div style="padding: 8px;">
          <h3 style="font-weight: bold; margin-bottom: 4px;">${challenge.title}</h3>
          <p style="color: #666;">${challenge.address}</p>
        </div>
      `);

      const marker = new mapboxgl.Marker({ color: '#805AD5' })
        .setLngLat([challenge.longitude, challenge.latitude])
        .setPopup(popup)
        .addTo(map.current!);
      
      markersRef.current.push(marker);
      bounds.extend([challenge.longitude, challenge.latitude]);
    });

    map.current.fitBounds(bounds, { padding: 50 });

    return () => {
      if (!isOpen) {
        markersRef.current.forEach(marker => marker.remove());
        markersRef.current = [];
        if (map.current) {
          map.current.remove();
          map.current = null;
        }
      }
    };
  }, [isOpen, challengesWithCoordinates]);

  return (
    <Modal 
      isOpen={isOpen} 
      onClose={onClose}
      size="5xl"
      isCentered
      motionPreset="slideInBottom"
    >
      <ModalOverlay 
        bg="blackAlpha.300"
        backdropFilter="blur(10px)"
      />
      <ModalContent
        bg={bgColor}
        borderRadius="xl"
        boxShadow="xl"
        maxWidth="90vw"
      >
        <ModalHeader 
          borderBottom="1px" 
          borderColor={borderColor}
          py={4}
          px={6}
        >
          <Flex align="center">
            <Icon as={MapPin} mr={3} color="purple.500" />
            <Text fontSize="xl">Tour Locations</Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton size="lg" />
        
        <ModalBody py={6} px={6}>
          <VStack spacing={4} align="stretch">
            {isLoading ? (
              <Flex justify="center" align="center" height="60vh">
                <Spinner size="xl" color="purple.500" thickness="4px" />
              </Flex>
            ) : error ? (
              <Flex 
                direction="column" 
                align="center" 
                justify="center" 
                height="60vh"
                bg={mapBgColor}
                borderRadius="lg"
                p={8}
              >
                <Icon as={MapPin} boxSize={12} color="red.500" mb={4} />
                <Text color="red.500" fontSize="lg" textAlign="center">
                  {error}
                </Text>
              </Flex>
            ) : (
              <Box 
                ref={mapContainer} 
                height="60vh" 
                width="100%" 
                borderRadius="lg"
                overflow="hidden"
                borderWidth="1px"
                borderColor={borderColor}
              />
            )}
          </VStack>
        </ModalBody>

        <ModalFooter 
          borderTop="1px" 
          borderColor={borderColor}
          py={4}
          px={6}
        >
          <Button
            colorScheme="purple"
            size="lg"
            onClick={onClose}
          >
            Close Map
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default MapModal;
