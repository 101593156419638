import React, { useState } from "react";
import { 
  Box, 
  Button, 
  Text, 
  VStack,
  HStack,
  Progress,
  Flex,
  useToken,
  Collapse,
  Heading
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import { isDarkColor } from "../../helpers/color-util";

const MotionBox = motion(Box as any);

type VoteResult = {
  id: string;
  title: string;
  address?: string;
  voteCount: number;
  percentage: number;
};

type VotingStateProps = {
  hunt: {
    id: string;
    title: string;
    color: string;
    challenges: any[];
  };
  challenges: any[];
  votedRestaurant: string;
  votedRestaurantName: string;
  onVoteChange: (restaurantId: string, restaurantName: string, participantId: string) => void;
  onSubmitVote: () => void;
  voteResults?: VoteResult[];
  hasVoted?: boolean;
};

export const VotingState: React.FC<VotingStateProps> = ({
  hunt,
  challenges,
  votedRestaurant,
  votedRestaurantName,
  onVoteChange,
  onSubmitVote,
  voteResults = [],
  hasVoted = false
}) => {
  const participantId = localStorage.getItem("participantId") || "";
  const [orange500] = useToken("colors", ["orange.500"]);
  const [showResults, setShowResults] = useState(false);

  if (!challenges?.length) {
    return (
      <Box p={6}>
        <Text>No locations available for voting.</Text>
      </Box>
    );
  }

  const handleVoteSubmit = async () => {
    await onSubmitVote();
    setShowResults(true);
  };

  const renderVoteOption = (challenge: any) => {
    const isSelected = votedRestaurant === challenge.id;
    const textColor = hunt.color ? isDarkColor(hunt.color) ? "black" : "black" : "inherit";

    return (
      <MotionBox
        key={challenge.id}
        as="button"
        width="100%"
        onClick={() => onVoteChange(challenge.id, challenge.title, participantId)}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 0.98 }}
        transition={{ duration: 0.2 }}
      >
        <Box
          p={4}
          borderRadius="lg"
          border="2px solid"
          borderColor={isSelected ? hunt.color || orange500 : "gray.200"}
          bg={isSelected ? `${hunt.color}20` : "white"}
          _hover={{
            borderColor: hunt.color || orange500,
            bg: `${hunt.color}10`
          }}
        >
          <VStack align="start" spacing={1}>
            <Text 
              fontWeight="bold"
              color={textColor}
              fontSize="lg"
            >
              {challenge.title}
            </Text>
            {challenge.address && (
              <Text fontSize="sm" color="gray.600">
                {challenge.address}
              </Text>
            )}
          </VStack>
        </Box>
      </MotionBox>
    );
  };

  const renderVoteResults = (challenge: any) => {
    const result = voteResults.find(r => r.id === challenge.id) || {
      percentage: 0,
      voteCount: 0
    };

    const isVotedOption = votedRestaurant === challenge.id;

    return (
      <Box
        key={challenge.id}
        width="100%"
        p={4}
        borderRadius="lg"
        border="2px solid"
        borderColor={isVotedOption ? hunt.color || orange500 : "gray.200"}
        bg={isVotedOption ? `${hunt.color}20` : "white"}
        position="relative"
      >
        <VStack align="stretch" spacing={2}>
          <Flex justify="space-between" align="center">
            <HStack spacing={2}>
              <Text fontWeight="bold" fontSize="lg">
                {challenge.title}
              </Text>
              {isVotedOption && (
                <Text fontSize="sm" color={hunt.color || orange500}>
                  (Your Vote)
                </Text>
              )}
            </HStack>
            <Text fontWeight="bold" color={hunt.color || orange500}>
              {result.percentage}%
            </Text>
          </Flex>
          <Progress 
            value={result.percentage} 
            colorScheme="orange"
            bg="gray.100"
            borderRadius="full"
            height="8px"
          />
          <HStack justify="space-between">
            {challenge.address && (
              <Text fontSize="sm" color="gray.600">
                {challenge.address}
              </Text>
            )}
            <Text fontSize="sm" color="gray.600">
              {result.voteCount} vote{result.voteCount !== 1 ? "s" : ""}
            </Text>
          </HStack>
        </VStack>
      </Box>
    );
  };

  return (
    <Box p={6}>
      <VStack spacing={6} align="stretch">
        <VStack spacing={2} align="center" mb={4}>
          <Heading 
            fontSize={{ base: "2xl", md: "3xl" }}
            bg={hunt.color}
            bgClip="text"
            textAlign="center"
            fontWeight="extrabold"
          >
            {showResults ? "The Results Are In!" : "Time To Cast Your Vote!"}
          </Heading>
          <Text
            fontSize={{ base: "md", md: "lg" }}
            color="gray.600"
            textAlign="center"
            fontWeight="medium"
          >
            {showResults 
              ? "Check out how the community voted 📊" 
              : "Which amazing spot won your foodie heart today? 🌟"}
          </Text>
        </VStack>
        
        <Collapse in={!showResults} animateOpacity>
          <VStack spacing={4} align="stretch">
            {challenges.map(renderVoteOption)}
          </VStack>
        </Collapse>

        <Collapse in={showResults} animateOpacity>
          <VStack spacing={4} align="stretch">
            {challenges.map(renderVoteResults)}
          </VStack>
        </Collapse>

        {!showResults && (
          <Button
            mt={4}
            style={{ backgroundColor: hunt.color }}
            color={hunt.color ? isDarkColor(hunt.color) ? "white" : "black" : "inherit"}
            isDisabled={!votedRestaurant}
            onClick={handleVoteSubmit}
            width="100%"
            size="lg"
          >
            Submit Vote
          </Button>
        )}
      </VStack>
    </Box>
  );
}; 