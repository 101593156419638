import { Box, Skeleton, SkeletonText, VStack, Flex } from "@chakra-ui/react";

type CardSkeletonProps = {
  rows?: number;
  showHeader?: boolean;
};

const CardSkeleton = ({ rows = 3, showHeader = true }: CardSkeletonProps) => {
  return (
    <Box 
      borderWidth="1px" 
      borderRadius="lg" 
      overflow="hidden" 
      bg="white" 
      borderColor="gray.200"
      boxShadow="sm"
      _hover={{ boxShadow: "md" }}
    >
      {showHeader && (
        <Flex justify="space-between" p={4} borderBottomWidth="1px" borderColor="gray.200">
          <Skeleton height="24px" width="120px" />
          <Skeleton height="32px" width="100px" />
        </Flex>
      )}
      <VStack spacing={0} align="stretch" divider={<Box borderBottomWidth="1px" borderColor="gray.200" />}>
        {Array(rows).fill(0).map((_, i) => (
          <Box key={i} p={4}>
            <Flex align="center" gap={4}>
              <Skeleton height="40px" width="40px" borderRadius="full" />
              <Box flex="1">
                <SkeletonText noOfLines={2} spacing={2} />
              </Box>
              <Skeleton height="32px" width="32px" />
            </Flex>
          </Box>
        ))}
      </VStack>
    </Box>
  );
};

export default CardSkeleton; 