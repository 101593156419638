import React from "react";
import { Box, Flex, VStack, Text, CloseButton, useDisclosure } from "@chakra-ui/react";
import { CheckCircleIcon } from "@chakra-ui/icons";

type SuccessBannerProps = {
  title: string;
  message: string;
  onClose: () => void;
};

const SuccessBanner: React.FC<SuccessBannerProps> = ({ title, message, onClose }) => {
  const { isOpen, onClose: closeBanner } = useDisclosure({ defaultIsOpen: true });

  const handleClose = () => {
    closeBanner();
    onClose();
  };

  if (!isOpen) return null;

  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      right={0}
      bg="green.500"
      p={4}
      zIndex="banner"
    >
      <Flex justify="space-between" align="center" maxW="container.xl" mx="auto">
        <Flex align="center">
          <CheckCircleIcon color="white" mr={3} boxSize={6} />
          <VStack align="start" spacing={0}>
            <Text color="white" fontWeight="bold" fontSize="lg">
              {title}
            </Text>
            <Text color="white" fontWeight="medium">
              {message}
            </Text>
          </VStack>
        </Flex>
        <CloseButton color="white" onClick={handleClose} />
      </Flex>
    </Box>
  );
};

export default SuccessBanner;
