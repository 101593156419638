import React, { useEffect, useCallback } from 'react';
import {
    Flex,
    Card,
    CardBody,
    Text,
    Box,
    Skeleton,
    SimpleGrid,
    AlertIcon,
    Alert,
} from "@chakra-ui/react";
import { useGetHuntsWithParticipantsQuery, GetHuntsWithParticipantsDocument } from '../graphql/generated';
import { CompassIcon as Compass, UsersIcon as Users, BarChartIcon as BarChart } from "lucide-react";
import { useLoading } from "../context/loading";
import { useApolloClient } from '@apollo/client';

interface QuickStatsProps {
  userType: string;
}

const QuickStats: React.FC<QuickStatsProps> = ({ userType }) => {
  const { isLoading } = useLoading();
  const client = useApolloClient();
  const { data, loading, error } = useGetHuntsWithParticipantsQuery({
    pollInterval: 1_000,
    fetchPolicy: 'network-only'
  });

  const updateHuntData = useCallback((cachedData: any, hunts: any[]) => {
    if (cachedData) {
      return hunts.map((hunt: any) => ({
        ...hunt,
        participantCount: hunt.participants.length,
        progress: hunt.progress || 0,
      }));
    }
    return [];
  }, []);

  useEffect(() => {
    if (data) {
      const hunts = data.huntParticipantsAndChallenges;
      const cachedData = client.readQuery({
        query: GetHuntsWithParticipantsDocument,
      });
      const updatedHunts = updateHuntData(cachedData, hunts);
      client.writeQuery({
        query: GetHuntsWithParticipantsDocument,
        data: { huntParticipantsAndChallenges: updatedHunts },
      });
    }
  }, [updateHuntData, data, client]);

  if (isLoading || loading) {
    return (
      <SimpleGrid columns={{ base: 1, md: 3 }} spacing={4}>
        {[1, 2, 3].map((i) => (
          <Card key={i} w="full">
            <CardBody>
              <Flex justify="space-between" align="center">
                <Box flex="1">
                  <Skeleton height="20px" width="100px" mb={2} />
                  <Skeleton height="24px" width="60px" />
                </Box>
                <Skeleton height="24px" width="24px" />
              </Flex>
            </CardBody>
          </Card>
        ))}
      </SimpleGrid>
    );
  }

  if (error) return (
    <Alert
      status="error" 
      variant="subtle"
      borderRadius="lg"
      p={4}
    >
      <AlertIcon />
      <Text>Unable to load tour statistics. Please try again later.</Text>
    </Alert>
  );

  const activeTours = data?.huntParticipantsAndChallenges?.length || 0;
  const totalParticipants = data?.huntParticipantsAndChallenges?.reduce((acc, curr) => acc + curr.participants.length, 0) || 0;
  const completionRate = data?.huntParticipantsAndChallenges && data?.huntParticipantsAndChallenges?.length  > 0
    ? Math.round(data?.huntParticipantsAndChallenges.reduce((acc, curr) => acc + (curr.progress || 0), 0) / data.huntParticipantsAndChallenges.length)
    : 0;

  const maxTours = userType === 'FREE' ? 1 : userType === 'BASIC' ? 10 : 20;

  return (
    <Flex direction={{ base: "column", md: "row" }} justify="space-between" gap={4}>
      <Card
        w="full"
        bg="linear-gradient(135deg, #FF6B6B 0%, #FF8E53 100%)"
        color="white"
        boxShadow="lg"
      >
        <CardBody>
          <Flex justify="space-between" align="center">
            <Box>
              <Text fontSize="sm" fontWeight="medium">
                Active Tours
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                {userType === 'FREE' ? activeTours === 0 ? 0 : 1 : activeTours}/{maxTours}
              </Text>
              <Text fontSize="xs">{maxTours - (userType === 'FREE' ? activeTours === 0 ? 0 : 1 : activeTours)} slots remaining</Text>
            </Box>
            <Compass size={24} />
          </Flex>
        </CardBody>
      </Card>
        <Card
          w="full"
          bg="linear-gradient(135deg, #4E54C8 0%, #8F94FB 100%)"
          color="white"
          boxShadow="lg"
        >
          <CardBody>
            <Flex justify="space-between" align="center">
              <Box>
                <Text fontSize="sm" fontWeight="medium">
                  Total Participants
                </Text>
                <Text fontSize="2xl" fontWeight="bold">
                  {totalParticipants}
                </Text>
                <Text fontSize="xs">Across all tours</Text>
              </Box>
              <Users size={24} />
            </Flex>
          </CardBody>
        </Card>
      <Card
        w="full"
        bg="linear-gradient(135deg, #11998e 0%, #38ef7d 100%)"
        color="white"
        boxShadow="lg"
      >
        <CardBody>
          <Flex justify="space-between" align="center">
            <Box>
              <Text fontSize="sm" fontWeight="medium">
                Completion Rate
              </Text>
              <Text fontSize="2xl" fontWeight="bold">
                {completionRate}%
              </Text>
              <Text fontSize="xs">Average across tours</Text>
            </Box>
            <BarChart size={24} />
          </Flex>
        </CardBody>
      </Card>
    </Flex>
  );
};

export default QuickStats;
