import React, { useState, useCallback, useEffect } from "react";
import { Box, Button, ChakraProvider, extendTheme, Flex, Text, Heading, Spinner, Icon, VStack, IconButton } from "@chakra-ui/react";
import { ArrowLeft, ArrowRight, Camera, AlertCircle, User, Home, CheckCircle, MessageCircle } from "lucide-react";
import { motion } from "framer-motion";
import { useNavigate, useParams, useLocation, Link } from "react-router-dom";
import { useGetPublicHuntDetailsQuery, useGetParticipantStatusQuery, useRecordResponseMutation, useRecordVoteMutation } from "../../graphql/generated";
import ShareModal from "../../components/share-tour-modal";
import { IntroState } from "../../components/game-states/intro-state";
import { LocationState } from "../../components/game-states/location-state";
import { VotingState } from "../../components/game-states/voting-state";
import { FinishedState } from "../../components/game-states/finished-state";
import { useToast } from "@chakra-ui/react";
import PoweredByFooter from "../../components/shared/powered-by-footer";
import { useAuth } from "../../context/auth";
import { isDarkColor } from "../../helpers/color-util";

type SavedGameState = {
  participantId: string;
  gameState: "intro" | "playing" | "voting" | "finished";
  currentLocation: number;
  votedRestaurant: string;
  votedRestaurantName: string;
};
type PhotoItem = {
  preview: string;
  mediaId: string;
};
type VoteResult = {
  id: string;
  title: string;
  address?: string;
  voteCount: number;
  percentage: number;
};
const theme = extendTheme({
  fonts: {
    heading: "'Bebas Neue', sans-serif",
    body: "'Montserrat', sans-serif",
  },
  components: {
    Heading: {
      baseStyle: {
        fontWeight: 700,
      },
    },
    Text: {
      baseStyle: {
        fontSize: 'md',
        lineHeight: 'tall',
      },
    },
    Button: {
      baseStyle: {
        fontWeight: 700,
      },
    },
  },
});

const MotionBox = motion.create(Box as any);

const getStorageKey = (huntId: string, participantId: string) => `foodTourGameState_${huntId}_${participantId}`;

const optimizeImage = async (file: File): Promise<Blob> => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    const objectUrl = URL.createObjectURL(file);

    img.onload = () => {
      URL.revokeObjectURL(objectUrl); // Clean up the object URL

      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      if (!ctx) {
        reject(new Error('Failed to get canvas context'));
        return;
      }

      const maxSize = 800;
      let width = img.width;
      let height = img.height;

      if (width > height) {
        if (width > maxSize) {
          height *= maxSize / width;
          width = maxSize;
        }
      } else if (height > maxSize) {
        width *= maxSize / height;
        height = maxSize;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.imageSmoothingEnabled = true;
      ctx.imageSmoothingQuality = 'high';

      ctx.drawImage(img, 0, 0, width, height);

      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve(blob);
          } else {
            reject(new Error('Failed to convert image'));
          }
        },
        'image/jpeg',
        0.7
      );
    };

    img.onerror = () => {
      URL.revokeObjectURL(objectUrl);
      reject(new Error('Failed to load image'));
    };

    img.src = objectUrl;
  });
};

const PlayFoodTourGame: React.FC = () => {
  const navigate = useNavigate();
  const { huntId } = useParams();
  const location = useLocation();
  const toast = useToast();

  const [participantId, setParticipantId] = useState<string | null>(null);
  const [gameState, setGameState] = useState<"intro" | "playing" | "voting" | "finished">("intro");
  const [currentLocation, setCurrentLocation] = useState(0);
  const [photos, setPhotos] = useState<Record<number, PhotoItem[]>>({});
  const [votedRestaurant, setVotedRestaurant] = useState<string>("");
  const [votedRestaurantName, setVotedRestaurantName] = useState<string>("");
  const [isShareModalOpen, setIsShareModalOpen] = useState(false);
  const [userLocation, setUserLocation] = useState<{ latitude: number; longitude: number } | null>(null);
  const [challengeCoordinates, setChallengeCoordinates] = useState<{ lat: number; lon: number }[]>([]);
  const [sortedChallenges, setSortedChallenges] = useState<any[]>([]);
  const [bgImage, setBgImage] = useState<string | undefined>(undefined);
  const [voteResults, setVoteResults] = useState<VoteResult[]>([]);

  const { loading, error, data } = useGetPublicHuntDetailsQuery({
    variables: { huntId: `${huntId}` },
    skip: !huntId,
    pollInterval: 2_500,
  });

  const { data: participantStatusData } = useGetParticipantStatusQuery({
    variables: {
      huntId: `${huntId}`,
      participantId: `${participantId}`,
    },
    skip: !huntId || !participantId,
    pollInterval: 2_500,
  });

  const hunt = data?.publicHunt;

  const { user } = useAuth();

  useEffect(() => {
    if (!(hunt?.challenges && hunt.challenges.length > 0 && huntId && participantId)) return;

    const savedState = localStorage.getItem(getStorageKey(huntId, participantId));
    if (savedState) {
      const parsedState: SavedGameState = JSON.parse(savedState);
      setGameState(parsedState.gameState);
      setCurrentLocation(parsedState.currentLocation);
      setVotedRestaurant(parsedState.votedRestaurant);
      setVotedRestaurantName(parsedState.votedRestaurantName);
    } else {
      setGameState("intro");
      setCurrentLocation(0);
    }
    if (hunt.image) {
      setBgImage(`data:image/jpeg;base64,${hunt.image}`);
    } else if (hunt.color) {
      setBgImage(hunt.color);
    } else {
      setBgImage("linear-gradient(to bottom right, orange.500, pink.300)");
    }
  }, [hunt, huntId, participantId, hunt?.image, hunt?.color]);

  useEffect(() => {
    const handleInvite = () => {
      const searchParams = new URLSearchParams(location.search);
      const inviteId = searchParams.get("invite");
      const storedParticipantId = localStorage.getItem("participantId");

      // Case 1: Has invite in URL - redirect to join page with invite parameter
      if (inviteId) {
        navigate(`/participant/${huntId}/join?invite=${inviteId}`);
        return;
      }

      // Case 2: Has stored participantId but no invite
      if (storedParticipantId) {
        setParticipantId(storedParticipantId);
        return;
      }

      // Case 3: No invite and no stored participantId
      navigate(`/participant/${huntId}/join`);
    };

    handleInvite();
  }, [location, navigate, huntId]);

  useEffect(() => {
    if (hunt?.challenges) {
      setChallengeCoordinates(
        hunt.challenges.map((challenge) => ({
          lat: userLocation?.latitude || 0,
          lon: userLocation?.longitude || 0,
        }))
      );
    }
  }, [hunt?.challenges, userLocation]);

  useEffect(() => {
    getUserLocation();
  }, []);

  useEffect(() => {
    const sortChallengesByDistance = () => {
      if (userLocation && hunt?.challenges && challengeCoordinates.length > 0) {
        const sortedChallenges = [...hunt.challenges].sort((a, b) => {
          const indexA = hunt?.challenges?.indexOf(a);
          const indexB = hunt?.challenges?.indexOf(b);
          const coordsA = challengeCoordinates[indexA || 0];
          const coordsB = challengeCoordinates[indexB || 0];

          if (!coordsA || !coordsB) return 0;

          const distanceA = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            coordsA.lat,
            coordsA.lon
          );
          const distanceB = calculateDistance(
            userLocation.latitude,
            userLocation.longitude,
            coordsB.lat,
            coordsB.lon
          );

          return distanceA - distanceB;
        });

        setSortedChallenges(sortedChallenges as any);
      }
    };

    sortChallengesByDistance();
  }, [userLocation, hunt?.challenges, challengeCoordinates]);

  useEffect(() => {
    if (!participantStatusData?.participantStatus?.isBlocked) {
      return;
    }
    localStorage.removeItem(getStorageKey(`${huntId}`, `${participantId}`));
    setGameState("intro");
    setCurrentLocation(0);
    setPhotos({});
    setVotedRestaurant("");
  }, [participantStatusData?.participantStatus?.isBlocked, huntId]);

  const startGame = useCallback(() => {
    if (!hunt || !participantId) return;

    setGameState("playing");
    setPhotos(sortedChallenges.map(() => []));
    const initialState: SavedGameState = {
      participantId,
      gameState: "playing",
      currentLocation: 0,
      votedRestaurant: "",
      votedRestaurantName: "",
    };
    localStorage.setItem(getStorageKey(`${huntId}`, `${participantId}`), JSON.stringify(initialState));
  }, [hunt, huntId, participantId, sortedChallenges]);

  const [recordResponse] = useRecordResponseMutation();
  const [recordVote] = useRecordVoteMutation();

  const handlePhotoUpload = useCallback(async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (!file) return;

    const loadingToast = toast({
      render: () => (
        <Box
          color="white"
          p={3}
          bg="rgba(0, 0, 0, 0.8)"
          borderRadius="lg"
          display="flex"
          alignItems="center"
          gap={3}
        >
          <Spinner size="sm" color="white" />
          <Text fontWeight="medium">Processing image...</Text>
        </Box>
      ),
      duration: null,
      position: "top",
    });

    try {
      const optimizedImage = await optimizeImage(file);
      const previewUrl = URL.createObjectURL(optimizedImage);

      setPhotos(prev => ({
        ...prev,
        [currentLocation]: [
          ...(prev[currentLocation] || []),
          { preview: previewUrl, mediaId: '' }
        ]
      }));

      const formData = new FormData();
      formData.append('operations', JSON.stringify({
        query: `
          mutation UploadMedia($files: [UploadMediaInput!]!) {
            uploadMedia(files: $files) {
              id
            }
          }
        `,
        variables: {
          files: [{
            docType: file.type,
            file: null
          }]
        }
      }));
      formData.append('map', JSON.stringify({ "0": ["variables.files.0.file"] }));
      formData.append('0', optimizedImage);

      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/graphql`, {
        method: 'POST',
        body: formData,
        credentials: 'include',
        headers: {
          'Apollo-Require-Preflight': 'true',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to upload image');
      }

      const data = await response.json();
      const mediaId = data.data.uploadMedia[0]?.id;

      setPhotos(prev => ({
        ...prev,
        [currentLocation]: prev[currentLocation].map((photo, idx) =>
          idx === prev[currentLocation].length - 1
            ? { ...photo, mediaId }
            : photo
        )
      }));

      toast.close(loadingToast);
      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg="rgba(0, 0, 0, 0.8)"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            gap={3}
          >
            <Icon as={Camera} color="green.300" />
            <Text fontWeight="medium">Photo uploaded successfully!</Text>
          </Box>
        ),
        duration: 2000,
        position: "top",
      });
    } catch (error) {
      console.error('Error uploading image:', error);
      toast.close(loadingToast);
      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg="rgba(255, 0, 0, 0.8)"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            gap={3}
          >
            <Icon as={AlertCircle} color="white" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="medium">Error uploading photo</Text>
              <Text fontSize="sm">Please try again</Text>
            </VStack>
          </Box>
        ),
        duration: 3000,
        position: "top",
      });
    } finally {
      if (event.target) {
        event.target.value = '';
      }
    }
  }, [currentLocation, toast]);

  const resetGameState = useCallback(() => {
    if (!participantId) return;
    localStorage.removeItem(getStorageKey(`${huntId}`, `${participantId}`));
    setGameState("intro");
    setCurrentLocation(0);
    setPhotos({});
    setVotedRestaurant("");
  }, [huntId, participantId]);

  const handleStartNewTour = useCallback(() => {
    resetGameState();
  }, [resetGameState]);

  const handleShare = useCallback(() => {
    setIsShareModalOpen(true);
  }, []);

  const closeShareModal = useCallback(() => {
    setIsShareModalOpen(false);
  }, []);

  const handleNext = useCallback(async () => {
    if (!hunt || !participantId) return;

    try {
      const currentPhotos = photos[currentLocation] || [];
      const mediaIds = currentPhotos.map(photo => photo.mediaId).filter(Boolean);

      await recordResponse({
        variables: {
          input: {
            participantId,
            challengeId: sortedChallenges[currentLocation].id,
            huntId: hunt.id,
            responses: mediaIds
          }
        }
      });

      const nextLocation = currentLocation + 1;
      const isLastLocation = currentLocation >= (hunt.challenges?.length || 0) - 1;

      const newGameState: SavedGameState = {
        participantId,
        gameState: isLastLocation ? "voting" : "playing",
        currentLocation: isLastLocation ? currentLocation : nextLocation,
        votedRestaurant: "",
        votedRestaurantName: "",
      };

      setGameState(newGameState.gameState);
      setCurrentLocation(newGameState.currentLocation);
      localStorage.setItem(getStorageKey(`${huntId}`, `${participantId}`), JSON.stringify(newGameState));

    } catch (error) {
      console.error('Error recording response:', error);
      if (error instanceof Error) {
        if (error.message.includes('Participant not found')) {
          localStorage.removeItem("participantId");
          localStorage.removeItem(getStorageKey(`${huntId}`, `${participantId}`));
          navigate(`/participant/${huntId}/join`);
        } else if (error.message.includes("Participant does not belong to this hunt")) {
          navigate(`/participant/${huntId}/join`);
        } else {
          toast({
            title: "Error saving progress",
            description: "Please try again",
            status: "error",
            duration: 3000,
          });
        }
      }
    }
  }, [currentLocation, hunt, huntId, navigate, participantId, photos, recordResponse, sortedChallenges, toast]);

  const handlePrevious = useCallback(() => {
    if (currentLocation <= 0 || !participantId) return;

    const prevLocation = currentLocation - 1;
    setCurrentLocation(prevLocation);
    const updatedState: SavedGameState = {
      participantId,
      gameState: "playing",
      currentLocation: prevLocation,
      votedRestaurant: "",
      votedRestaurantName: "",
    };
    localStorage.setItem(getStorageKey(`${huntId}`, `${participantId}`), JSON.stringify(updatedState));
  }, [currentLocation, huntId, participantId]);

  const handleVoteChange = useCallback((restaurantId: string, restaurantName: string, participantId: string) => {
    setVotedRestaurant(restaurantId);
    setVotedRestaurantName(restaurantName);

    const updatedState: SavedGameState = {
      participantId,
      gameState: "voting",
      currentLocation,
      votedRestaurant: restaurantId,
      votedRestaurantName: restaurantName,
    };
    localStorage.setItem(getStorageKey(`${huntId}`, participantId), JSON.stringify(updatedState));
  }, [currentLocation, huntId]);

  const handleVoteSubmit = useCallback(async () => {
    if (!votedRestaurant || !participantId) {
      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg="rgba(255, 0, 0, 0.8)"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            gap={3}
          >
            <Icon as={AlertCircle} color="white" />
            <Text fontWeight="medium">Please select a restaurant first</Text>
          </Box>
        ),
        duration: 3000,
        position: "top",
      });
      return;
    }

    try {
      const response = await recordVote({
        variables: {
          challengeId: votedRestaurant,
          participantId,
        }
      });

      if (response.data?.recordVote) {
        //setVoteResults(calculateVotePercentages(response.data.recordVote.votes));
      }

      setGameState("finished");
      const updatedState: SavedGameState = {
        participantId,
        gameState: "finished",
        currentLocation,
        votedRestaurant,
        votedRestaurantName,
      };
      localStorage.setItem(getStorageKey(`${huntId}`, `${participantId}`), JSON.stringify(updatedState));

      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg="rgba(0, 0, 0, 0.8)"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            gap={3}
          >
            <Icon as={CheckCircle} color="green.300" />
            <Text fontWeight="medium">Vote submitted successfully!</Text>
          </Box>
        ),
        duration: 2000,
        position: "top",
      });
    } catch (error) {
      console.error('Error submitting vote:', error);
      toast({
        render: () => (
          <Box
            color="white"
            p={3}
            bg="rgba(255, 0, 0, 0.8)"
            borderRadius="lg"
            display="flex"
            alignItems="center"
            gap={3}
          >
            <Icon as={AlertCircle} color="white" />
            <VStack align="start" spacing={0}>
              <Text fontWeight="medium">Error submitting vote</Text>
              <Text fontSize="sm">Please try again</Text>
            </VStack>
          </Box>
        ),
        duration: 3000,
        position: "top",
      });
    }
  }, [votedRestaurant, participantId, currentLocation, huntId, recordVote, toast, votedRestaurantName]);

  const calculateVotePercentages = useCallback((votes: any[]) => {
    const totalVotes = votes.reduce((sum, vote) => sum + vote.count, 0);
    
    return votes.map(vote => ({
      id: vote.challengeId,
      title: hunt?.challenges?.find(c => c?.id === vote.challengeId)?.title || "",
      address: hunt?.challenges?.find(c => c?.id === vote.challengeId)?.address,
      voteCount: vote.count,
      percentage: totalVotes > 0 ? Math.round((vote.count / totalVotes) * 100) : 0
    }));
  }, [hunt]);

  const renderContent = useCallback(() => {
    const motionProps = {
      initial: { opacity: 0, y: 10 },
      animate: { opacity: 1, y: 0 },
      transition: { duration: 0.2 },
      exit: { opacity: 0, y: 10 },
    };

    return (
      <MotionBox
        bg="white"
        borderRadius="md"
        maxWidth={{ base: "95vw", md: "900px" }}
        width="100%"
        overflow="hidden"
        boxShadow="xl"
        {...motionProps}
        border="2px solid"
        style={{ borderColor: hunt?.color || "orange.500" }}
      >
        {gameState === "intro" && (
          <IntroState
            loading={loading}
            error={error}
            hunt={hunt}
            closestChallenge={sortedChallenges[0]}
            onStart={startGame}
          />
        )}
        {gameState === "playing" && (
          <LocationState
            hunt={hunt}
            currentLocation={currentLocation}
            challenge={sortedChallenges[currentLocation]}
            nextChallenge={sortedChallenges[currentLocation + 1]}
            photos={photos as any}
            onPhotoUpload={handlePhotoUpload}
            totalLocations={sortedChallenges.length}
            headingSize={"lg"}
            textSize={"sm"}
            buttonSize={"md"}
          />
        )}
        {gameState === "voting" && hunt?.challenges && (
          <VotingState
            hunt={{ id: hunt.id, title: hunt.title, challenges: hunt.challenges, color: hunt.color || "orange.500" }}
            challenges={hunt.challenges}
            votedRestaurant={votedRestaurant}
            votedRestaurantName={votedRestaurantName}
            onVoteChange={handleVoteChange}
            onSubmitVote={handleVoteSubmit}
            voteResults={voteResults}
            hasVoted={gameState === "voting"}
          />
        )}
        {gameState === "finished" && (
          <FinishedState
            hunt={hunt as any}
            votedRestaurant={votedRestaurant}
            votedRestaurantName={votedRestaurantName}
            totalPhotos={Object.values(photos).flat().length}
            onStartNewTour={handleStartNewTour}
            onShare={handleShare}
          />
        )}
      </MotionBox>
    );
  }, [gameState, loading, error, hunt, sortedChallenges, votedRestaurant, handleVoteChange, currentLocation, votedRestaurantName, handleVoteSubmit, handleStartNewTour, handleShare, photos, startGame, handlePhotoUpload, voteResults, calculateVotePercentages]);

  const getUserLocation = () => {
    if ("geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude,
          });
        },
        (error) => {
          console.error("Error getting user location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const calculateDistance = (lat1: number, lon1: number, lat2: number, lon2: number) => {
    const R = 6371; // Radius of the Earth in km
    const dLat = (lat2 - lat1) * (Math.PI / 180);
    const dLon = (lon2 - lon1) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(lat1 * (Math.PI / 180)) * Math.cos(lat2 * (Math.PI / 180)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
  };

  const generateShortLink = (huntId: string, participantId: string | null) => {
    const participant = participantId || localStorage.getItem("participantId");
    return `${window.location.origin}/participant/${huntId}/join${participant ? `?by=${participant}` : ""}`;
  };

  const handleSwitchParticipant = () => {
    localStorage.removeItem("participantId");
    navigate(`/participant/${huntId}/join?switch=true`);
  };

  const handleChatClick = useCallback(() => {
    const code = new URLSearchParams(location.search).get("code") || "code";
    const participantId = localStorage.getItem("participantId") || "";
    if (participantId) {
      navigate(`/participant/${hunt?.id}/messages/${participantId}${code ? `?code=${code}` : ""}`);
    }
  }, [navigate, hunt?.id, participantId, location.search]);

  if (participantStatusData?.participantStatus?.isBlocked) {
    return (
      <ChakraProvider theme={theme}>
        <Box
          bgGradient="linear-gradient(to bottom right, orange.500, pink.300)"
          minHeight="100vh"
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center"
          p={4}
        >
          <MotionBox
            bg="white"
            borderRadius="xl"
            p={8}
            maxWidth="md"
            width="100%"
            textAlign="center"
            boxShadow="xl"
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
          >
            <Heading size="lg" color="red.500" mb={4}>
              Access Denied
            </Heading>

            <Text color="gray.600" mb={6}>
              You have been blocked from participating in this food tour.{" "}
              {hunt?.isPublic && !participantStatusData?.participantStatus?.isBlocked ? (
                <>
                  The tour is public, please join the tour by{" "}
                  <Link to={generateShortLink(hunt.id, null)} target="_blank" style={{ color: "blue.800", textDecoration: "underline" }}>
                    clicking here.
                  </Link>
                </>
              ) : participantStatusData?.participantStatus?.isBlocked ? (
                ""
              ) : (
                "The tour is private, so you must be invited to participate."
              )}
            </Text>
            <Button onClick={() => navigate("/")} colorScheme="red" variant="outline">
              Return Home
            </Button>
          </MotionBox>
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>

      <Box
        bgGradient="linear-gradient(to bottom right, orange.500, pink.300)"
        minHeight="100vh"
        display="flex"
        flexDirection="column"
        bgImage={bgImage}
        bgSize="cover"
        bgPosition="center"
        bgRepeat="no-repeat"
        alignItems="center"
        justifyContent="center"
        p={4}
        position="relative"
        top={0}
        right={0}
        bottom={0}
        left={0}
        bg="linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.6))"
        style={{ backgroundColor: hunt?.color || "white" }}
      >

        <Flex
          position="absolute"
          top={4}
          right={4}
          zIndex={2}
          gap={2}
        >

          {user?.isAuth && user?.id && (
            <>
              <Icon
                as={Home}
                size={24}
                color="white"
                cursor="pointer"
                onClick={() => navigate(`/home/tours/${huntId}`)}
                _hover={{ color: "gray.300" }}
              />
              <Icon
                as={User}
                size={24}
                color="white"
                cursor="pointer"
                onClick={handleSwitchParticipant}
                _hover={{ color: "gray.300" }}
              />
            </>
          )}
        </Flex>

        {renderContent()}
        {gameState === "playing" && (
          <MotionBox
            mt={4}
            display="flex"
            justifyContent="space-between"
            width="100%"
            maxWidth="md"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.2, delay: 0.1 }}
          >
            <Button
              leftIcon={<ArrowLeft />}
              onClick={handlePrevious}
              isDisabled={currentLocation === 0}
              colorScheme="blackAlpha"
              size="md"
            >
              Previous
            </Button>
            <Button
              rightIcon={<ArrowRight />}
              onClick={handleNext}
              colorScheme="blackAlpha"
              size="md"
            >
              {hunt && currentLocation === (hunt.challenges?.length || 0) - 1 ? "Finish" : "Next"}
            </Button>
          </MotionBox>
        )}
        <ShareModal
          isOpen={isShareModalOpen}
          onClose={closeShareModal}
          color={hunt?.color || "orange.500"}
          shareLink={generateShortLink(`${huntId}`, participantId)}
        />
        <IconButton
          aria-label="Chat with creator"
          icon={<Icon as={MessageCircle} />}
          position="fixed"
          bottom={4}
          right={4}
          size="lg"
          isRound
          style={{ backgroundColor: hunt?.color || "orange.500", color: isDarkColor(hunt?.color || "orange.500") ? "white" : "black" }}
          boxShadow="lg"
          onClick={handleChatClick}
          zIndex={1000}
          _hover={{
            transform: "scale(1.1)",
          }}
          transition="all 0.2s"
        />
        <PoweredByFooter />
      </Box>
    </ChakraProvider>
  );
};

export default PlayFoodTourGame;
