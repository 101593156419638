import { VStack, Image, Divider, Heading, Text } from "@chakra-ui/react";

type TourHeaderProps = {
  logo?: string;
  title: string;
  description: string;
  color: string;
};

export const TourHeader = ({ logo, title, description, color }: TourHeaderProps) => (
  <VStack spacing={4} width="100%" px={4}>
    {logo && (
      <>
        <Image
          src={`data:image/jpeg;base64,${logo}`}
          alt="Tour Logo"
          maxWidth={["200px", "250px"]}
          maxHeight="150px"
          objectFit="contain"
        />
        <Divider borderColor={color || "orange.500"} borderWidth="1.5px" width="85%" />
      </>
    )}
    <Heading 
      size={["md", "lg"]} 
      color="gray.800" 
      textAlign="center"
      px={2}
    >
      {title}
    </Heading>
    <Text 
      color="gray.600" 
      textAlign="center"
      fontSize={["sm", "md"]}
      maxWidth="600px"
      px={2}
    >
      {description}
    </Text>
  </VStack>
); 