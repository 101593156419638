import { useEffect } from "react"
import { useMessageSubscription } from "../../../graphql/generated"

type UseMessageUpdatesProps = {
  participantId: string
  onNewMessage: () => void
}

export const useMessageUpdates = ({ participantId, onNewMessage }: UseMessageUpdatesProps) => {
  const { data: subscriptionData } = useMessageSubscription()

  useEffect(() => {
    if (subscriptionData?.messageAdded) {
      onNewMessage()
    }
  }, [subscriptionData, onNewMessage])
} 