import React from "react"
import { Flex, Text, Divider, useColorModeValue } from "@chakra-ui/react"
import { format, isToday, isYesterday } from "date-fns"

type MessageDateDividerProps = {
  date: string
}

export const MessageDateDivider = ({ date }: MessageDateDividerProps) => {
  const dividerColor = useColorModeValue("gray.200", "gray.700")
  const textColor = useColorModeValue("gray.500", "gray.400")

  const formatDate = (dateString: string) => {
    const messageDate = new Date(dateString)
    
    if (isToday(messageDate)) {
      return "Today"
    }
    
    if (isYesterday(messageDate)) {
      return "Yesterday"
    }
    
    return format(messageDate, "MMMM d, yyyy")
  }

  return (
    <Flex align="center" w="100%" gap={4}>
      <Divider borderColor={dividerColor} />
      <Text
        flexShrink={0}
        fontSize="xs"
        fontWeight="medium"
        color={textColor}
        px={2}
      >
        {formatDate(date)}
      </Text>
      <Divider borderColor={dividerColor} />
    </Flex>
  )
} 