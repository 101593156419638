import React from 'react';
import { Box, Text, VStack, HStack, Badge, Icon, Flex, SimpleGrid, useBreakpointValue } from '@chakra-ui/react';
import { FaQuestionCircle, FaClock, FaTrophy, FaUtensils } from 'react-icons/fa';
import { Challenge, GameDifficulty } from '../graphql/generated';

interface ChallengeCardProps {
    challenge: {
        id: string;
        title: string;
        description: string;
        points: number;
        // Add other challenge properties as needed
    };
    onClick: () => void;
}

const getDifficultyColor = (difficulty: string) => {
    switch (difficulty) {
        case 'Easy': return 'green';
        case 'Medium': return 'orange';
        case 'Hard': return 'red';
        default: return 'gray';
    }
};

const ChallengeCard = ({ challenge, onClick }: ChallengeCardProps) => {
    const fontSize = useBreakpointValue({ base: "sm", md: "md" });
    const iconSize = useBreakpointValue({ base: 5, md: 6 });
    const padding = useBreakpointValue({ base: 3, md: 6 });

    if (!challenge) {
      return (
        <Box textAlign="center" p={4} bg="gray.100" borderRadius="lg">
          <Text color="gray.500">No challenges available</Text>
        </Box>
      );
    }
  
    return (
            <Box
              borderWidth="1px"
              borderRadius="xl"
              overflow="hidden"
              boxShadow="md"
              _hover={{ boxShadow: 'xl', transform: 'translateY(-4px)' }}
              transition="all 0.3s ease"
              bg="white"
              cursor="pointer"
              onClick={onClick}
            >
              <VStack align="stretch" spacing={3} p={padding}>
                <Flex justifyContent="space-between" alignItems="center" flexWrap="wrap">
                  <HStack spacing={2} mb={{ base: 2, md: 0 }}>
                    <Icon as={FaUtensils} color="purple.500" boxSize={iconSize} />
                    <Text fontWeight="bold" fontSize={{ base: "xl", md: "2xl" }} color="purple.700">
                      {challenge.title}
                    </Text>
                  </HStack>
                  <Badge colorScheme="orange" fontSize={fontSize} px={3} py={1} borderRadius="full">
                    Restaurant
                  </Badge>
                </Flex>
                <Text color="gray.600" fontSize={fontSize} minHeight={{ base: "auto", md: "60px" }}>
                  {challenge.description}
                </Text>
                <Flex justifyContent="space-between" alignItems="center" mt={2} flexWrap="wrap">
                  <HStack 
                    spacing={2} 
                    bg="purple.50" 
                    p={2} 
                    borderRadius="md" 
                    border="1px" 
                    borderColor="purple.200"
                    mb={{ base: 2, md: 0 }}
                  >
                    <Icon as={FaTrophy} color="yellow.500" boxSize={iconSize} />
                    <Text fontSize={fontSize} fontWeight="semibold" color="purple.700">
                      {challenge.points} points
                    </Text>
                  </HStack>
                  <HStack 
                    spacing={2} 
                    bg="green.50" 
                    p={2} 
                    borderRadius="md"
                    border="1px" 
                    borderColor="green.200"
                  >
                    <Icon as={FaClock} color="green.500" boxSize={iconSize} />
                    <Text fontSize={fontSize} fontWeight="medium" color="green.700">
                      1-3 hours
                    </Text>
                  </HStack>
                </Flex>
              </VStack>
            </Box>
    );
  };

const ChallengeGrid: React.FC<{ challenges: Challenge[] }> = ({ challenges }) => {
    return (
        <SimpleGrid columns={{ base: 1, sm: 2, lg: 3 }} spacing={4}>
            {challenges.map((challenge) => (
                <ChallengeCard
                    key={challenge.id}
                    challenge={challenge}
                    onClick={() => {/* Handle click */}}
                />
            ))}
        </SimpleGrid>
    );
};

export {ChallengeCard, ChallengeGrid};
