import React, { useEffect, useRef, useState } from "react";
import { VStack, Heading, Text, Button, Icon, Progress, Input, Image, SimpleGrid, Flex, Box, Spinner, Link } from "@chakra-ui/react";
import { Camera, MapPin, ArrowRight } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { isDarkColor } from "../../helpers/color-util";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useGetMediaQuery } from '../../graphql/generated';

const MotionBox = motion.create(Box as any);

type LocationStateProps = {
  hunt: any;
  currentLocation: number;
  challenge: any;
  nextChallenge: any;
  photos: Array<Array<{preview: string; mediaId: string}>>;
  onPhotoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  totalLocations: number;
  headingSize: string;
  textSize: string;
  buttonSize: string;
};

const MAX_PHOTOS = 2;

const LocationProgress: React.FC<{ currentLocation: number; totalLocations: number; textSize: string }> = ({
  currentLocation,
  totalLocations,
  textSize,
}) => (
  <Text fontSize={textSize} textAlign="center" color="gray.500">
    {currentLocation} / {totalLocations}
  </Text>
);

const NextLocationPreview: React.FC<{ nextChallenge: any }> = ({ nextChallenge }) => (
  <MotionBox
    bg="linear-gradient(135deg, #FF8C42 0%, #FFB566 100%)"
    borderRadius="md" 
    p={4}
    m={2}
    boxShadow="0 4px 6px rgba(0,0,0,0.1)"
    initial={{ opacity: 0, y: 10 }}
    animate={{ opacity: 1, y: 0 }}
    transition={{ duration: 0.3 }}
    _hover={{ transform: "translateY(-2px)", boxShadow: "0 6px 8px rgba(0,0,0,0.15)" }}
  >
    <Flex align="center" justify="center" gap={3}>
      <Text fontSize="sm" fontWeight="500" color="white">
        Next up:
      </Text>
      <Link
        href={`https://maps.google.com/?q=${encodeURIComponent(nextChallenge.address)}`}
        isExternal
        _hover={{ textDecoration: "none" }}
      >
        <Text fontSize="sm" fontWeight="600" color="white">
          {nextChallenge.title} - {nextChallenge.address}
        </Text>
      </Link>
      <Icon as={ArrowRight} color="white" />
    </Flex>
  </MotionBox>
);

export const LocationState: React.FC<LocationStateProps> = ({
  hunt,
  currentLocation,
  challenge,
  nextChallenge,
  photos,
  onPhotoUpload,
  totalLocations,
  headingSize,
  textSize,
  buttonSize,
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [isMapLoading, setIsMapLoading] = useState(true);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN || '';

    const geocodeAddress = async (address: string) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
           address
          )}.json?access_token=${mapboxgl.accessToken}`
        );
        const data = await response.json();
        if (data.features && data.features.length > 0) {
          setCoordinates(data.features[0].center);
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
      }
    };

    if (challenge?.address) {
      geocodeAddress(challenge.address);
    }
  }, [challenge]);

  useEffect(() => {
    if (!coordinates || !mapContainer.current || !challenge) return;

    if (map.current) map.current.remove();

    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: coordinates,
      zoom: 15,
      interactive: true
    });

    map.current.addControl(new mapboxgl.NavigationControl(), 'top-right');

    const marker = new mapboxgl.Marker({ 
      color: hunt.color || '#805AD5'
    })
      .setLngLat(coordinates)
      .addTo(map.current);

    const popup = new mapboxgl.Popup({ offset: 25 })
      .setHTML(`
        <div style="padding: 8px;">
          <h3 style="font-weight: bold; margin-bottom: 4px;">${challenge.title}</h3>
          <p style="color: #666;">${challenge.address}</p>
        </div>
      `);

    marker.setPopup(popup);

    map.current.on('load', () => {
      setIsMapLoading(false);
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [coordinates, challenge, hunt.color]);

  if (!challenge) return null;

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <Box px={6} pt={6}>
        <Progress
          value={(currentLocation / totalLocations) * 100}
          sx={{
            '& > div': {
              transition: 'all 0.3s ease-in-out',
              backgroundColor: hunt.color || "#805AD5" 
            }
          }}
          size="lg"
          borderRadius="full"
          hasStripe
          isAnimated
          bg="gray.100"
        />
      </Box>

      <Box
        position="relative"
        height={{ base: "300px", md: "400px" }}
        width="100%"
      >
        {isMapLoading && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="gray.100"
            justify="center"
            align="center"
            zIndex={1}
          >
            <Spinner 
              size="xl" 
              color={hunt.color || "purple.500"} 
              thickness="4px"
              speed="0.65s"
            />
          </Flex>
        )}
        <Box 
          ref={mapContainer} 
          style={{ width: '100%', height: '100%' }}
        />
      </Box>

      <Box px={6} pb={6}>
        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={6}
          bg="white"
          borderRadius="xl"
          overflow="hidden"
          boxShadow="sm"
          _dark={{ bg: "gray.800" }}
        >
          <Box 
            flex={1} 
            p={6}
            bg="gray.50"
            _dark={{ bg: "gray.700" }}
          >
            <LocationDetails 
              challenge={challenge}
              headingSize={headingSize}
              textSize={textSize}
            />
          </Box>

          <Box 
            flex={1} 
            p={6}
            borderLeft={{ base: "none", md: "1px" }}
            borderTop={{ base: "1px", md: "none" }}
            borderColor="gray.200"
            _dark={{ borderColor: "gray.600" }}
          >
            <PhotoUploadSection
              currentLocation={currentLocation}
              photos={photos}
              onPhotoUpload={onPhotoUpload}
              hunt={hunt}
              buttonSize={buttonSize}
            />
          </Box>
        </Flex>

        <Flex 
          justify="space-between" 
          align="center" 
          mt={6}
          direction={{ base: "column", sm: "row" }}
          gap={4}
        >
          <LocationProgress
            currentLocation={currentLocation + 1}
            totalLocations={totalLocations}
            textSize={textSize}
          />

          {nextChallenge && (
            <NextLocationPreview nextChallenge={nextChallenge} />
          )}
        </Flex>
      </Box>
    </VStack>
  );
};

const LocationDetails: React.FC<{ challenge: any; headingSize: string; textSize: string }> = ({
  challenge,
  headingSize,
  textSize,
}) => (
  <VStack spacing={4} align="stretch">
    <Heading size={headingSize} color="orange.800" textAlign="left">
      {challenge.title}
    </Heading>
    <Text color="gray.700" fontSize={textSize} lineHeight="tall">
      {challenge.description}
    </Text>
    <Flex 
      alignItems="center" 
      bg="blue.50" 
      p={3} 
      borderRadius="md"
      _dark={{ bg: "blue.900" }}
    >
      <Icon as={MapPin} boxSize={5} color="blue.500" mr={3} />
      <Link 
        href={`https://maps.google.com/?q=${encodeURIComponent(challenge.address)}`}
        isExternal
        display="flex"
        alignItems="center"
        color="blue.700"
        fontSize={textSize}
        fontWeight="medium"
        _hover={{ color: "blue.500", textDecoration: "none" }}
      >
        {challenge.address}
      </Link>
    </Flex>
  </VStack>
);

const PhotoUploadSection: React.FC<{
  currentLocation: number;
  photos: Array<Array<{preview: string; mediaId: string}>>;
  onPhotoUpload: (event: React.ChangeEvent<HTMLInputElement>) => void;
  hunt: any;
  buttonSize: string;
}> = ({ currentLocation, photos, onPhotoUpload, hunt, buttonSize }) => {
  const PhotoPreview: React.FC<{ photo: { preview: string; mediaId: string } }> = ({ photo }) => {
    const { data: mediaData } = useGetMediaQuery({
      variables: { id: photo.mediaId },
      skip: !photo.mediaId,
    });

    const imageSource = mediaData?.media 
      ? mediaData.media
      : photo.preview;

    return (
      <MotionBox
        initial={{ opacity: 0, scale: 0.8 }}
        animate={{ opacity: 1, scale: 1 }}
        exit={{ opacity: 0, scale: 0.8 }}
        transition={{ duration: 0.2 }}
        position="relative"
        borderRadius="lg"
        overflow="hidden"
        boxShadow="md"
        _hover={{ transform: "scale(1.05)", boxShadow: "lg" }}
      >
        <Image
          src={imageSource}
          alt="Food photo"
          objectFit="cover"
          w="100%"
          aspectRatio={1}
          borderRadius="lg"
          loading="lazy"
        />
      </MotionBox>
    );
  };

  return (
    <VStack spacing={4} align="stretch" w="100%">
      <Input
        type="file"
        accept="image/*"
        onChange={onPhotoUpload}
        display="none"
        id="photo-upload"
        isDisabled={photos[currentLocation]?.length >= MAX_PHOTOS}
      />
      <Button
        as="label"
        htmlFor="photo-upload"
        style={{ 
          backgroundColor: hunt.color || "#805AD5",
          color: hunt.color && isDarkColor(hunt.color) ? "white" : "black" 
        }}
        leftIcon={<Camera />}
        isDisabled={photos[currentLocation]?.length >= MAX_PHOTOS}
        size={buttonSize}
        width="100%"
        height="48px"
        _hover={{ 
          transform: "translateY(-2px)",
          boxShadow: "lg" 
        }}
        transition="all 0.2s"
      >
        Take Photo ({photos[currentLocation]?.length || 0}/{MAX_PHOTOS})
      </Button>
      <AnimatePresence>
        <SimpleGrid 
          columns={{ base: 2, sm: 3 }} 
          spacing={3} 
          w="100%"
        >
          {photos[currentLocation]?.map((photo, index) => (
            <PhotoPreview key={index} photo={photo} />
          ))}
        </SimpleGrid>
      </AnimatePresence>
    </VStack>
  );
}; 