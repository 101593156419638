import React, { useEffect, useCallback } from 'react';
import {
    Box,
    Flex,
    Text,
    Button,
    VStack,
    Progress,
    IconButton,
    useColorModeValue,
    useDisclosure,
    useBreakpointValue,
} from "@chakra-ui/react";
import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { useGetHuntsQuery, GetHuntsDocument } from '../graphql/generated';
import { useApolloClient } from '@apollo/client';
import CreateHuntModal from './tour-drawer';
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";

interface ActiveToursProps {
    userType: string;
}

const ActiveTours: React.FC<ActiveToursProps> = ({ userType }) => {
    const navigate = useNavigate();
    const client = useApolloClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isLoading } = useLoading();

    const maxTours = userType === 'FREE' ? 1 : userType === 'BASIC' ? 3 : 5;

    const { data, loading, error } = useGetHuntsQuery({
        variables: { limit: maxTours },
        pollInterval: 5000,
        fetchPolicy: 'network-only',
    });
    const hoverBgColor = useColorModeValue("gray.50", "gray.700");
    const textColor = useColorModeValue("purple.800", "white");
    const secondaryTextColor = useColorModeValue("gray.600", "gray.300");

    const isMobile = useBreakpointValue({ base: true, md: false });

    const handleHuntClick = (huntId: string) => {
        navigate(`/home/tours/${huntId}`);
    };

    const activeTours = data?.hunts.filter(hunt => new Date(hunt.endDate) > new Date()) || [];
    const displayedTours = activeTours.slice(0, 5);
    const hasMoreTours = activeTours.length > 5;

    const updateHuntData = useCallback((cachedData: any, huntId: string, newParticipantCount: number) => {
        if (cachedData) {
            return cachedData.hunts.map((hunt: any) =>
                hunt.id === huntId ? { ...hunt, participantCount: newParticipantCount } : hunt
            );
        }
        return [];
    }, []);

    useEffect(() => {
        if (data) {
            const tours = data.hunts;
            tours.forEach((hunt: any) => {
                const cachedData = client.readQuery({
                    query: GetHuntsDocument,
                    variables: { limit: 5 },
                });
                const updatedtours = updateHuntData(cachedData, hunt.id, hunt.participantCount);
                client.writeQuery({
                    query: GetHuntsDocument,
                    variables: { limit: 5 },
                    data: { tours: updatedtours },
                });
            });
        }
    }, [updateHuntData, data, client]);

    if (isLoading || loading) return <CardSkeleton rows={3} />;
    if (error) return <Text p={4}>Error loading tours</Text>;

    return (
        <CardWrapper>
            <CardHeader title="Tours">
                {hasMoreTours && (
                    <Button
                        size="sm"
                        colorScheme="purple"
                        variant="outline"
                        onClick={() => navigate('/home/tours')}
                        width={isMobile ? "full" : "auto"}
                    >
                        View All
                    </Button>
                )}
                {activeTours.length < maxTours && (
                    <Button
                        size="sm"
                        colorScheme="purple"
                        onClick={onOpen}
                        width={isMobile ? "full" : "auto"}
                    >
                        Create New Tour
                    </Button>
                )}
            </CardHeader>
            {displayedTours.length === 0 ? (
                <Box p={4} textAlign="center">
                    <Text color={secondaryTextColor}>No active tours.</Text>
                </Box>
            ) : (
                <VStack spacing={0} align="stretch">
                    {displayedTours.map((hunt: any) => (
                        <Flex
                            key={hunt.id}
                            p={4}
                            direction={isMobile ? "column" : "row"}
                            gap={isMobile ? 3 : 0}
                            _hover={{ bg: hoverBgColor }}
                            cursor="pointer"
                            onClick={() => handleHuntClick(hunt.id)}
                        >
                            <Box flex="1">
                                <Text fontWeight="bold" fontSize="md" color={textColor}>
                                    {hunt.title}
                                </Text>
                                <Text fontSize="sm" color={secondaryTextColor}>
                                    {hunt.participantCount} participants
                                </Text>
                            </Box>
                            <Flex 
                                width={isMobile ? "100%" : "auto"}
                                justify={isMobile ? "space-between" : "flex-end"}
                                align="center"
                                gap={3}
                            >
                                <Flex align="center" gap={2}>
                                    <Progress
                                        value={hunt.progress}
                                        size="xs"
                                        width="60px"
                                        colorScheme="purple"
                                        borderRadius="full"
                                    />
                                    <Text fontSize="xs" fontWeight="medium" color={textColor}>
                                        {hunt.progress}%
                                    </Text>
                                </Flex>
                                <IconButton
                                    aria-label="View hunt details"
                                    icon={<ChevronRight size={18} />}
                                    variant="ghost"
                                    colorScheme="purple"
                                    size="sm"
                                />
                            </Flex>
                        </Flex>
                    ))}
                </VStack>
            )}
            <CreateHuntModal isOpen={isOpen} onClose={onClose} />
        </CardWrapper>
    );
};

export default ActiveTours;
