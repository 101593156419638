import React, { useEffect } from 'react';
import { Box } from "@chakra-ui/react";

interface StripePricingTableProps {
  pricingTableId: string;
  publishableKey: string;
}

declare global {
  namespace JSX {
    interface IntrinsicElements {
      'stripe-pricing-table': React.DetailedHTMLProps<React.HTMLAttributes<HTMLElement>, HTMLElement>;
    }
  }
}

const StripePricingTable: React.FC<StripePricingTableProps> = ({ pricingTableId, publishableKey }) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://js.stripe.com/v3/pricing-table.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Box bg="#fbf6ff" p={4} borderRadius="md">
      <stripe-pricing-table
        pricing-table-id={pricingTableId}
        publishable-key={publishableKey}
      />
    </Box>
  );
};

export default StripePricingTable;
