import { useState, useEffect } from "react";
import { 
  VStack, 
  Button, 
  Flex, 
  Spinner,
  Text,
  Container,
  useToast
} from "@chakra-ui/react";
import { getCurrentLocation, generateMultiStopMapUrl, type Coordinates } from "../../helpers/map-util";
import { isDarkColor } from "../../helpers/color-util";
import { TourHeader } from "./intro-state/tour-header";
import { LocationCard } from "./intro-state/location-card";

type IntroStateProps = {
  loading: boolean;
  error?: Error | null;
  hunt: any;
  closestChallenge: any;
  onStart: () => void;
};

export const IntroState = ({
  loading,
  error,
  hunt,
  closestChallenge,
  onStart,
}: IntroStateProps) => {
  const [userLocation, setUserLocation] = useState<Coordinates>(null);
  const [isLoadingLocation, setIsLoadingLocation] = useState(false);
  const toast = useToast();

  const fetchUserLocation = async () => {
    setIsLoadingLocation(true);
    try {
      const location = await getCurrentLocation();
      setUserLocation(location);
    } catch (error) {
      toast({
        title: "Location Access",
        description: "Unable to access your location. Using first stop as starting point.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoadingLocation(false);
    }
  };

  const mapUrl = generateMultiStopMapUrl(hunt?.challenges || [], userLocation);
  
  if (loading) return (
    <Flex justify="center" align="center" height="100vh">
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color={hunt?.color || "orange.500"}
        size="xl"
      />
    </Flex>
  );

  if (error || !hunt) {
    return <Text color="red.500" p={4}>No food tour found.</Text>;
  }

  return (
    <Container maxW="container.md" py={[4, 6, 8]}>
      <VStack 
        spacing={[4, 6, 8]} 
        align="center" 
        justify="center"
      >
        <TourHeader
          logo={hunt.logo}
          title={hunt.title}
          description={hunt.description}
          color={hunt.color}
        />

        <LocationCard
          closestChallenge={closestChallenge}
          userLocation={userLocation}
          isLoadingLocation={isLoadingLocation}
          mapUrl={mapUrl}
          onFetchLocation={fetchUserLocation}
        />

        <Button
          style={{
            backgroundColor: hunt.color || "orange.500",
            color: hunt.color && isDarkColor(hunt.color) ? "white" : "black"
          }}
          onClick={onStart}
          width="100%"
          maxWidth="300px"
          size={["md", "lg"]}
          fontSize={["md", "lg"]}
          py={[4, 6]}
          boxShadow="md"
          _hover={{
            transform: "translateY(-2px)",
            boxShadow: "lg"
          }}
          transition="all 0.2s"
        >
          Get Started
        </Button>
      </VStack>
    </Container>
  );
}; 