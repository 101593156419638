import {
	ApolloClient,
	InMemoryCache,
	HttpLink,
	split,
	ApolloLink,
	from,
	Operation,
	NextLink,
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { createClient } from 'graphql-ws';
import { onError, ErrorResponse } from '@apollo/client/link/error';

import env from './env';

// Create an HTTP link
const httpLink = new HttpLink({
	uri: `${env.REACT_APP_SERVER_URL}/graphql`,
	credentials: 'include',
});

// Create a WebSocket link using graphql-ws
const wsLink = new GraphQLWsLink(
	createClient({
		url: `${env.REACT_APP_SERVER_URL.replace('http', 'ws').replace('https', 'wss')}/graphql`,
		retryAttempts: 10,
		connectionParams: {
			credentials: 'include',
		},
	})
);

const errorLink: ApolloLink = onError(
	({ graphQLErrors, networkError }: ErrorResponse) => {
		if (graphQLErrors) {
			graphQLErrors.forEach(error =>
				console.error(
					`[GraphQL error]: Message: ${error.message}, Location: ${JSON.stringify(
						error.locations
					)}, Path: ${error.path}`
				)
			);
		}
		if (networkError) {
			console.error(`[Network error]: ${networkError}`);
		}
	}
);

const authLink = new ApolloLink((operation: Operation, forward: NextLink) => {
	operation.setContext({
		headers: {
			'Access-Control-Allow-Origin': '*',
			credentials: true,
		},
	});
	return forward(operation);
});

const splitLink = split(
	({ query }) => {
		const definition = getMainDefinition(query);
		return (
			definition.kind === 'OperationDefinition' &&
			definition.operation === 'subscription'
		);
	},
	wsLink,
	from([errorLink, authLink, httpLink])
);

const client = new ApolloClient({
	link: splitLink,
	cache: new InMemoryCache(),
});

export { client };
