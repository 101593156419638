import React, { useEffect, useState } from 'react';
import './app.css';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { Login as LoginPage, Signup as SignupPage, Verify as VerifyPage } from './pages/auth';
import { useAuth } from './context/auth';
import DashboardPage from './pages/dashboard/home';
import LandingPage from './pages/landing';
import { Spinner, Flex } from '@chakra-ui/react';
import JoinHunt from './pages/participants/join-hunt';
import ChallengeSelectionPage from './pages/participants/challenges';
import HuntNotStartedPage from './pages/participants/hunt-not-started';
import DashboardLayout from './components/shared/layout';
import ToursPage from './pages/dashboard/tours';
import ParticipantsPage from './pages/dashboard/participants';
import ActivitiesPage from './pages/dashboard/activities';
import PreferencesPage from './pages/dashboard/preferences';
import TourDetailsPage from './pages/dashboard/tours/tour';
import PlayGamePage from './pages/participants/play-quiz-game';
import PlayFoodTourGame from './pages/participants/play-food-tour-game';
import HowToPlayPage from './pages/participants/how-to-play-food-tour';
import LocationDetailsPage from './pages/dashboard/challenge-detail';
import { LoadingProvider } from "./context/loading";
import BillingPage from './pages/dashboard/billing';
import MessagePage from './pages/messages/index';
import { useInterval } from './hooks/useInterval';

const useAuthCheck = () => {
  const { user, checkAuthStatus } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const isParticipantRoute = location.pathname.startsWith('/participant');

  useEffect(() => {
    const initAuth = async () => {
      if (!isParticipantRoute) {
        await checkAuthStatus();
      }
      setIsLoading(false);
    };
    initAuth();
  }, [isParticipantRoute]);

  useInterval(
    async () => {
      if (!isParticipantRoute) {
        await checkAuthStatus();
      }
    },
    20000
  );

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get('token');
    const email = params.get('email');

    if (token && email) {
      window.history.replaceState(null, '', '/verify');
    }
  }, [location]);

  return { user, isLoading };
};

function App() {
  const { user, isLoading } = useAuthCheck();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [tourDrawerOpen, setTourDrawerOpen] = useState(false);
  const [locationDrawerOpen, setLocationDrawerOpen] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (user?.isAuth && !isLoading && location.pathname.startsWith('/home')) {
      // Only show onboarding on dashboard pages and check for first visit
      const hasSeenOnboarding = localStorage.getItem('hasSeenOnboarding');
      if (!hasSeenOnboarding) {
        setShowOnboarding(true);
      }
    }
  }, [user?.isAuth, isLoading, location.pathname]);

  const handleOnboardingClose = () => {
    setShowOnboarding(false);
    localStorage.setItem('hasSeenOnboarding', 'true');
  };

  if (isLoading) {
    return (
      <Flex height="100vh" alignItems="center" justifyContent="center">
        <Spinner size="xl" color="purple.500" />
      </Flex>
    );
  }

  return (
    <LoadingProvider>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={user?.isAuth ? <Navigate to="/home" replace /> : <LoginPage />} />
        <Route path="/signup" element={user?.isAuth ? <Navigate to="/home" replace /> : <SignupPage />} />
        <Route
          path="/verify"
          element={
            user?.isAuth ? (
              <Navigate to="/home" replace />
            ) : (
              <VerifyPage />
            )
          }
        />
        <Route
          path="/home/*"
          element={
            user?.isAuth ? (
              <DashboardLayout 
                tourDrawerOpen={tourDrawerOpen}
                setTourDrawerOpen={setTourDrawerOpen}
                locationDrawerOpen={locationDrawerOpen}
                setLocationDrawerOpen={setLocationDrawerOpen}
              >
                <Routes>
                  <Route index element={<DashboardPage />} />
                  <Route path="tours" element={<ToursPage />} />
                  <Route path="billing" element={<BillingPage />} />
                  <Route path="participants" element={<ParticipantsPage />} />
                  <Route path="activity" element={<ActivitiesPage />} />
                  <Route path="settings" element={<PreferencesPage />} />
                  <Route path="tours/:tourId" element={<TourDetailsPage />} />
                  <Route path="locations/:locationId" element={<LocationDetailsPage />} />
                  <Route path="messages/:tourId/:participantId" element={<MessagePage />} />
                </Routes>
              </DashboardLayout>
            ) : (
              <Navigate to="/login" replace />
            )
          }
        />
        <Route path="*" element={<Navigate replace to="/" />} />
        <Route path="/participant/:huntId/join" element={<JoinHunt />} />
        <Route path="/participant/:huntId/challenges" element={<ChallengeSelectionPage />} />
        <Route path="/participant/:huntId/play-game" element={<PlayGamePage />} />
        <Route path="/participant/:huntId/play-food-tour-game" element={<PlayFoodTourGame />} />
        <Route path="/participant/:huntId/how-to-play" element={<HowToPlayPage />} />
        <Route path="/participant/:huntId/not-started" element={<HuntNotStartedPage />} />
        <Route path="/participant/:tourId/messages/:participantId" element={<MessagePage />} />
      </Routes>
    </LoadingProvider>
  );
}

export default App;