import React, { useEffect, useState } from 'react';
import {
  VStack,
  Flex,
  Text,
  Avatar,
  Divider,
  Box,
  Button,
  Textarea,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Icon,
  Card,
  CardBody,
  Progress,
} from "@chakra-ui/react";
import { AuthUser } from '../../context/auth';
import { useLogoutMutation } from '../../graphql/generated';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { BarChartIcon as BarChart, CompassIcon as Compass,SettingsIcon as Settings, ClockIcon as Clock, LogOutIcon as LogOut, SendIcon as Send, ZapIcon as Zap, CreditCardIcon as CreditCard } from "lucide-react";
import { useQuery } from "@apollo/client";
import { GetGenerationsTodayQuery, useGetGenerationsTodayQuery } from "../../graphql/generated";
const NavItem = ({ icon, children, onClick, to, isActive, hoverBgColor, activeBgColor, ...rest }: { icon: React.ElementType; children: React.ReactNode; onClick?: () => void; to?: string; isActive: boolean; hoverBgColor: string; activeBgColor: string }) => {
  const navigate = useNavigate();
  const handleClick = (e: React.MouseEvent) => {
    if (onClick) {
      e.preventDefault();
      onClick();
    } else if (to) {
      navigate(to);
    }
  };

  return (
    <Flex
      as="a"
      href={to || '#'}
      onClick={handleClick}
      align="center"
      p="4"
      mx="4"
      fontWeight="semibold"
      borderRadius="lg"
      role="group"
      cursor="pointer"
      bg={isActive ? activeBgColor : 'transparent'}
      color="white"
      _hover={{
        bg: hoverBgColor,
      }}
      {...rest}>
      {icon && (
        <Icon
          mr="4"
          fontSize="16"
          as={icon}
        />
      )}
      {children}
    </Flex>
  );
};

const GenerationCredits = () => {
  const { data, loading } = useGetGenerationsTodayQuery({
    pollInterval: 30000, // Poll every 30 seconds to keep count updated
  });

  if (loading) {
    return (
      <Flex
        mx={4}
        p={3}
        bg="whiteAlpha.200"
        borderRadius="lg"
        alignItems="center"
        justifyContent="center"
      >
        <Text fontSize="sm" color="white">Loading...</Text>
      </Flex>
    );
  }

  const formatTime = (dateString: string) => {
    return new Date(dateString).toLocaleString([], { 
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: '2-digit'
    });
  };

  return (
    <Card
      mx={4}
      bg="whiteAlpha.200"
      borderRadius="lg"
      border="1px solid"
      borderColor="whiteAlpha.300"
    >
      <CardBody p={3}>
        <VStack spacing={1} align="stretch">
          <Flex justify="space-between" align="center">
            <Flex align="center">
              <Icon as={Zap} color="white" mr={2} />
              <Text color="white" fontSize="sm" fontWeight="medium">
                AI Credits
              </Text>
            </Flex>
            <Text color="white" fontSize="lg" fontWeight="bold">
              {data?.generationsToday.remaining}/{data?.generationsToday.limit}
            </Text>
          </Flex>
          <Text color="whiteAlpha.700" fontSize="xs">
            Credits refresh at {formatTime(data?.generationsToday.resetAt ?? "")}
          </Text>
        </VStack>
      </CardBody>
    </Card>
  );
};

const AdCard = ({ showAd }: { showAd: boolean }) => {
  const navigate = useNavigate();
  const promoCode = "FIRST24";
  if (!showAd) return null;

  return (
    <Card
      bg="white"
      mx={4}
      mb={3}
      boxShadow="lg"
      borderRadius="md"
      overflow="hidden"
    >
      <CardBody p={4}>
        <Flex align="center" justify="space-between" mb={3}>
          <Text fontWeight="bold" fontSize="lg" color="purple.700">
            Limited Time Offer!
          </Text>
        </Flex>
        <Text fontSize="sm" color="purple.700" mb={2}>
          Unlock AI-powered features & save 20% today!
        </Text>
        <Text fontSize="sm" fontWeight="normal" color="purple.700" mb={4}>
          Use code: <Text as="span" fontWeight="bold" fontSize="md" fontStyle={"italic"} color="yellow.400">{promoCode}</Text>
        </Text>
        <Button
          size="sm"
          color={"white"}
          bg={"purple.700"}
          width="100%"
          onClick={() => navigate('/#pricing')}
          _hover={{ bg: "purple.800" }}
        >
          Upgrade Now
        </Button>
      </CardBody>
    </Card>
  );
};

const getNavItems = (userType: string) => {
  const baseItems: { icon: any; label: string; to?: string; onClick?: () => void }[] = [
    { icon: BarChart, label: 'Dashboard', to: '/home' },
    { icon: Compass, label: 'Tours', to: '/home/tours' },
  ];

  if (userType === 'BASIC' || userType === 'PLUS') {
    baseItems.splice(3, 0, { icon: Clock, label: 'Activity', to: '/home/activity' });
  }

  baseItems.push({ icon: CreditCard, label: 'Billing', to: '/home/billing' });
  baseItems.push({ icon: Settings, label: 'Settings', to: '/home/settings' });

  return baseItems;
};

const SidebarContent = ({ user, appName }: { user: AuthUser; appName: string }) => {
  const bgGradient = "linear(to-b, purple.500, pink.500)";
  const textColor = "white";
  const hoverBgColor = "whiteAlpha.200";
  const activeItemBgColor = "whiteAlpha.300";
  const borderColor = "whiteAlpha.300";

  const appData = {
    name: appName,
    icon: Compass,
    color: 'white'
  };

  const navigate = useNavigate();
  const location = useLocation();
  const [logoutMutation] = useLogoutMutation({
    onCompleted: () => {
      navigate('/login');
    }
  });

  const handleLogout = async () => {
    await logoutMutation();
    window.location.reload();
  };

  const [userType, setUserType] = useState<string | null>(null);
  const [bottomComponent, setBottomComponent] = useState<React.ReactNode | null>(null);

  useEffect(() => {
    if (user.userType && (user.userType !== "FREE")) {
      setUserType(user.userType);
    }
  }, [user.userType]);

  useEffect(() => {
    setBottomComponent(
      userType ? <GenerationCredits /> : <AdCard showAd={true} />
    );
  }, [userType]);

  return (
    <VStack spacing="2" align="stretch" height="100%" bgGradient={bgGradient} color={textColor}>
      <Flex
        h="16"
        alignItems="center"
        justifyContent="center"
        px="2"
        mb="-1"
      >
        <Flex
          alignItems="center"
          maxW="80%"
        >
          <img src={"/images/culinary-castle-logo.png"} alt="Culinary Castle Logo" style={{ width: "32px", height: "32px" }} />
          <Link to="/">
            <Text
              fontSize="xl"
              fontWeight="bold"
              color={appData.color}
              ml="2"
              isTruncated
            >
              {appData.name}
            </Text>
          </Link>
        </Flex>
      </Flex>

      <Divider borderColor={borderColor} />

      <Flex
        direction="column"
        alignItems="center"
        justifyContent="center"
        py="4"
        borderBottomWidth="1px"
        borderBottomColor={borderColor}
      >
        <Avatar
          size="xl"
          color={'purple.500'}
          bgColor={'white'}
          src={user.profilePicture ? `data:image/jpeg;base64,${user.profilePicture}` : undefined}
          name={user.username}
          mb="2"
          boxShadow="0 0 0 3px rgba(255, 255, 255, 0.4)"
        />
        <Text fontSize="lg" fontWeight="semibold">
          {user.username}
        </Text>
        <Text fontSize="sm" color="whiteAlpha.800">
          {userType}
        </Text>
      </Flex>

      <VStack spacing="2" align="stretch" mt="4" flex="1">
        {getNavItems(user.userType || "FREE").map((item, index) => (
          <NavItem
            key={index}
            icon={item.icon}
            onClick={item.onClick}
            to={item.to}
            isActive={location.pathname === item.to}
            hoverBgColor={hoverBgColor}
            activeBgColor={activeItemBgColor}
          >
            {item.label}
          </NavItem>
        ))}
        <NavItem 
          icon={LogOut} 
          onClick={handleLogout} 
          to="#" 
          isActive={false} 
          hoverBgColor={hoverBgColor} 
          activeBgColor={activeItemBgColor}
        >
          Logout
        </NavItem>
      </VStack>

      <Box mt="auto" mb={4}>
        {bottomComponent}
      </Box>
    </VStack>
  );
};

export default SidebarContent;
