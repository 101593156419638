import { z } from "zod";

const envSchema = z.object({
  FRONTEND_PORT: z.coerce.number().min(3000).default(4000),
  REACT_APP_SERVER_URL: z.coerce.string(),
  REACT_APP_MAPBOX_TOKEN: z.coerce.string(),
  REACT_APP_ENV: z
    .union([
      z.literal("localhost"),
      z.literal("development"),
      z.literal("testing"),
      z.literal("production"),
    ])
    .default("localhost"),
});

const env = envSchema.parse(process.env);

export default env;
