import React, { createContext, useContext, useState, ReactNode, useEffect } from 'react';
import { useCurrentUserQuery, useLogoutMutation } from '../graphql/generated';
import { UserType } from '../graphql/generated';
export type AuthUser = {
  id?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  username?: string;
  profilePicture?: string;
  userType?: UserType;
  isAuth: boolean;
}

interface AuthContextType {
  user: AuthUser;
  setUser: (user: AuthUser) => void;
  logout: () => void;
  checkAuthStatus: () => Promise<void>;
  updateProfilePicture: (profilePicture: string) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [user, setUser] = useState<AuthUser>(() => {
    const storedUser = localStorage.getItem('user');
    return storedUser ? JSON.parse(storedUser) : { isAuth: false };
  });
  const { data, refetch } = useCurrentUserQuery({
    fetchPolicy: 'network-only',
    pollInterval: 1_000
  });
  const [logoutMutation] = useLogoutMutation();

  useEffect(() => {
    if (!data?.me) return;

    const updatedUser = { isAuth: true, profilePicture: data.me.profilePicture || undefined, id: data.me.id || undefined, email: data.me.email || undefined, username: data.me.username || undefined, firstName: data.me.firstName || undefined, lastName: data.me.lastName || undefined, userType: data.me.userType || undefined };
    setUser(updatedUser);
    localStorage.setItem('user', JSON.stringify(updatedUser));
  }, [data]);

  const logout = async () => {
    try {
      await logoutMutation();
      setUser({ isAuth: false });
      localStorage.removeItem('user');
      await refetch();
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  const checkAuthStatus = async () => {
    try {
      const { data } = await refetch();
      if (data?.me) {
        const updatedUser = { isAuth: true, profilePicture: data.me.profilePicture || undefined, id: data.me.id || undefined, email: data.me.email || undefined, username: data.me.username || undefined, firstName: data.me.firstName || undefined, lastName: data.me.lastName || undefined, userType: data.me.userType || undefined };
        setUser(updatedUser);
        localStorage.setItem('user', JSON.stringify(updatedUser));
      } else {
        setUser({ isAuth: false });
        localStorage.removeItem('user');
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
      setUser({ isAuth: false });
      localStorage.removeItem('user');
    }
  };

  const updateProfilePicture = (profilePicture: string) => {
    setUser(prevUser => {
      const updatedUser = { ...prevUser, profilePicture };
      localStorage.setItem('user', JSON.stringify(updatedUser));
      return updatedUser;
    });
  };

  return (
    <AuthContext.Provider value={{ user, setUser, logout, checkAuthStatus, updateProfilePicture }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};