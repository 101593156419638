import React, { useRef, useState, useEffect } from "react";
import { 
  VStack, 
  Heading, 
  Text, 
  Flex, 
  Icon, 
  Box, 
  Progress, 
  Button, 
  Link, 
  Spinner, 
  Input, 
  SimpleGrid 
} from "@chakra-ui/react";
import { Camera, MapPin } from "lucide-react";
import { motion } from "framer-motion";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';

const MotionBox = motion(Box as any);

type ChallengePreviewProps = {
  challenge: {
    title: string;
    description: string;
    address: string;
    points: number;
  };
  color: string;
  onUploadMedia: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const STATE_ABBREVIATIONS: Record<string, string> = {
  Alabama: "AL",
  Alaska: "AK",
  Arizona: "AZ",
  Arkansas: "AR",
  California: "CA",
  Colorado: "CO",
  Connecticut: "CT",
  Delaware: "DE",
  "District of Columbia": "DC",
  Florida: "FL",
  Georgia: "GA",
  Hawaii: "HI",
  Idaho: "ID",
  Illinois: "IL",
  Indiana: "IN",
  Iowa: "IA",
  Kansas: "KS",
  Kentucky: "KY",
  Louisiana: "LA",
  Maine: "ME",
  Maryland: "MD",
  Massachusetts: "MA",
  Michigan: "MI",
  Minnesota: "MN",
  Mississippi: "MS",
  Missouri: "MO",
  Montana: "MT",
  Nebraska: "NE",
  Nevada: "NV",
  "New Hampshire": "NH",
  "New Jersey": "NJ",
  "New Mexico": "NM",
  "New York": "NY",
  "North Carolina": "NC",
  "North Dakota": "ND",
  Ohio: "OH",
  Oklahoma: "OK",
  Oregon: "OR",
  Pennsylvania: "PA",
  "Rhode Island": "RI",
  "South Carolina": "SC",
  "South Dakota": "SD",
  Tennessee: "TN",
  Texas: "TX",
  Utah: "UT",
  Vermont: "VT",
  Virginia: "VA",
  Washington: "WA",
  "West Virginia": "WV",
  Wisconsin: "WI",
  Wyoming: "WY",
};

const formatAddress = (address: string) => {
  if (!Number.isNaN(parseInt(address.split(" ")[0]))) return address;
  const addressParts = address.split(",");
  if (addressParts.length < 3) return address;
  const street = addressParts[1].trim();
  const city = addressParts[2].trim();
  const zipAndState = addressParts[3].trim();
  const [state, zip] = zipAndState.split(" ");
  const abbrState = STATE_ABBREVIATIONS[state];
  return `${street}, ${city}, ${abbrState} ${zip}`;
};

const LocationDetails: React.FC<{ challenge: ChallengePreviewProps["challenge"] }> = ({
  challenge
}) => (
  <VStack spacing={4} align="stretch">
    <Heading size="md" color="orange.800" textAlign="left">
      {challenge.title || "Location Title"}
    </Heading>
    <Text color="gray.700" fontSize="sm" lineHeight="tall">
      {challenge.description || "Location Description"}
    </Text>
    <Flex 
      alignItems="center" 
      bg="blue.50" 
      p={3} 
      borderRadius="md"
      _dark={{ bg: "blue.900" }}
    >
      <Icon as={MapPin} boxSize={5} color="blue.500" mr={3} />
      <Link 
        href={`https://maps.google.com/?q=${encodeURIComponent(challenge.address)}`}
        isExternal
        display="flex"
        alignItems="center"
        color="blue.700"
        fontSize="sm"
        fontWeight="medium"
        _hover={{ color: "blue.500", textDecoration: "none" }}
      >
        {formatAddress(challenge.address) || "Location Address"}
      </Link>
    </Flex>
  </VStack>
);

const PhotoUploadSection: React.FC<{
  color: string;
  isDarkColor: (color: string) => boolean;
}> = ({ color, isDarkColor }) => (
  <VStack spacing={4} align="stretch" w="100%">
    <Input
      type="file"
      accept="image/*"
      display="none"
      id="photo-upload-preview"
    />
    <Button
      as="label"
      htmlFor="photo-upload-preview"
      style={{ 
        backgroundColor: color || "#805AD5",
        color: color && isDarkColor(color) ? "white" : "black" 
      }}
      leftIcon={<Camera />}
      size="md"
      width="100%"
      height="48px"
      _hover={{ 
        transform: "translateY(-2px)",
        boxShadow: "lg" 
      }}
      transition="all 0.2s"
    >
      Take Photo (0/2)
    </Button>
    <SimpleGrid 
      columns={{ base: 2, sm: 3 }} 
      spacing={3} 
      w="100%"
    >
      {/* Photo previews will go here */}
    </SimpleGrid>
  </VStack>
);

const ChallengePreview: React.FC<ChallengePreviewProps> = ({ 
  challenge, 
  color, 
  onUploadMedia 
}) => {
  const mapContainer = useRef<HTMLDivElement>(null);
  const map = useRef<mapboxgl.Map | null>(null);
  const [coordinates, setCoordinates] = useState<[number, number] | null>(null);
  const [isMapLoading, setIsMapLoading] = useState(true);
  const [mapError, setMapError] = useState<string | null>(null);

  useEffect(() => {
    if (!process.env.REACT_APP_MAPBOX_TOKEN) {
      setMapError("Mapbox token is missing");
      setIsMapLoading(false);
      return;
    }

    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

    const geocodeAddress = async (address: string) => {
      try {
        const response = await fetch(
          `https://api.mapbox.com/geocoding/v5/mapbox.places/${encodeURIComponent(
           address
          )}.json?access_token=${mapboxgl.accessToken}`
        );
        
        if (!response.ok) {
          throw new Error(`Geocoding failed: ${response.statusText}`);
        }

        const data = await response.json();
        if (data.features && data.features.length > 0) {
          setCoordinates(data.features[0].center);
        } else {
          setMapError("Address not found");
        }
      } catch (error) {
        console.error('Error geocoding address:', error);
        setMapError("Failed to load location");
      } finally {
        setIsMapLoading(false);
      }
    };

    if (challenge?.address) {
      geocodeAddress(challenge.address);
    }
  }, [challenge]);

  useEffect(() => {
    if (!coordinates || !mapContainer.current || !challenge) return;

    try {
      if (map.current) map.current.remove();

      map.current = new mapboxgl.Map({
        container: mapContainer.current,
        style: 'mapbox://styles/mapbox/streets-v11',
        center: coordinates,
        zoom: 14,
        interactive: true,
        attributionControl: false,
        maxZoom: 18,
        minZoom: 12,
        dragRotate: false
      });

      // Add load event handler
      map.current.on('load', () => {
        setIsMapLoading(false);
      });

      // Add error event handler
      map.current.on('error', (e) => {
        console.error('Map error:', e);
        setMapError("Failed to load map");
        setIsMapLoading(false);
      });

      // Add zoom controls with custom positioning
      const nav = new mapboxgl.NavigationControl({
        showCompass: false,
        visualizePitch: false
      });
      map.current.addControl(nav, 'bottom-right');

      // Custom styled marker
      const marker = new mapboxgl.Marker({ 
        color: color || '#805AD5',
        scale: 0.8 // Slightly smaller marker
      })
        .setLngLat(coordinates)
        .addTo(map.current);

      // Styled popup
      const popup = new mapboxgl.Popup({ 
        offset: 25,
        closeButton: false,
        className: 'custom-popup'
      })
        .setHTML(`
          <div style="
            padding: 12px;
            font-family: system-ui, -apple-system, sans-serif;
          ">
            <h3 style="
              font-weight: 600;
              font-size: 14px;
              margin-bottom: 4px;
              color: #2D3748;
            ">${challenge.title}</h3>
            <p style="
              color: #4A5568;
              font-size: 12px;
            ">${challenge.address}</p>
          </div>
        `);

      marker.setPopup(popup);

      // Add responsive handling
      const handleResize = () => {
        if (map.current) {
          map.current.resize();
          // Adjust zoom based on screen size
          const isMobile = window.innerWidth < 768;
          map.current.setZoom(isMobile ? 13 : 14);
        }
      };

      window.addEventListener('resize', handleResize);
      handleResize(); // Initial resize

    } catch (error) {
      console.error('Error initializing map:', error);
      setMapError("Failed to initialize map");
      setIsMapLoading(false);
    }
  }, [coordinates, challenge, color]);

  const isDarkColor = (color: string) => {
    const hex = color.replace(/^#/, "");
    const r = parseInt(hex.slice(0, 2), 16);
    const g = parseInt(hex.slice(2, 4), 16);
    const b = parseInt(hex.slice(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000 < 128;
  };

  return (
    <VStack spacing={6} align="stretch" width="100%">
      <Box px={6} pt={6}>
        <Progress
          value={50}
          sx={{
            "& > div": {
              transition: "all 0.3s ease-in-out",
              backgroundColor: color || "#805AD5" 
            }
          }}
          size="lg"
          borderRadius="full"
          hasStripe
          isAnimated
          bg="gray.100"
        />
      </Box>

      <Box
        position="relative"
        height={{ base: "250px", sm: "300px", md: "400px" }}
        width="100%"
        borderRadius={{ base: "none", md: "lg" }}
        overflow="hidden"
        boxShadow={{ base: "none", md: "base" }}
      >
        {isMapLoading && (
          <Flex
            position="absolute"
            top={0}
            left={0}
            right={0}
            bottom={0}
            bg="gray.100"
            justify="center"
            align="center"
            zIndex={1}
          >
            {mapError ? (
              <Text color="red.500">{mapError}</Text>
            ) : (
              <Spinner 
                size="xl" 
                color={color || "purple.500"} 
                thickness="4px"
                speed="0.65s"
              />
            )}
          </Flex>
        )}
        <Box 
          ref={mapContainer} 
          style={{ width: '100%', height: '100%' }}
          className="map-container"
          sx={{
            ".mapboxgl-ctrl-bottom-right": {
              mb: { base: 2, md: 4 },
              mr: { base: 2, md: 4 }
            },
            ".mapboxgl-popup-content": {
              p: 0,
              borderRadius: "md",
              boxShadow: "lg"
            }
          }}
        />
      </Box>

      <Box px={6} pb={6}>
        <Flex 
          direction={{ base: "column", md: "row" }} 
          gap={6}
          bg="white"
          borderRadius="xl"
          overflow="hidden"
          boxShadow="sm"
          _dark={{ bg: "gray.800" }}
        >
          <Box 
            flex={1} 
            p={6}
            bg="gray.50"
            _dark={{ bg: "gray.700" }}
          >
            <LocationDetails challenge={challenge} />
          </Box>

          <Box 
            flex={1} 
            p={6}
            borderLeft={{ base: "none", md: "1px" }}
            borderTop={{ base: "1px", md: "none" }}
            borderColor="gray.200"
            _dark={{ borderColor: "gray.600" }}
          >
            <PhotoUploadSection 
              color={color} 
              isDarkColor={isDarkColor}
            />
          </Box>
        </Flex>
      </Box>
    </VStack>
  );
};

export default ChallengePreview;
