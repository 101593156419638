export type Coordinates = {
  latitude: number;
  longitude: number;
} | null;

export const generateMultiStopMapUrl = (
  challenges: any[], 
  userLocation: Coordinates = null
) => {
  if (!challenges || challenges.length === 0) return '';

  const baseUrl = 'https://www.google.com/maps/dir/?api=1';
  
  // If user location is available, use it as origin
  const origin = userLocation 
    ? `${userLocation.latitude},${userLocation.longitude}`
    : encodeURIComponent(challenges[0].address);
  
  // If user location is available, include first challenge as waypoint
  const waypointChallenges = userLocation 
    ? challenges.slice(0, -1) 
    : challenges.slice(1, -1);
    
  const waypoints = waypointChallenges
    .map(challenge => encodeURIComponent(challenge.address))
    .join('|');
    
  const destination = encodeURIComponent(challenges[challenges.length - 1].address);

  let url = `${baseUrl}&origin=${origin}&destination=${destination}`;
  if (waypoints) {
    url += `&waypoints=${waypoints}`;
  }

  return url;
};

export const getCurrentLocation = (): Promise<Coordinates> => {
  return new Promise((resolve, reject) => {
    if (!navigator.geolocation) {
      resolve(null);
      return;
    }

    navigator.geolocation.getCurrentPosition(
      (position) => {
        resolve({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
      },
      (error) => {
        console.error("Error getting location:", error);
        resolve(null);
      },
      { timeout: 10000 } // 10 second timeout
    );
  });
};