import React, { useState } from 'react';
import {
  Box, Flex, Text, Button, VStack, IconButton,
  useColorModeValue, Heading, Spinner, useDisclosure, HStack,
  Skeleton, Alert, AlertIcon, Tooltip, Badge, useBreakpointValue, Stack
} from "@chakra-ui/react";
import { ChevronRight } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { Challenge, useGetChallengesQuery } from '../graphql/generated';
import CreateChallengeModal from './challenge-drawer';
import CardSkeleton from './shared/card-skeleton';
import { useLoading } from "../context/loading";
import CardHeader from "./shared/card-header";
import CardWrapper from "./shared/card-wrapper";

interface ShowChallengesProps {
  userType: string;
}

const ShowChallenges: React.FC<ShowChallengesProps> = ({ userType }) => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showAll, setShowAll] = useState(false);

  const maxChallenges = userType === 'FREE' ? 2 : userType === 'BASIC' ? 25 : userType === 'PLUS' ? 50 : Infinity;

  const { data, loading, error } = useGetChallengesQuery({
    pollInterval: 2_500,
    fetchPolicy: 'network-only'
  });

  const bgColor = useColorModeValue("white", "gray.800");
  const borderColor = useColorModeValue("gray.200", "gray.700");
  const hoverBgColor = useColorModeValue("gray.50", "gray.700");
  const textColor = useColorModeValue("purple.800", "white");
  const secondaryTextColor = useColorModeValue("gray.600", "gray.300");

  const isMobile = useBreakpointValue({ base: true, md: false });

  const handleChallengeClick = (challengeId: string) => {
    navigate(`/home/locations/${challengeId}`);
  };

  const toggleViewAll = () => {
    setShowAll(!showAll);
  };

  const { isLoading } = useLoading();

  if (isLoading || loading) return <CardSkeleton rows={3} />;
  if (error) return (
    <Alert status="error">
      <AlertIcon />
      <VStack align="start" spacing={1}>
        <Text fontWeight="bold">Oops! Something went wrong</Text>
        <Text>We couldn't load the challenges. Please try again later.</Text>
      </VStack>
    </Alert>
  );

  const allChallenges = data?.challenges || [];
  const displayedChallenges = showAll ? allChallenges : allChallenges.slice(0, 3);
  const hasMoreChallenges = allChallenges.length > 3;

  return (
    <CardWrapper>
      <CardHeader title="Locations">
        {hasMoreChallenges && (
          <Button
            size="sm"
            variant="outline"
            colorScheme="purple"
            onClick={toggleViewAll}
            width={isMobile ? "full" : "auto"}
          >
            {showAll ? "Show Less" : "Show More"}
          </Button>
        )}
        {allChallenges.length < maxChallenges && (
          <Button
            size="sm"
            colorScheme="purple"
            onClick={onOpen}
            width={isMobile ? "full" : "auto"}
          >
            Register New Location
          </Button>
        )}
      </CardHeader>
      <VStack spacing={0} align="stretch">
        {displayedChallenges.length > 0 ? (
          displayedChallenges.map((challenge: Challenge) => (
            <Flex
              key={challenge.id}
              p={4}
              direction={isMobile ? "column" : "row"}
              gap={isMobile ? 3 : 0}
              _hover={{ bg: hoverBgColor }}
              cursor="pointer"
              onClick={() => handleChallengeClick(challenge.id)}
            >
              <Box flex="1">
                <Text fontWeight="bold" fontSize="md" color={textColor}>
                  {challenge.title}
                </Text>
                <Text fontSize="sm" color={secondaryTextColor}>
                  {challenge.address}
                </Text>
              </Box>
              <Flex 
                width={isMobile ? "100%" : "auto"}
                justify={isMobile ? "space-between" : "flex-end"}
                align="center"
                gap={2}
              >
                <Badge colorScheme="orange">Restaurant</Badge>
                <IconButton
                  aria-label="View challenge details"
                  icon={<ChevronRight size={18} />}
                  variant="ghost"
                  colorScheme="purple"
                  size="sm"
                />
              </Flex>
            </Flex>
          ))
        ) : (
          <Box p={4} textAlign="center">
            <Text color={secondaryTextColor}>No locations registered yet.</Text>
          </Box>
        )}
      </VStack>
      <CreateChallengeModal isOpen={isOpen} onClose={onClose} />
    </CardWrapper>
  );
};

export default ShowChallenges;
