import React, { useEffect, useState } from 'react';
import {
  Box,
  VStack,
  Heading,
  Text,
  SimpleGrid,
  useColorModeValue,
  Skeleton,
  Container,
} from '@chakra-ui/react';
import { useParams, useNavigate } from 'react-router-dom';
import { useGetParticipantChallengesQuery } from '../../graphql/generated';
import { ChallengeCard } from '../../components/challenge-card';
import { useAuth } from '../../context/auth';
import DashboardLayout from '../../components/shared/layout';

const ChallengeSelectionPage = () => {
  const { huntId } = useParams<{ huntId: string }>();
  const [participantEmail, setParticipantEmail] = useState<string | null>(null);
  const navigate = useNavigate();
  const { user } = useAuth();

  useEffect(() => {
    const email = localStorage.getItem('participantEmail');
    setParticipantEmail(email);
  }, []);

  const { data, loading, error } = useGetParticipantChallengesQuery({
    variables: {
      huntId: huntId || '',
      participantEmail: participantEmail || '',
    },
    skip: !huntId || !participantEmail,
  });

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const textColor = useColorModeValue('gray.800', 'white');

  const content = (
    <Container maxW="container.xl" py={8}>
      <VStack spacing={6} align="stretch">
        <Heading size="xl" color="purple.500">
          Select a Location
        </Heading>
        <Text color={textColor}>Choose a location to start your adventure!</Text>
        {loading && <Skeleton height="200px" />}
        {error && <Text>Error loading locations: {error.message}</Text>}
        {data && (
          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {data.challenges.map((challenge) => (
              <ChallengeCard
                key={challenge.id}
                challenge={{
                  id: challenge.id,
                  title: challenge.title,
                  description: challenge.description,
                  points: challenge.points,
                }}
                onClick={() => navigate(`/challenges/${challenge.id}`)}
              />
            ))}
          </SimpleGrid>
        )}
      </VStack>
    </Container>
  );

  if (user.isAuth) {
    return <DashboardLayout tourDrawerOpen={false} locationDrawerOpen={false} setTourDrawerOpen={() => {}} setLocationDrawerOpen={() => {}}>{content}</DashboardLayout>;
  }

  return (
    <Box p={6} bg={bgColor} minHeight="100vh">
      {content}
    </Box>
  );
};

export default ChallengeSelectionPage;
