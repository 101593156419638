import { useState } from 'react';
import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  DrawerHeader,
  DrawerBody,
  DrawerFooter,
  DrawerCloseButton,
  Heading,
  Text,
  Button,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
} from '@chakra-ui/react';
import { useCreateChallengeMutation, GameType, GameDifficulty } from '../graphql/generated';
import ChallengePreview from './challenge-preview';
import ChallengeForm from './challenge-form';
import SuccessBanner from './success-banner';

type CreateChallengeInput = {
  title: string;
  description: string;
  points: number;
  address: string;
};

const CreateChallengeDrawer = ({
  onClose,
  isOpen,
}: {
  onClose: () => void;
  isOpen: boolean;
}) => {
  const [challenge, setChallenge] = useState<CreateChallengeInput>({
    title: '',
    description: '',
    points: 0,
    address: '',
  });
  const [errors, setErrors] = useState<Partial<Record<keyof CreateChallengeInput, string>>>({});
  const [activeTab, setActiveTab] = useState<"edit" | "preview">("edit");
  const [mediaFile, setMediaFile] = useState<File | null>(null);
  const [showSuccessBanner, setShowSuccessBanner] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  const [createChallenge, { loading }] = useCreateChallengeMutation();

  const handleInputChange = (field: keyof CreateChallengeInput, value: any) => {
    setChallenge((prev) => ({ ...prev, [field]: value }));
    setErrors((prev) => ({ ...prev, [field]: "" }));
  };

  const validateForm = () => {
    const newErrors: Partial<Record<keyof CreateChallengeInput, string>> = {};

    if (!challenge.title) newErrors.title = "Title is required";
    if (!challenge.description) newErrors.description = "Description is required";
    if (!challenge.address) newErrors.address = "Address is required";
    if (challenge.points <= 0) newErrors.points = "Points must be greater than 0";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleUploadMedia = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setMediaFile(file);
    }
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      try {
        console.log("Media file to upload:", mediaFile?.name);

        await createChallenge({
          variables: {
            input: {
              ...challenge,
              gameType: GameType.Basic,
              gameDifficulty: GameDifficulty.Medium,
              basic: [{ text: "Enter the 3 photos or videos that best describe this location", content: "Give the location a name", expectedContent: "Enter the name of the location" }],
            },
          },
        });

        setShowSuccessBanner(true);
        onClose(); // Close the drawer when the challenge is created successfully
      } catch (err) {
        console.error(err);
        // You might want to show an error message here
      }
    }
  };

  const handleCloseBanner = () => {
    setShowSuccessBanner(false);
  };

  return (
    <>
      {showSuccessBanner && (
        <SuccessBanner
          title="Location Added"
          message="Your new location has been successfully added."
          onClose={handleCloseBanner}
        />
      )}
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="md">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader borderBottomWidth="0.5px" bg="purple.50" p={3}>
            <Heading size="md" color="purple.600">Register New Location</Heading>
            <Text fontSize="xs" color="gray.600" mt={1}>
              Register a new location for your tour
            </Text>
          </DrawerHeader>
          <Tabs
            index={activeTab === "edit" ? 0 : 1}
            onChange={(index) => setActiveTab(index === 0 ? "edit" : "preview")}
          >
            <TabList>
              <Tab>Edit</Tab>
              <Tab>Preview</Tab>
            </TabList>
            <DrawerBody p={0}>
              <TabPanels bg="purple.50">
                <TabPanel>
                  <ChallengeForm
                    challenge={challenge}
                    errors={errors}
                    handleInputChange={handleInputChange}
                    setIsFormValid={setIsFormValid}
                  />
                </TabPanel>
                <TabPanel>
                  <ChallengePreview
                    challenge={challenge}
                    onUploadMedia={handleUploadMedia}
                    color="orange.500"
                  />
                </TabPanel>
              </TabPanels>
            </DrawerBody>
          </Tabs>
          <DrawerFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button 
              colorScheme="purple" 
              onClick={handleSubmit} 
              isLoading={loading}
              isDisabled={!isFormValid}
            >
              Register Location
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default CreateChallengeDrawer;
