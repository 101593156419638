import React, { useEffect, useRef, useState } from "react"
import { useParams, useSearchParams } from "react-router-dom"
import {
  VStack,
  Flex,
  Spinner,
  useToast,
  Container,
  useColorModeValue,
} from "@chakra-ui/react"
import { Send, ArrowLeft } from "lucide-react"
import { useNavigate } from "react-router-dom"
import { useGetMessagesThreadQuery } from "../../graphql/generated"
import { useMessageUpdates } from "./hooks/useMessageSubscription"
import { useMessageInput } from "./hooks/useMessageInput"
import { MessageBubble } from "./components/MessageBubble"
import { ChatHeader } from "./components/ChatHeader"
import { MessageInput } from "./components/MessageInput"
import { MessageDateDivider } from "./components/MessageDateDivider"
import { useAuth } from "../../context/auth"

const MessagesPage = () => {
  const { tourId, participantId} = useParams()
  // get search params code
  const [searchParams] = useSearchParams()
  const code = searchParams.get("code")
  const navigate = useNavigate()
  const toast = useToast()
  const messagesEndRef = useRef<HTMLDivElement>(null)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const [hasScrolled, setHasScrolled] = useState(false)
  
  const bgColor = useColorModeValue("white", "gray.800")
  const borderColor = useColorModeValue("gray.200", "gray.700")
  const headerBg = useColorModeValue("purple.50", "purple.900")

  const { data, loading, refetch } = useGetMessagesThreadQuery({
    variables: {
      participantId: participantId!,
      code,
      limit: 50,
    },
    pollInterval: 1000,
    onError: (error) => {
      toast({
        title: "Error loading messages",
        description: error.message,
        status: "error",
      })
    },
  })

  const { user } = useAuth()
  
  const { message, setMessage, sendMessage, isSending } = useMessageInput({
    tourId: tourId!,
    participantId: participantId!,
    participantEmail: data?.message[0]?.participantEmail,
    onMessageSent: refetch,
  })

  useMessageUpdates({
    participantId: participantId!,
    onNewMessage: refetch,
  })

  const scrollToBottom = (behavior: ScrollBehavior = "smooth") => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior })
    }
  }

  useEffect(() => {
    if (!hasScrolled && data?.message) {
      scrollToBottom("auto")
      setHasScrolled(true)
    }
  }, [data?.message, hasScrolled])

  useEffect(() => {
    if (hasScrolled) {
      scrollToBottom()
    }
  }, [data?.message?.length])

  const handleKeyPress = (e: React.KeyboardEvent) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault()
      sendMessage()
    }
  }

  const groupMessagesByDate = (messages: any[]) => {
    return messages.reduce((groups: any, message: any) => {
      const date = new Date(message.createdAt).toLocaleDateString()
      if (!groups[date]) {
        groups[date] = []
      }
      groups[date].push(message)
      return groups
    }, {})
  }

  return (
    <Container maxW="container.md" h="100vh" p={0}>
      <VStack h="full" spacing={0}>
        <ChatHeader
          participantUsername={data?.message[0]?.participantName ?? ""}
          participantEmail={data?.message[0]?.participantEmail ?? ""}
          creatorUsername={data?.message[0]?.creatorUsername ?? ""}
          creatorEmail={data?.message[0]?.creatorEmail ?? ""}
          huntTitle={data?.message[0]?.huntTitle ?? ""}
          isCreator={user?.isAuth === true}
        />

        <VStack
          ref={scrollContainerRef}
          flex={1}
          w="full"
          spacing={6}
          p={{ base: 2, md: 4 }}
          overflowY="auto"
          bg={useColorModeValue("gray.50", "gray.900")}
          position="relative"
        >
          {loading ? (
            <Flex justify="center" align="center" h="full">
              <Spinner color="purple.500" />
            </Flex>
          ) : (
            data?.message && (
              <>
                {Object.entries(groupMessagesByDate(data.message)).map(
                  ([date, messages]) => (
                    <VStack key={date} w="full" spacing={4}>
                      <MessageDateDivider date={date} />
                      {(messages as any[]).map((msg: any) => (
                        <MessageBubble
                          key={msg.id}
                          message={msg}
                          isUser={user?.isAuth === true}
                        />
                      ))}
                    </VStack>
                  )
                )}
                <div ref={messagesEndRef} />
              </>
            )
          )}
        </VStack>

        <MessageInput
          message={message}
          setMessage={setMessage}
          sendMessage={sendMessage}
          isSending={isSending}
          onKeyPress={handleKeyPress}
        />
      </VStack>
    </Container>
  )
}

export default MessagesPage
