import React, { useState } from 'react';
import {
    Box,
    VStack,
    Heading,
    FormControl,
    FormLabel,
    Input,
    Button,
    useColorModeValue,
    Avatar,
    AvatarBadge,
    IconButton,
    useToast,
    Flex,
    Text,
    HStack,
} from '@chakra-ui/react';
import { EditIcon, CheckIcon, CameraIcon } from 'lucide-react';
import { CloseIcon } from '@chakra-ui/icons';
import { useAuth } from '../../context/auth';

const PreferencesDashboard = () => {
    const { user, setUser, updateProfilePicture } = useAuth();
    const [isEditing, setIsEditing] = useState(false);
    const [formData, setFormData] = useState({
        username: user.username || '',
        email: user.email || '',
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        profilePicture: user.profilePicture || '',
        mediaId: '',
    });
    const toast = useToast();

    const bgColor = useColorModeValue('gray.50', 'gray.900');
    const cardBg = useColorModeValue('white', 'gray.800');
    const borderColor = useColorModeValue('purple.200', 'purple.700');
    const textColor = useColorModeValue('purple.800', 'purple.100');
    const secondaryTextColor = useColorModeValue('purple.600', 'purple.300');

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({ ...prev, [name]: value }));
    };

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        const updatedUser = { ...user, ...formData };
        setUser(updatedUser);
        if (user.profilePicture) {
            updateProfilePicture(user.profilePicture);
        }
        setIsEditing(false);
        toast({
            title: 'Profile updated',
            description: 'Your profile has been updated successfully.',
            status: 'success',
            duration: 3000,
            isClosable: true,
        });
    };

    const handleCancel = () => {
        setFormData({
            username: user.username || '',
            email: user.email || '',
            firstName: user.firstName || '',
            lastName: user.lastName || '',
            profilePicture: user.profilePicture || '',
            mediaId: '',
        });
        setIsEditing(false);
    };

    return (
        <Box bg={bgColor} minH="100vh" p={{ base: 4, md: 8 }}>
            <VStack spacing={6} align="stretch" maxWidth="800px" mx="auto">
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    mb={6}
                >
                    <Heading size="lg" color="purple.600">Profile Settings</Heading>
                    {isEditing ? (
                        <HStack>
                            <Button
                                leftIcon={<CloseIcon />}
                                onClick={handleCancel}
                                variant="outline"
                                colorScheme="purple"
                            >
                                Cancel
                            </Button>
                            <Button
                                leftIcon={<CheckIcon />}
                                colorScheme="purple"
                                onClick={handleSubmit}
                            >
                                Save Changes
                            </Button>
                        </HStack>
                    ) : (
                        <Button
                            leftIcon={<EditIcon size={18} />}
                            onClick={() => setIsEditing(true)}
                            colorScheme="purple"
                        variant="solid"
                        size="md"
                        borderRadius="md"
                        bgGradient="linear(to-r, purple.400, purple.600)"
                        _hover={{
                            bgGradient: "linear(to-r, purple.500, purple.700)",
                                    transform: "translateY(-1px)",
                                    shadow: "md"
                                }}
                        >
                            Edit Profile
                        </Button>
                    )}
                </Flex>

                <Box
                    bg={cardBg}
                    borderRadius="xl"
                    borderWidth="1px"
                    borderColor={borderColor}
                    overflow="hidden"
                    boxShadow="sm"
                >
                    <Flex
                        direction="column"
                        alignItems="center"
                        bg="purple.50"
                        p={8}
                        borderBottom="1px"
                        borderColor={borderColor}
                    >
                        <Box position="relative">
                            <Avatar
                                size="2xl"
                                src={user.profilePicture ? `data:image/jpeg;base64,${user.profilePicture}` : undefined}
                                name={`${formData.firstName} ${formData.lastName}`}
                                bg="white"
                                color="purple.500"
                                boxShadow="lg"
                            >
                                <AvatarBadge
                                    as={IconButton}
                                    size="sm"
                                    rounded="full"
                                    top="-10px"
                                    colorScheme="purple"
                                    aria-label={isEditing ? "Change Avatar" : "Edit Profile"}
                                    icon={isEditing ? <CameraIcon size={20} /> : <EditIcon size={20} />}
                                    onClick={() => isEditing ? {} : setIsEditing(true)}
                                />
                            </Avatar>
                        </Box>
                        <VStack mt={4} spacing={1}>
                            <Text fontSize="2xl" fontWeight="bold" color={textColor}>
                                {formData.firstName} {formData.lastName}
                            </Text>
                            <Text fontSize="md" color={secondaryTextColor}>
                                {formData.email}
                            </Text>
                        </VStack>
                    </Flex>

                    <Box p={6}>
                        <VStack spacing={4}>
                            <FormControl>
                                <FormLabel>Username</FormLabel>
                                <Input
                                    name="username"
                                    value={formData.username}
                                    onChange={handleInputChange}
                                    isReadOnly={!isEditing}
                                    bg={isEditing ? 'white' : 'gray.100'}
                                />
                            </FormControl>
                            <FormControl>
                                <FormLabel>Email</FormLabel>
                                <Input
                                    name="email"
                                    value={formData.email}
                                    onChange={handleInputChange}
                                    isReadOnly={!isEditing}
                                    bg={isEditing ? 'white' : 'gray.100'}
                                />
                            </FormControl>
                            <Flex width="100%" gap={4}>
                                <FormControl>
                                    <FormLabel>First Name</FormLabel>
                                    <Input
                                        name="firstName"
                                        value={formData.firstName}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                        bg={isEditing ? 'white' : 'gray.100'}
                                    />
                                </FormControl>
                                <FormControl>
                                    <FormLabel>Last Name</FormLabel>
                                    <Input
                                        name="lastName"
                                        value={formData.lastName}
                                        onChange={handleInputChange}
                                        isReadOnly={!isEditing}
                                        bg={isEditing ? 'white' : 'gray.100'}
                                    />
                                </FormControl>
                            </Flex>
                        </VStack>
                    </Box>
                </Box>
            </VStack>
        </Box>
    );
};

export default PreferencesDashboard;